import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as CheckIcon } from 'Assets/images/tick.svg';
import './style.scss';

const CustomCheckbox = withStyles({
    root: {
        color: '#D0DCFF',
        '&$checked': {
            color: '#5D5CF5',
        },
        '&:hover': {
            backgroundColor: (props) => props.disableHoverEffect && 'transparent',
            cursor: (props) => props.disableHoverEffect && 'auto',
        },
    },
    checked: {},
})((props) => (
    <>
        <Checkbox
            color='default'
            icon={
                <div style={{ border: '1px solid #D0DCFF', borderRadius: '4px', height: '16px', width: '16px' }}></div>
            }
            checkedIcon={
                <div
                    className='checkbox-container'
                    style={{
                        opacity: props.disabled ? 0.5 : 1,
                    }}
                >
                    <CheckIcon />
                </div>
            }
            {...props}
        />
        {props?.label && <span className='checkbox-label'>{props.label}</span>}
    </>
));

export default CustomCheckbox;
