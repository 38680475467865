import React from 'react';
import PropTypes from 'prop-types';
import { TAB_NAMES } from '../constants';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';
import { PLATFORM } from 'constants.js';

const Tabs = ({ handleTabChange, selectedTab }) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);

    return (
        <div className='dropdown-tabs'>
            {TAB_NAMES.map((tabName, index) =>
                platform === PLATFORM.WEB && tabName === TAB_NAMES[1] ? null : (
                    <button
                        key={tabName}
                        className={clsx({ 'tab-name': true, 'tab-name-selected': index === selectedTab })}
                        onClick={() => {
                            handleTabChange(index);
                        }}
                    >
                        {tabName}
                    </button>
                )
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    platform: state.Workspace.platform,
});

Tabs.propTypes = {
    handleTabChange: PropTypes.func,
    selectedTab: PropTypes.number,
};

export default Tabs;
