import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Badge } from '@material-ui/core';
import { Tooltip } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Table from 'components/ReusableComponents/Table';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as NoFeedbackImage } from 'Assets/images/no-feedback.svg';
import { shallowEqual, useSelector } from 'react-redux';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM, PLATFORM } from 'constants.js';
import { ReactComponent as Eye } from 'Assets/images/eye.svg';
import { ReactComponent as RedirectToSlack } from 'Assets/images/redirect-slack.svg';
import ReadFeedbackModal from 'components/Dashboard/Content/Feedback/MyFeedback/ReadFeedbackModal';
import UserButton from 'components/ReusableComponents/UserButton';
import { GET_FEEDBACK_LIST } from 'Services/apiKeys';
import { getFeedbackList } from '../Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const FeedbackTable = ({
    history,
    feedbackGiven,
    data,
    setData,
    setNewFeedbackModal,
    feedBackEnabled,
    platform,
    showReadFeedbackModal,
    setShowReadFeedbackModal,
    currentFeedback,
}) => {
    const { SetSnackbar } = useToaster();
    const { isAdmin, userId } = useSelector(mapStateToProps, shallowEqual);

    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('descending');
    const [selectedUser, setSelectedUser] = useState();

    const fromOptions = useRef([]);
    const selectedUserName = useRef();
    const [loading, setLoading] = useState(true);

    const type = feedbackGiven ? 'sent' : 'received';

    useQuery([GET_FEEDBACK_LIST, page, type, sortOrder, selectedUser], getFeedbackList, {
        onSuccess: (data) => {
            setFromDropdownOptions(data?.users);
            setData(data);
            setLoading(false);
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setFromDropdownOptions(data?.users);
            setLoading(false);
        },
    });

    useEffect(() => {
        if (!selectedUserName.current) {
            selectedUserName.current = 'ALL';
        }
    }, []);

    const setFromDropdownOptions = (optionsData) => {
        const allOption = { userName: 'ALL', label: 'ALL' };
        const options =
            optionsData?.map((option) => ({
                ...option,
                label: option?.userName,
            })) || [];
        fromOptions.current = [allOption, ...options];
    };

    const handleSort = (_index) => setSortOrder(sortOrder === 'descending' ? 'ascending' : 'descending');

    const handleFromSelection = (index) => {
        const newSelectedUser = fromOptions.current[index];
        // Set loading state only if the selected user is different from the previous selected user
        if (newSelectedUser?.userName !== selectedUserName.current) {
            setLoading(true);
            selectedUserName.current = fromOptions.current[index]?.userName;
            setSelectedUser(newSelectedUser?._id);
        }
        setPage(1);
    };

    const header = ['Date', feedbackGiven ? 'To' : 'From', 'Strength', 'Needs Improvement', 'Message'];
    const noSortColumns = [1, 2, 3, 4, 5, 6];
    const paginationProps = {
        fixedBottom: true,
        currentPage: page,
        totalCount: feedbackGiven ? data?.totalSent : data?.totalReceived,
        setCurrentPage: setPage,
        onChange: () => setLoading(true),
    };
    const columnWidth = ['12%', '20%', '20%', '20%', '23%', '5%'];

    const openUserProfile = (item, key) => {
        let user;
        switch (key) {
            case 'sender':
                user = item.sender;
                break;

            case 'receiver':
                user = item.receiver;
                break;
            default:
                user = feedbackGiven ? item.receiver : item.sender;
                break;
        }
        if (isAdmin && user._id) {
            handlePageRedirectionWithinSubMenu({
                history,
                redirectionURL: `/dashboard/profile/${user._id}`,
                navMenuCode: HISTORY_FROM.FEEDBACK,
            })();
        }
    };

    const getSenderImageURL = (item, key) => {
        let url;
        switch (key) {
            case 'sender':
                url = item.sender.pictureURL;
                break;

            case 'receiver':
                url = item.receiver.pictureURL;
                break;
            default:
                url = feedbackGiven ? item.receiver.pictureURL : item.sender.pictureURL;
                break;
        }
        return url;
    };

    /**
     * Get the sender name from the sender object.
     * If sender is anonymous then sender will be string else object
     * @param {object} item
     * @returns userName
     */
    const getSenderName = (item, key) => {
        switch (key) {
            case 'sender':
                return typeof item.sender === 'object' ? item.sender.userName : item.sender;
            case 'receiver':
                return item.receiver.userName;
            default:
                if (!feedbackGiven) {
                    return typeof item.sender === 'object' ? item.sender.userName : item.sender;
                }
                return item.receiver.userName;
        }
    };

    const userButtonHandler = (item, key) => {
        const isAnonymous = item.sender === 'Anonymous' && type === 'received';
        return (
            <UserButton
                key={item._id}
                id={item._id}
                item={item}
                isAnonymous={isAnonymous}
                onClick={() => openUserProfile(item, key)}
                imageURL={getSenderImageURL(item, key)}
                senderName={getSenderName(item, key)}
                pointerEvents={isAdmin && !isAnonymous}
                clickableUser={!isAnonymous}
            />
        );
    };

    const feedbackCategories = (categories) => {
        if (!categories || categories.length === 0) return '-';

        if (categories.length <= 1) {
            return categories.join(', ');
        } else {
            const displayedCategories = categories.slice(0, 1).join(', ');
            const hiddenCategories = categories.slice(1).join(', ');
            const remainingCount = categories.length - 1;

            return (
                <div className='feedback-category-container'>
                    <span>{displayedCategories}</span>
                    <Tooltip arrow title={hiddenCategories} padding='10px'>
                        <span>
                            <Badge
                                classes={{
                                    badge: 'feedback-count-badge',
                                    root: 'feedback-count-badge-root',
                                }}
                                badgeContent={<span className='feedback-count-badge-text'>+{remainingCount}</span>}
                            />
                        </span>
                    </Tooltip>
                </div>
            );
        }
    };

    const feedbackTableData = data?.data?.map((item) => {
        return {
            id: item,
            row: [
                item.date,
                userButtonHandler(item),
                feedbackCategories(item.strength),
                feedbackCategories(item.improvement),
                <p key={item._id} className='my-feedback-message'>
                    {item?.message}
                </p>,
                <div className='vert-menu-wrapper' key={item._id}>
                    <Eye
                        key={item._id}
                        onClick={() => {
                            currentFeedback.current = item;
                            setShowReadFeedbackModal(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    {platform === PLATFORM.SLACK && (
                        <RedirectToSlack
                            key={item._id}
                            onClick={(_index) => window.open(item.messageUrl, '_blank')}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </div>,
            ],
        };
    });

    if (loading) {
        return <CustomLoader />;
    }
    const emptyContentProps = {
        image: <NoFeedbackImage />,
        headingText: feedbackGiven ? 'Your voice matters.' : 'Feedback hasn’t rolled in yet.',
        subheadingText: feedbackGiven
            ? 'Share feedback to help your colleagues grow!'
            : 'Request some and gather insights!',
        ...(feedBackEnabled && {
            btnName: (
                <div className='d-flex align-items-center'>
                    <span className='new-feedback-button-plus-icon'>+</span>
                    <span className='ml-2'>New Feedback</span>
                </div>
            ),
            action: () => setNewFeedbackModal(true),
        }),
    };

    if (!loading && !feedbackTableData?.length) {
        return <EmptyContent {...emptyContentProps} />;
    }

    return (
        <div>
            <div className='feedback-page-filter'>
                <CustomFilterDropdown
                    dropDownID={'filterFeedbackReceived'}
                    title={feedbackGiven ? 'To' : 'From'}
                    selectedName={selectedUserName?.current || 'ALL'}
                    optionsSelected
                    buttonStyleClass='width-188 mb-2'
                    dropdownWidth={188}
                    filterOptions={fromOptions.current}
                    handleSelection={handleFromSelection}
                    search
                    singleSelect
                />
                {selectedUserName.current && selectedUserName.current != 'ALL' && (
                    <div className='feedback-total-items-container'>
                        <span className='feedback-total-items-count'>
                            Displaying {data.data.length}/{feedbackGiven ? data.totalSent : data.totalReceived} items
                        </span>
                        <button
                            className='feedback-filter-clear-btn'
                            onClick={() => {
                                setSelectedUser();
                                selectedUserName.current = 'ALL';
                            }}
                        >
                            Clear
                        </button>
                    </div>
                )}
            </div>
            <Table
                header={header}
                data={feedbackTableData}
                noSortColumns={noSortColumns}
                paginationProps={paginationProps}
                columnWidth={columnWidth}
                noShadow
                loading={loading}
                handleSort={handleSort}
            />
            {showReadFeedbackModal && (
                <ReadFeedbackModal
                    open={showReadFeedbackModal}
                    onClose={() => setShowReadFeedbackModal(false)}
                    data={currentFeedback.current}
                    openUserProfile={openUserProfile}
                    userButtonHandler={userButtonHandler}
                    isFeedbackReadableModal={true}
                    currentUserId={userId}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin, userId: state.User._id });

FeedbackTable.propTypes = {
    history: PropTypes.object,
    feedbackGiven: PropTypes.bool,
    data: PropTypes.object,
    setData: PropTypes.func,
    setNewFeedbackModal: PropTypes.func,
    feedBackEnabled: PropTypes.bool,
    platform: PropTypes.string,
    showReadFeedbackModal: PropTypes.bool,
    setShowReadFeedbackModal: PropTypes.func,
    currentFeedback: PropTypes.object,
};

export default FeedbackTable;
