import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as RequestFeedback } from 'Assets/images/request-feedback.svg';
import { ReactComponent as SendFeedback } from 'Assets/images/send-feedback.svg';
import './style.scss';

const FeedbackOption = ({ Icon, title, onClick }) => (
    <button className='feedback-modal-content-item' onClick={onClick}>
        <Icon />
        <div className='feedback-modal-content-item-heading'>{title}</div>
    </button>
);

const FeedbackModal = ({ open, onClose, handleShowSendFeedbackModal, handleShowRequestFeedbackModal }) => {
    const modalData = {
        heading: 'How do you want to get started?',
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='feedback-modal-container'>
                <div className='d-flex justify-content-end'>
                    <CloseIcon className='pointer-cursor' onClick={onClose} />
                </div>
                <div className='feedback-modal-header'>
                    <div className='feedback-modal-heading'>{modalData.heading}</div>
                </div>
                <div className='feedback-modal-content'>
                    <FeedbackOption
                        onClick={() => {
                            handleShowRequestFeedbackModal();
                            onClose();
                        }}
                        Icon={RequestFeedback}
                        title='Request Feedback'
                    />
                    <FeedbackOption
                        onClick={() => {
                            handleShowSendFeedbackModal();
                            onClose();
                        }}
                        Icon={SendFeedback}
                        title='Send Feedback'
                    />
                </div>
            </div>
        </Modal>
    );
};

FeedbackOption.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

FeedbackModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleShowSendFeedbackModal: PropTypes.func.isRequired,
    handleShowRequestFeedbackModal: PropTypes.func.isRequired,
};

export default FeedbackModal;
