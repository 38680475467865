import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DownIcon } from 'Assets/images/downIcon.svg';
import { Tooltip } from 'components';
import { CustomMenu, MenuList } from 'components/ReusableComponents/Menu';
import clsx from 'clsx';
import './styles.scss';

/**
 * NomineesDropdown Component
 * Renders a list of nominees in a dropdown menu
 */
const NomineesDropdown = ({ members }) => {
    return (
        <div className='nominees-content'>
            <div className='nominees-header'>NOMINEES({members.length})</div>
            <div className='nominees-list'>
                {members.map((member, index) => (
                    <div className='nominee-item' key={member._id || index}>
                        <img
                            src={member.pictureURL || require('Assets/images/defaultUser.png')}
                            className='nominee-avatar'
                            alt='nominee'
                        />
                        <span className='nominee-name'>{member.userName}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * ImageStack Component
 * Displays a stack of user avatars with an optional dropdown for additional members
 *
 * @param {Object} props
 * @param {Array} props.members - Array of member objects or image URLs
 * @param {number} props.width - Width of avatar images
 * @param {number} props.height - Height of avatar images
 * @param {number} props.borderRadius - Border radius of avatar images
 * @param {string} props.customClassName - Custom class for styling
 * @param {number} props.count - Number of avatars to show in stack
 * @param {boolean} props.showMore - Whether to show dropdown for additional members
 * @param {boolean} props.showToolTip - Whether to show tooltips on hover
 */
const ImageStack = ({
    members = [],
    width = 24,
    height = 24,
    borderRadius = 4,
    customClassName,
    count = 4,
    showMore = false,
    showToolTip = false,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const buttonRef = useRef(null);
    const remainingCount = members.length - count;

    if (!members.length) return null;

    const handleCountClick = (event) => {
        if (!showMore) return;
        event.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    };

    const renderStack = () => (
        <div className='image-stack-wrapper'>
            {members.slice(0, count).map((member, index) => (
                <Tooltip key={member?._id || index} title={member?.userName} hide={!showToolTip}>
                    <img
                        src={
                            member?.pictureURL ||
                            (typeof member === 'string' && member) ||
                            require('Assets/images/defaultUser.png')
                        }
                        key={member?._id || index}
                        style={{
                            width,
                            height,
                            borderRadius,
                        }}
                        alt={member?.userName || 'member'}
                        className={clsx('member-avatar', { [customClassName]: customClassName })}
                    />
                </Tooltip>
            ))}
            {remainingCount > 0 && showMore && (
                <button ref={buttonRef} className='count-button' onClick={handleCountClick}>
                    {`+${remainingCount}`} <DownIcon />
                </button>
            )}
        </div>
    );

    if (!showMore) {
        return renderStack();
    }

    return (
        <div className='image-stack-container'>
            {renderStack()}
            <CustomMenu
                open={dropdownOpen}
                anchorEl={buttonRef.current}
                onClose={() => setDropdownOpen(false)}
                className='nominees-dropdown'
                offset={{ top: 8, left: 0 }}
            >
                <MenuList>
                    <NomineesDropdown members={members} />
                </MenuList>
            </CustomMenu>
        </div>
    );
};

NomineesDropdown.propTypes = {
    members: PropTypes.array.isRequired,
};

ImageStack.propTypes = {
    members: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    customClassName: PropTypes.string,
    count: PropTypes.number,
    showMore: PropTypes.bool,
    showToolTip: PropTypes.bool,
};

export default ImageStack;
