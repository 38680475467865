import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { BeatLoader } from 'react-spinners';
import { filter, differenceWith, find } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { useToaster } from 'Context/SnackbarContext';
import { FormGroup } from '@material-ui/core';
import { CustomCheckbox } from 'components';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomTags from 'components/ReusableComponents/CustomTags';
import Modal from '@material-ui/core/Modal';
import { shallowEqual, useSelector } from 'react-redux';
import { addChannel, getPublicChannelsList } from 'Services/apiFunctions';
import { GET_PUBLIC_CHANNELS, GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { showSnackBarMessage, isSlack } from 'utils/HelperFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import './style.scss';

const Index = ({ setOpen, channel }) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();
    const [empty, setEmpty] = useState(false);
    const [selectedList, setSelectedList] = useState([...channel]);
    const [search, setSearch] = useState('');
    const [publicChannels, setPublicChannels] = useState([]);

    const { mutateAsync, isLoading } = useMutation(addChannel);
    const queryClient = useQueryClient();

    const channelComparator = (a, b) => {
        if (isSlack(platform)) {
            return a.channelID === b.channelID;
        } else {
            return a.channelID === b.groupId;
        }
    };
    const { isLoading: loadingPublicChannelsList } = useQuery([GET_PUBLIC_CHANNELS], getPublicChannelsList, {
        onSuccess: (data) => {
            const channelsData = data.channels.map((channel) => ({
                ...channel,
                label: channel.channelName,
                value: channel.channelID,
            }));
            // filter already integrated channels from public channels list
            const unmappedChannels = differenceWith(channelsData, channel, channelComparator);
            setPublicChannels(unmappedChannels);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const handleClose = () => setOpen(false);

    const handleClick = (option) => {
        if (option.alreadyPresent) {
            return;
        }
        const tempList = [...selectedList];
        tempList.push(option);
        setSelectedList(tempList);
    };

    const handleRemove = (_option, index) => {
        const tempList = [...selectedList];
        tempList.splice(index, 1);
        setSelectedList(tempList);
    };

    const handleReset = () => setSelectedList([...channel]);

    const handleChannel = async () => {
        const newChannelList = selectedList
            .filter((channel) => !channel.alreadyPresent)
            .map((channel) => channel.channelID);
        if (newChannelList.length < 1) {
            showSnackBarMessage(SetSnackbar, 'warning', 'No new channels selected!');
            return;
        }
        try {
            await mutateAsync({ apiData: { channelId: newChannelList } });
            queryClient.invalidateQueries(GET_INTEGRATED_CHANNELS);
            setOpen(false);
            showSnackBarMessage(SetSnackbar, 'success', 'New channels added successfully!');
            return;
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const handleChange = (value) => setSearch(value);

    const channelName = (option) => `${platform === 'MSTeams' ? '' : '#'}${option.label}`;

    const body = (
        <div className='modal-style add-channel-container'>
            <div className='add-channel-heading-container'>
                <p>Enable EngageWith in preferred {platform === 'MSTeams' ? 'teams' : 'channels'}</p>
                <CloseIcon className='pointer-cursor' onClick={handleClose} />
            </div>
            <CustomSearchBox
                containerClassName='add-channel-search-container'
                value={search}
                width={'100%'}
                handleSearch={handleChange}
                placeholder={`Search for a ${platform === 'MSTeams' ? 'team' : 'channel'}`}
            />
            <div className='add-channel-data-container make-relative'>
                <FormGroup>
                    <CustomTags
                        tags={selectedList}
                        onRemove={handleRemove}
                        searchText={search}
                        platform={platform}
                        selectedList={selectedList}
                    />
                    <hr className='dividing-line' />
                    {publicChannels?.map((option) => {
                        return option.label.toLowerCase().includes(search.toLowerCase()) &&
                            !(
                                option.alreadyPresent ||
                                find(selectedList, (selectedChannel) => option.channelID === selectedChannel.channelID)
                            ) ? (
                            <span className='channel-option-span' key={option.value}>
                                <CustomCheckbox
                                    size='small'
                                    checked={!!(option.alreadyPresent || selectedList[option.value])}
                                    onChange={() => handleClick(option)}
                                    name={option.value}
                                />
                                <span className='ml-2 channel-name'>{channelName(option)}</span>
                            </span>
                        ) : null;
                    })}
                    {!loadingPublicChannelsList &&
                        publicChannels.length > 0 &&
                        !filter(publicChannels, (option) => option.label.toLowerCase().includes(search.toLowerCase()))
                            .length > 0 && (
                            <div className='response-empty'>
                                <div className='response-empty-image'></div>
                                <p className='no-response-text'>No results found</p>
                            </div>
                        )}
                    {loadingPublicChannelsList && <CustomLoader />}
                </FormGroup>
            </div>
            <div className=' d-flex justify-content-end mt-2'>
                {selectedList.length > channel.length && (
                    <button className='ew-btn tb-btn mt-4 mr-2' onClick={handleReset}>
                        Reset
                    </button>
                )}
                <button
                    className='ew-btn pb-btn mt-4 ml-3'
                    onClick={handleChannel}
                    disabled={isLoading || selectedList.length === channel.length}
                >
                    {isLoading ? <BeatLoader size={10} color={'#fff'} /> : 'Add'}
                </button>
            </div>
        </div>
    );

    const emptyBody = (
        <div className='modal-style add-channel-container' style={{ height: '450px' }}>
            <div className='add-channel-heading-container'>
                <p>Select new teammates</p>
                <CloseIcon className='pointer-cursor' onClick={handleClose} />
            </div>
            <div className='response-empty'>
                <div className='response-empty-image' style={{ margin: 'auto' }}></div>
                <p className='no-response-text'>No new teammantes were selected</p>
            </div>
            <div className='ok-btn-container'>
                <button className='ew-btn pb-btn' onClick={() => setEmpty(false)}>
                    Ok
                </button>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={!empty}
                onClose={handleClose}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                border={0}
            >
                {body}
            </Modal>
            <Modal
                open={empty}
                onClose={() => setEmpty(false)}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                border={0}
            >
                {emptyBody}
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    team: Workspace.team,
    platform: Workspace.platform,
});

Index.propTypes = {
    setOpen: PropTypes.func,
    channel: PropTypes.object,
};

export default Index;
