import React, { useState } from 'react';
import './style.scss';
import { format } from 'date-fns';
import Table from 'components/ReusableComponents/Table';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { dateRanges } from 'constants.js';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { debounce } from 'lodash';
import { dateRangeHandler } from '../../../EmployeeAwards/componentUtils';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { getSortHandler, rowFormatter } from './componentUtil';
import { useQuery } from 'react-query';
import { GET_EMPLOYEE_AWARDS_LIST } from 'Services/apiKeys';
import { getMyActivityAwards } from '../../../EmployeeAwards/Services/APIFunctions';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import { AWARD_TABLE_PROPERTIES } from './constant';
import Certificate from 'components/ReusableComponents/Certificate';
import { useSelector, shallowEqual } from 'react-redux';

const Index = () => {
    const [total, setTotal] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedDateRangeDisplayLabel, setSelectedDateRangeDisplayLabel] = useState();
    const [sort, setSort] = useState({ key: undefined, direction: undefined });
    const [rowData, setRowData] = useState([]);
    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateInfo, setCertificateInfo] = useState({});
    const { userName } = useSelector(mapStateToProps, shallowEqual);

    const handleSearchChange = debounce((value) => {
        setSearchTerm(value);
    }, 300);
    const searchHandler = (value) => {
        handleSearchChange(value);
        setPageNumber(1);
    };

    const dateRangeSelectionHandler = (index) => {
        dateRangeHandler({
            index,
            setSelectedDateRangeDisplayLabel,
            setShowDatePicker,
            setSelectedDateRange,
        });
        setPageNumber(1);
    };
    const onCloseDateModalHandler = () => setShowDatePicker(false);
    const onConfirmDateModalHandler = (selectedDate) => {
        setShowDatePicker(false);
        setSelectedDateRange({
            start: format(selectedDate.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            end: format(selectedDate.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        });
        setPageNumber(1);
    };
    const sortHandler = getSortHandler(sort, setSort, AWARD_TABLE_PROPERTIES);
    const paginationProps = {
        fixedBottom: true,
        currentPage: pageNumber,
        totalCount: total,
        setCurrentPage: setPageNumber,
    };

    const { SetSnackbar } = useToaster();
    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err.message);
    const onSuccess = (data) => {
        const { awards, total } = data;
        setTotal(total);
        setRowData(awards.map((award) => rowFormatter(award, setShowCertificate, setCertificateInfo, userName)));
    };

    const { isLoading, isFetching } = useQuery(
        [GET_EMPLOYEE_AWARDS_LIST, pageNumber, sort.key, sort.direction, selectedDateRange, searchTerm],
        getMyActivityAwards,
        {
            onSuccess,
            onError,
        }
    );

    return (
        <div className='award-table-container'>
            <div className='filters-container'>
                <CustomFilterDropdown
                    singleSelect
                    title='Date Range'
                    dropDownID='awardDateDropdown'
                    buttonStyleClass='width-188'
                    filterOptions={dateRanges}
                    selectedName={selectedDateRangeDisplayLabel || 'ALL'}
                    optionsSelected
                    handleSelection={dateRangeSelectionHandler}
                />
                <CustomDateRangeModal
                    open={showDatePicker}
                    onClose={onCloseDateModalHandler}
                    onConfirm={onConfirmDateModalHandler}
                />
                <div className='ml-auto mt-auto'>
                    <CustomSearchBox
                        width={'320px'}
                        value={searchTerm}
                        handleSearch={searchHandler}
                        placeholder={'Search by award name'}
                    />
                </div>
            </div>

            <Table
                header={AWARD_TABLE_PROPERTIES.HEADERS}
                columnWidth={AWARD_TABLE_PROPERTIES.COLUMN_WIDTH}
                noSortColumns={AWARD_TABLE_PROPERTIES.NO_SORT}
                paginationProps={paginationProps}
                data={rowData}
                handleSort={sortHandler}
                loading={isLoading || isFetching}
            />
            <Certificate open={showCertificate} onClose={() => setShowCertificate(false)} {...certificateInfo} />
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    userName: User.userName,
});

export default Index;
