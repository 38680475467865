import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'utils/HelperFunctions';
import PulseTask from './PulseTask';
import RecognitionTask from './RecognitionTask';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { toggleFullscreen } from 'redux/layout/actions';
import './style.scss';

const TASK_COMPONENTS = {
    fill_survey: PulseTask,
    recognition_pending_approval: RecognitionTask,
    recognition_requires_approval: RecognitionTask,
};

const Index = ({ taskItemData, history, nameForPoints }) => {
    const dispatch = useDispatch();
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);
    const location = useLocation();
    const originalDate = new Date(taskItemData?.timestamp);
    const formattedDate = getFormattedDate(originalDate);
    // for below code - users.type for recognitions, users.event for pulse
    const TaskComponent = TASK_COMPONENTS[taskItemData.users?.type || taskItemData.users?.event];

    useEffect(() => {
        toggleFullscreen(dispatch, false);
    }, []);

    return (
        <div>
            {TaskComponent && (
                <TaskComponent
                    taskItemData={taskItemData}
                    formattedDate={formattedDate}
                    history={history}
                    nameForPoints={nameForPoints}
                    isAdmin={isAdmin}
                    location={location}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAdmin: state.User.isAdmin,
});

Index.propTypes = {
    taskItemData: PropTypes.object,
    history: PropTypes.object,
    nameForPoints: PropTypes.string,
};

export default Index;
