import React from 'react';
import TeamCount from 'components/ReusableComponents/TeamCount';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

/*
onClick - for redirection to user profile
imageURL - image of user
senderName - Name of the user
pointerEvents - by default true (pointer or None)
isAnonymous - Anonymous feedback/Suggestion
isAdmin - Whether user is admin or not
*/

const Index = ({
    id,
    isAnonymous,
    pointerEvents = true,
    showImage,
    onClick,
    imageURL,
    senderName,
    teamSize,
    customClassname,
    showTeam = false,
    clickableUser = false,
}) => {
    const image = isAnonymous
        ? require('Assets/images/anonymous-user-image.svg')
        : imageURL || require('Assets/images/defaultUser.png');

    return (
        <button
            key={id}
            className={clsx(clickableUser ? 'clickable-user-btn' : 'container-user-button', {
                member: true,
                [customClassname]: customClassname,
            })}
            style={{
                pointerEvents: pointerEvents ? 'pointer' : 'none',
            }}
            onClick={onClick}
        >
            {showImage && <img src={image} alt='' />}
            {!clickableUser ? senderName : <p>{senderName}</p>}
            {showTeam && teamSize > 0 ? <TeamCount count={teamSize} /> : null}
        </button>
    );
};

Index.defaultProps = {
    showImage: true,
    isAnonymous: false,
};

Index.propTypes = {
    senderName: PropTypes.string,
    imageURL: PropTypes.string,
    onClick: PropTypes.func,
    showImage: PropTypes.bool,
    isAnonymous: PropTypes.bool,
    pointerEvents: PropTypes.bool,
    id: PropTypes.string,
    teamSize: PropTypes.number,
    showTeam: PropTypes.bool,
    customClassname: PropTypes.string,
    clickableUser: PropTypes.bool,
};

export default Index;
