import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Table from 'components/ReusableComponents/Table';
import { Tooltip } from 'components';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { ReactComponent as Eye } from 'Assets/images/eye.svg';
import { ReactComponent as RedirectToSlack } from 'Assets/images/redirect-slack.svg';
import { ReactComponent as Pencil } from 'Assets/images/edit-blue.svg';
import ReadSuggestionModal from '../ReadSuggestionModal';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as NoSuggestionsImage } from 'Assets/images/no-suggestions.svg';
import { ReactComponent as LikeIcon } from 'Assets/images/like.svg';
import { ReactComponent as DisLikeIcon } from 'Assets/images/dislike.svg';
import { shallowEqual, useSelector } from 'react-redux';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import { GET_SUGGESTION_BOX_LIST } from 'Services/apiKeys';
import { showSnackBarMessage, addOptionAll, makePlural } from 'utils/HelperFunctions';
import UserButton from 'components/ReusableComponents/UserButton';
import EditSuggestionBoxModal from '../EditSuggestionBoxModal';
import { getSuggestionList } from '../../Services/apiFunctions';

const SuggestionTable = ({ history, isSuggestionBoxAdmin }) => {
    const { SetSnackbar } = useToaster();
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);
    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('descending');
    const [readSuggestionModal, setReadSuggestionModal] = useState(false);
    const [sortVotes, setSortVotes] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [currentSuggestion, setCurrentSuggestion] = useState();
    const fromOptions = useRef([]);
    const selectedUserName = useRef();
    const [loading, setLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const currentEditSuggestion = useRef();

    useQuery([GET_SUGGESTION_BOX_LIST, page, sortOrder, sortVotes, selectedUser], getSuggestionList, {
        onSuccess: (data) => {
            setFromDropdownOption(data?.users);
            setData(data);
            setLoading(false);
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setFromDropdownOption(data?.users);
            setLoading(false);
        },
    });

    const setFromDropdownOption = (optionsData) => {
        fromOptions.current = addOptionAll(optionsData, 'userName');
    };

    const handleSort = (_index, criteria) => {
        if (criteria === 'Date') {
            setSortVotes('date');
        } else if (criteria === 'Votes') {
            setSortVotes('vote');
        }
        setSortOrder(sortOrder === 'descending' ? 'ascending' : 'descending');
    };
    const handleFromSelection = (index) => {
        if (selectedUser !== fromOptions.current[index]?._id) {
            setLoading(true);
            selectedUserName.current = fromOptions.current[index]?.userName;
            setSelectedUser(fromOptions.current[index]?._id);
            setPage(1);
        }
    };

    const header =
        isAdmin || isSuggestionBoxAdmin
            ? ['Date', 'From', 'Suggestion Message', 'Votes']
            : ['Date', 'Suggestion Message', 'Votes'];
    const noSortColumns = isAdmin || isSuggestionBoxAdmin ? [1, 2] : [1];
    const paginationProps = {
        fixedBottom: true,
        currentPage: page,
        totalCount: data?.total,
        setCurrentPage: setPage,
        onChange: () => setLoading(true),
    };
    const columnWidth =
        isAdmin || isSuggestionBoxAdmin ? ['13%', '18%', '40%', '24%', '5%'] : ['26%', '49%', '20%', '5%'];

    const openUserProfile = (user) => {
        if (isAdmin && user?._id) {
            handlePageRedirectionWithinSubMenu({
                history,
                redirectionURL: `/dashboard/profile/${user._id}`,
                navMenuCode: HISTORY_FROM.SUGGESTION_BOX,
            })();
        }
    };

    const votesHandler = (item) => {
        if (typeof item.votes !== 'string') {
            return (
                <div key={item._id} className='votes-wrapper'>
                    <div className='up-votes'>
                        <LikeIcon />
                        <span>{makePlural(item.votes.upvote, 'upvote')}</span>
                    </div>
                    <div className='down-votes'>
                        <DisLikeIcon />
                        <span>{makePlural(item.votes.downvote, 'downvote')}</span>
                    </div>
                </div>
            );
        } else if (item.votes === 'Sent Privately') {
            return (
                <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                    {item.votes}
                    <Tooltip arrow title={'Voting is disabled for suggestions sent privately.'}>
                        <InfoIcon className='info-icon' />
                    </Tooltip>
                </div>
            );
        } else {
            return item.votes;
        }
    };

    const getSenderImageURL = (item) => {
        const url = item?.sender?.pictureURL;
        return url;
    };

    const getSenderName = (item) => {
        return typeof item?.sender === 'object' ? item?.sender?.userName : 'Anonymous';
    };

    const userButtonHandler = (item) => {
        const isAnonymous = item?.anonymous;
        return (
            <UserButton
                key={item._id}
                id={item._id}
                item={item}
                isAnonymous={isAnonymous}
                onClick={() => openUserProfile(item?.sender)}
                imageURL={getSenderImageURL(item)}
                senderName={getSenderName(item)}
                pointerEvents={(isAdmin || isSuggestionBoxAdmin) && !isAnonymous}
                clickableUser={!isAnonymous}
            />
        );
    };

    const suggestionBoxTableData = data?.data?.map((item) => {
        const rowArray = [
            item.date,
            userButtonHandler(item),
            <p key={item._id} className='my-suggestion-message'>
                {item.message}
            </p>,
            votesHandler(item),
            <div className='vert-menu-wrapper' key={item._id}>
                {item.canEdit && (
                    <Pencil
                        onClick={() => {
                            currentEditSuggestion.current = item;
                            setEditModal(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <Eye
                    onClick={() => {
                        setCurrentSuggestion(item);
                        setReadSuggestionModal(true);
                    }}
                    style={{ cursor: 'pointer' }}
                />

                <RedirectToSlack
                    onClick={(_index) => window.open(item.messageUrl, '_blank')}
                    style={{ cursor: 'pointer' }}
                />
            </div>,
        ];
        if (!isAdmin && !isSuggestionBoxAdmin) {
            rowArray.splice(1, 1);
        }
        return {
            id: item,
            row: rowArray,
        };
    });

    if (loading) {
        return <CustomLoader />;
    }

    return (
        <div>
            {(isAdmin || isSuggestionBoxAdmin) && (
                <CustomFilterDropdown
                    dropDownID={'filterSuggestionReceived'}
                    title={'From'}
                    selectedName={selectedUserName?.current || 'ALL'}
                    optionsSelected
                    buttonStyleClass='width-188 mb-2'
                    dropdownWidth={188}
                    filterOptions={fromOptions.current}
                    handleSelection={handleFromSelection}
                    search
                    singleSelect
                />
            )}
            {!loading && !suggestionBoxTableData?.length ? (
                <EmptyContent image={<NoSuggestionsImage />} />
            ) : (
                <Table
                    header={header}
                    data={suggestionBoxTableData}
                    noSortColumns={noSortColumns}
                    paginationProps={paginationProps}
                    columnWidth={columnWidth}
                    noShadow
                    loading={loading}
                    handleSort={handleSort}
                />
            )}
            {readSuggestionModal && (
                <ReadSuggestionModal
                    open={readSuggestionModal}
                    onClose={() => setReadSuggestionModal(false)}
                    data={currentSuggestion}
                    votesHandler={votesHandler}
                    openUserProfile={openUserProfile}
                    userButtonHandler={userButtonHandler}
                    isSuggestionBoxAdmin={isSuggestionBoxAdmin}
                />
            )}
            {editModal && (
                <EditSuggestionBoxModal
                    open={editModal}
                    onClose={() => setEditModal(false)}
                    editSuggestionData={currentEditSuggestion.current}
                    history={history}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin });

SuggestionTable.propTypes = {
    history: PropTypes.object,
    isSuggestionBoxAdmin: PropTypes.bool,
};

export default SuggestionTable;
