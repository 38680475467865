import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { VoiletTag } from 'utils/Stylesheet/style';
import { getData } from 'Services/apiCall';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { ReactComponent as EyeInvisible } from 'Assets/images/eye-invisible.svg';
import { ReactComponent as EyeVisible } from 'Assets/images/eye-visible.svg';
import { ReactComponent as Clock } from 'Assets/images/clock.svg';
import { ReactComponent as QuestionBook } from 'Assets/images/question-book.svg';
import { ReactComponent as SeparatorDot } from 'Assets/images/separator-dot.svg';
import { ReactComponent as NoResultFound } from 'Assets/images/empty-flag.svg';
import { filter } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import { getTemplates } from 'Services/apiFunctions';
import { GET_PULSE_TEMPLATES } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [templateData, setTemplateData] = useState(undefined);
    const [questions, setQuestions] = useState([]);
    const [templates, setTemplates] = useState(undefined);
    const [categoryList, setCategoryList] = useState([]);
    const [activeFilter, setActiveFilter] = useState('');
    const [search, setSearch] = useState('');

    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);

    useEffect(() => {
        const handleBackButton = () => {
            history.push('/dashboard/pulse');
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [history]);

    const { isLoading } = useQuery([GET_PULSE_TEMPLATES, false], getTemplates, {
        onSuccess: (data) => {
            setTemplateData(data?.templates[0]);
            setCategoryList(['All categories', ...(data?.categories || [])]);
            setTemplates(data?.templates);
            if (data?.templates?.length > 0) {
                getTemplateQuestions(data?.templates[0]);
            }
        },
        onError,
    });

    const handleTemplateClick = (template) => getTemplateQuestions(template);

    const getTemplateQuestions = async (template) => {
        try {
            const pulseTemplateData = await getData(`pulse/templates/questions/${template?._id}`);
            setQuestions(pulseTemplateData?.data || []);
            setTemplateData(template);
        } catch (err) {
            onError(err);
        }
    };

    const handleFilter = (index) => {
        setActiveFilter(index === 0 ? '' : categoryList[index]);
    };

    if (isLoading) {
        return <CustomLoader />;
    }

    if (!templates) {
        return <div>No templates to show</div>;
    }

    const filteredTemplates = filter(
        templates,
        (template) =>
            template.name.toLowerCase().includes(search.toLowerCase()) &&
            (activeFilter === '' || activeFilter === template.category)
    );
    return (
        <div className='template-list-container'>
            <div className='container_left'>
                <div className='left-header'>
                    <div className='template-count'>
                        <button className='a-default back-btn' onClick={() => history.push('/dashboard/pulse')}>
                            <strong>
                                <ArrowBackIosIcon /> Back
                            </strong>
                        </button>
                        &nbsp; All Templates ({templates.length})
                    </div>
                    <div className='template-search-dropdown'>
                        <div className='template-search-container'>
                            <CustomSearchBox placeholder='Search templates' value={search} handleSearch={setSearch} />
                        </div>
                        <div>
                            <CustomFilterDropdown
                                filterOptions={categoryList.map((category) => ({ label: category, value: category }))}
                                selectedName={activeFilter || 'All categories'}
                                dropDownID='filterTemplateDropdown'
                                buttonStyleClass='width-300'
                                handleSelection={(index) => handleFilter(index)}
                                singleSelect
                            />
                        </div>
                    </div>
                </div>
                <div className='template-list'>
                    {filteredTemplates.length > 0 ? (
                        filteredTemplates.map((template) => (
                            <button
                                className={
                                    templateData?._id === template?._id
                                        ? 'template-selected-list-item'
                                        : 'template-list-item'
                                }
                                key={template._id}
                                onClick={() => handleTemplateClick(template)}
                            >
                                <div className='template-list-item-text'>{template.name}</div>
                            </button>
                        ))
                    ) : (
                        <div className='text-center mt-5'>
                            <NoResultFound />
                            <p className='no-response-text'>No results found</p>
                        </div>
                    )}
                </div>
            </div>

            <div className='container_right'>
                <div className='container-header'>
                    <div className='container-header-block'>
                        <div className='container-title'>{templateData?.name}</div>
                        <div className='container-header-info'>
                            {templateData.anonymous ? AnonymousComponent : NonAnonymousComponent}
                            <SeparatorDot />
                            <QuestionBook /> {questions?.length} questions
                            <SeparatorDot />
                            <Clock /> {templateData?.time}
                        </div>
                    </div>
                    <div className='header-buttons'>
                        <button
                            className='ew-btn pb-btn margin-left-15'
                            onClick={() =>
                                history.push(
                                    `/dashboard/pulse/create?template=${templateData._id}&name=${templateData.name}`
                                )
                            }
                        >
                            Use template
                        </button>
                    </div>
                </div>
                <div className='separator'></div>
                <div className='question-list'>
                    {questions.map((question, index) => (
                        <div className='question-card' key={question.title}>
                            <div className='question-card-index'>Q{index + 1}</div>
                            <div className='question-card-content'>
                                <VoiletTag>{question.category}</VoiletTag>
                                <div>{question.title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const AnonymousComponent = (
    <>
        <EyeInvisible /> Anonymous
    </>
);

const NonAnonymousComponent = (
    <>
        <EyeVisible /> Non-Anonymous
    </>
);

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
