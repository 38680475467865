import React from 'react';
import EWModal from 'components/ReusableComponents/EWModal';
import UserSelectionDropdown from 'components/ReusableComponents/UserSelectionDropdown';
import LabelWithImage from 'components/ReusableComponents/LabelWithImage/index';
import PropTypes from 'prop-types';

const Index = ({
    open,
    onClose,
    onConfirm,
    data,
    loading,
    width = '554px',
    selectedUser,
    setSelectedUser,
    customClassname,
    buttonWidth = 292,
    dropdownWidth = 292,
    showTeam = false,
}) => {
    const handleUserSelection = (user) => {
        const optionSelected = {
            name: user.userName,
            pictureURL: user.pictureURL,
            _id: user._id,
            isAdmin: user?.isAdmin,
        };
        setSelectedUser(optionSelected);
    };

    if (!open) return null;

    const modalData = {
        heading: data.heading,
        subHeading: data.subHeading,
        rightButtonText: data.confirm,
        handleRightButtonClick: onConfirm,
        handleLeftButtonClick: onClose,
        loading: loading,
        disabled: loading || !selectedUser?.name,
    };

    return (
        <EWModal open={open} onClose={() => onClose(false)} width={width} modalData={modalData}>
            <div className={customClassname}>
                <UserSelectionDropdown
                    id={'selectAdmin'}
                    dropdownWidth={dropdownWidth}
                    buttonWidth={buttonWidth}
                    selectedUser={
                        <LabelWithImage
                            {...(selectedUser && {
                                image: selectedUser?.pictureURL || require('Assets/images/defaultUser.png'),
                            })}
                            name={selectedUser?.name || 'Select'}
                        />
                    }
                    handleUserSelection={handleUserSelection}
                    placeholder='Search name'
                    showTeam={showTeam}
                    optionsSelected={!!selectedUser}
                />
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    data: PropTypes.object,
    width: PropTypes.string,
    loading: PropTypes.bool,
    selectedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setSelectedUser: PropTypes.func,
    customClassname: PropTypes.string,
    buttonWidth: PropTypes.number,
    dropdownWidth: PropTypes.number,
    showTeam: PropTypes.bool,
};

export default Index;
