import React, { useState, useCallback } from 'react';
import { ReactComponent as SearchIcon } from 'Assets/images/search.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomCheckbox } from 'components';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { debounce, find } from 'lodash';
import { removeSpecialCharacters, showSnackBarMessage, sortUsersByDisability } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({
    dropDownID,
    dropdownStyleClass,
    dropdownWidth,
    dropdownHeight,
    selectedUsers,
    setSelectedUsers,
    handleUserSelection,
    searchIcon = true,
    placeholder,
    customAddSearch,
    isRestrictedOptionsAllowed = false,
    isOptionDisabledHandler,
    userNameHandler,
    width,
    email,
}) => {
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [user, setUser] = useState({ email: '' });
    const onClose = () => {
        setOpen(false);
    };

    const isChecked = (user) => {
        return !!find(selectedUsers, (selectedUser) => selectedUser._id === user._id || selectedUser.id === user._id);
    };

    const handleSearch = useCallback(
        debounce((value) => {
            setDebouncedSearch(value.trim());
        }, 300),
        []
    );

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && user.email.trim() !== '') {
            const isEmailAlreadySelected = selectedUsers.some((selectedUser) => selectedUser.email === user.email);
            if (!isEmailAlreadySelected) {
                setSelectedUsers([...selectedUsers, user]);
            }
            setUser({ email: '' });
            setDebouncedSearch('');
        }
    };

    const {
        data: dropdownData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery([GET_USERS_DATA, { search: debouncedSearch, team: false }], getUsers, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
        staleTime: 5 * 60 * 1000,
    });

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='container-user-search-dropdown' style={{ width: width || 'auto' }}>
                <div className='cusd-input-container'>
                    {searchIcon && <SearchIcon className='cusd-search-icon' />}
                    <input
                        className='cusd-input'
                        placeholder={placeholder}
                        onChange={(e) => {
                            const updatedValue = removeSpecialCharacters(e.target.value);
                            setSearchValue(updatedValue);
                            handleSearch(updatedValue);
                            if (email) {
                                setUser({ email: e.target.value });
                            }
                        }}
                        value={email ? user.email : searchValue}
                        onKeyDown={customAddSearch ? handleKeyPress : null}
                        onClick={() => setOpen(true)}
                    />
                </div>
                <div
                    id={dropDownID}
                    className={clsx({
                        'dropdown-container': true,
                        'display-none': !open,
                        [dropdownStyleClass]: dropdownStyleClass,
                    })}
                    style={{
                        height: dropdownHeight,
                        width: dropdownWidth,
                        top: '42px',
                    }}
                >
                    <div>
                        {debouncedSearch || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                            <div>
                                {dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                                    <div className='infinite-scroll-container'>
                                        <InfiniteScroll
                                            dataLength={dropdownData?.pages?.length * 9}
                                            next={fetchNextPage}
                                            hasMore={hasNextPage}
                                            height={190}
                                            loader={<CustomLoader size={10} />}
                                        >
                                            {dropdownData?.pages?.map((pageData) => {
                                                const users = pageData?.data?.users || [];
                                                const sortedUsers = sortUsersByDisability(
                                                    users,
                                                    isOptionDisabledHandler,
                                                    isRestrictedOptionsAllowed
                                                );

                                                return sortedUsers?.map((user) => {
                                                    const isDisabled =
                                                        isRestrictedOptionsAllowed && isOptionDisabledHandler(user);
                                                    return (
                                                        <button
                                                            className={clsx('dropdown-list', {
                                                                'dropdown-list-disabled': isDisabled,
                                                                'dropdown-item-selected': isChecked(user),
                                                            })}
                                                            key={user._id}
                                                            onClick={() => handleUserSelection(user)}
                                                            disabled={isDisabled}
                                                        >
                                                            <CustomCheckbox checked={isChecked(user)} />
                                                            <img
                                                                className='dropdown-list-user-image'
                                                                src={
                                                                    user.pictureURL ||
                                                                    require('Assets/images/defaultUser.png')
                                                                }
                                                                alt='user'
                                                            />
                                                            <span
                                                                className={clsx('dropdown-list-username', {
                                                                    'dropdown-list-username-selected': isChecked(user),
                                                                })}
                                                            >
                                                                {isRestrictedOptionsAllowed
                                                                    ? userNameHandler(user)
                                                                    : user.userName}
                                                            </span>
                                                        </button>
                                                    );
                                                });
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                ) : (
                                    <p className='p-2 text-muted'>No users found</p>
                                )}
                            </div>
                        ) : (
                            <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
                        )}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    dropDownID: PropTypes.string,
    dropdownStyleClass: PropTypes.string,
    dropdownWidth: PropTypes.number,
    dropdownHeight: PropTypes.number,
    selectedUsers: PropTypes.array,
    setSelectedUsers: PropTypes.func,
    handleUserSelection: PropTypes.func,
    searchIcon: PropTypes.bool,
    placeholder: PropTypes.string,
    customAddSearch: PropTypes.bool,
    isRestrictedOptionsAllowed: PropTypes.bool,
    isOptionDisabledHandler: PropTypes.func,
    userNameHandler: PropTypes.func,
    width: PropTypes.number,
    email: PropTypes.bool,
};

export default Index;
