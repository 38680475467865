import React from 'react';
import { ReactComponent as MagicWand } from 'Assets/images/enhanceAI-blue.svg';
export const KEY_PULSE_AUDIENCE_DATA = 'pulse-audience-data';
export const KEY_PULSE_SCHEDULE_DATA = 'pulse-schedule-data';
export const KEY_PULSE_QUESION_DATA = 'pulse-question-data';

export const PULSE_ROUTE = {
    HOME: '/dashboard/pulse',
    CREATE: '/dashboard/pulse/create',
    ANSWERS: '/dashboard/pulse/answer',
    TEMPLATES: '/dashboard/pulse/create/?list=true',
};

export const CONFIRM_MODALS_DATA = {
    RESUME_SURVEY: {
        name: 'RESUME_SURVEY',
        heading: 'Are you sure you want to resume survey?',
        confirm: 'Resume',
    },
    PAUSE_SURVEY: {
        name: 'PAUSE_SURVEY',
        heading: 'Are you sure you want to pause survey?',
        confirm: 'Pause',
    },
    DELETE_SURVEY: {
        name: 'DELETE_SURVEY',
        heading: 'Are you sure want to delete Survey?',
        subHeading: 'Once survey is deleted, you can’t recover it.',
        confirm: 'Delete',
    },
    STOP_SURVEY: {
        name: 'STOP_SURVEY',
        heading: 'Are you sure you want to stop this ongoing pulse?',
        subHeading: 'Once stopped, this pulse will not collect responses anymore.',
        confirm: 'Stop',
    },
    STOP_ROLLING_SURVEY: {
        name: 'STOP_ROLLING_SURVEY',
        heading: 'Are you sure you want to stop this rolling pulse?',
        subHeading: 'Once stopped, this pulse will not collect responses anymore.',
        confirm: 'Stop',
    },
    STOP_RECURRING_SURVEY: {
        name: 'STOP_RECURRING_SURVEY',
        heading: 'Are you sure you want to stop this recurring pulse?',
        subHeading: 'Once stopped, this pulse will not collect responses anymore',
        confirm: 'Stop',
    },
    STOP_FUTURE_RECURRING_SURVEY: {
        name: 'STOP_FUTURE_RECURRING_SURVEY',
        heading: 'Are you sure you want to stop all future recurring pulse?',
        subHeading: 'Once stopped, any future instances of this pulse will not be sent',
        confirm: 'Stop',
    },
    EDIT_ALL_RECURRING_SURVEY: {
        name: 'EDIT_ALL_RECURRING_SURVEY',
        heading: 'Select an option before you edit this pulse.',
        confirm: 'Continue',
    },
    EDIT_FUTURE_RECURRING_SURVEY: {
        name: 'EDIT_FUTURE_RECURRING_SURVEY',
        heading: 'Anything you edit will affect only future instances.',
        confirm: 'Continue',
    },
};

export const AI_PULSE_GEN_DATA = {
    heading: (
        <div>
            <MagicWand /> Create Pulse with AI
        </div>
    ),
    subHeading: (
        <div className='ai-pulse-sub-heading'>
            Enter a brief description or prompt that outlines what your survey should focus on. Our{' '}
            <span className='bold-text'>AI</span> will generate a customized survey for you based on your input.
        </div>
    ),
    promptInput: {
        heading: 'Prompt to generate Pulse Questions',
        placeholder: 'Enter your prompt here.',
    },
    questionCountInput: {
        heading: 'Number of Questions (optional)',
        placeholder: 'Enter number of questions to generate',
    },
};

export const REQUIRED_TAG = { color: '#F48D37', background: '#FFBA071A', fontWeight: '600' };

export const PULSE_TYPE = { ONE_TIME: 'one-time', RECURRING: 'recurring' };

export const PULSE_SUB_TYPE = { STANDARD: 'standard', ROLLING: 'rolling', ENPS: 'eNPS' };

export const PULSE_STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
    ONGOING: 'ongoing',
    INACTIVE: 'inactive',
    FUTURE: 'future',
};

export const PULSE_RESPONSE_STATUS = {
    NOT_ATTEMPTED: 'NOT_ATTEMPTED',
    PARTIALLY_COMPLETE: 'PARTIALLY_COMPLETE',
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    COMPLETE: 'COMPLETE',
};

export const EDIT_TYPE = { ONGOING: 'ongoing', FUTURE: 'future' };
export const QUESTION_TYPE = {
    STANDARD: 'standard',
    ADDED: 'added',
};
export const INSTANCES = [
    { HEADING: 'This Instance', SUBHEADING: 'Pulse will not accept responses anymore', value: 'ongoingInstances' },
    {
        HEADING: 'Future Instances',
        SUBHEADING: 'Any future instance of this pulse will not be sent',
        value: 'futureInstances',
    },
    {
        HEADING: 'All instances',
        SUBHEADING:
            'Pulse will not accept responses anymore for the current instance & any future instances of this pulse will not be sent.',
        value: 'allInstances',
    },
];

export const EDIT_INSTANCES = [
    {
        HEADING: 'Edit this instance',
        value: 'ongoing',
    },
    {
        HEADING: 'Edit only future instance',
        value: 'future',
    },
];

export const AI_PULSE_ERROR_STATES = {
    PROMPT_ERROR: 'You must enter at least 50 characters.',
    QUESTION_COUNT_ERROR: 'Please enter a number between 1 and 15.',
};
