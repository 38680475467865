import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from 'Assets/images/search.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import DropdownList from '../DropdownList';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { debounce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import { TEAM } from 'components/Dashboard/Content/Feedback/constants';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({
    dropDownID,
    dropdownWidth,
    dropdownHeight,
    selectedUsers,
    searchIcon,
    handleUserSelection,
    placeholder,
}) => {
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const { feedbackConfigs, managerId, userId } = useSelector(mapStateToProps, shallowEqual);
    const onClose = () => {
        setOpen(false);
        setSearch('');
    };

    const { fetchNextPage, hasNextPage, data } = useInfiniteQuery(
        [
            GET_USERS_DATA,
            { search, isActive: true, managerId: feedbackConfigs?.feedbackLevel === TEAM ? managerId : null },
        ],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
        }
    );

    const handleSearch = debounce((value) => {
        setSearch(value.trim());
    }, 300);

    const renderContent = (data) => {
        if (data?.pages[0]?.data?.users?.length > 0) {
            return data?.pages?.map((pageData) => {
                const users = pageData?.data?.users || [];
                return users
                    ?.filter((user) => user?._id !== userId)
                    .map((user) => (
                        <DropdownList
                            key={user._id}
                            user={user}
                            selectedUsers={selectedUsers}
                            handleUserSelection={handleUserSelection}
                        />
                    ));
            });
        }

        return <p className='p-2 text-muted'>No users found</p>;
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='container-user-search-dropdown'>
                <button className='cusd-input-container' onClick={() => setOpen(!open)}>
                    {searchIcon && <SearchIcon className='cusd-search-icon' />}
                    <p className='container-placeholder'>{placeholder}</p>
                </button>
                <div
                    id={dropDownID}
                    className={clsx({
                        'dropdown-container': true,
                        'display-none': !open,
                    })}
                    style={{
                        height: dropdownHeight,
                        width: dropdownWidth,
                        top: '42px',
                    }}
                >
                    {data?.pages ? (
                        <div className='infinite-scroll-container'>
                            <InfiniteScroll
                                dataLength={data?.pages?.length * 9}
                                next={fetchNextPage}
                                hasMore={hasNextPage}
                                height={190}
                                loader={<CustomLoader size={10} />}
                            >
                                <CustomSearchBox
                                    width='470px'
                                    handleSearch={handleSearch}
                                    placeholder={'Search'}
                                    value={search}
                                />
                                {renderContent(data)}
                            </InfiniteScroll>
                        </div>
                    ) : (
                        <p className='p-2 text-muted'>No users found</p>
                    )}
                </div>
            </div>
        </ClickAwayListener>
    );
};

const mapStateToProps = ({ Workspace, User }) => ({
    userId: User?._id,
    feedbackConfigs: Workspace.feedback,
    managerId: User?.managerId,
    placeholder: 'Select Users',
});

Index.propTypes = {
    dropDownID: PropTypes.string,
    dropdownWidth: PropTypes.number,
    dropdownHeight: PropTypes.number,
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
    searchIcon: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default Index;
