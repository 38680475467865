import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import TitleBar from 'components/ReusableComponents/TitleBar';
import FeedbackReceived from './FeedbackReceived';
import FeedbackGiven from './FeedbackGiven';
import { Tooltip } from 'components';
import EWButton from 'components/ReusableComponents/EWButton';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import NewFeedbackModal from './NewFeedbackModal';
import SendFeedbackModal from './SendFeedbackModal';
import RequestFeedbackModal from './RequestFeedbackModal';
import Banner from 'components/Dashboard/Content/Celebration/Banner';
import { ReactComponent as FeedbackDisabled } from 'Assets/images/feedback-disabled-banner.svg';
import FeedbackCreatedModal from 'components/Modals/PulseCreatedModal';
import { showSnackBarMessage, isSubscriptionCancelled, makePlural } from 'utils/HelperFunctions';
import { GET_ADMIN_LIST, GET_FEEDBACK_CONFIGURATION, GET_FEEDBACK_LIST, GET_PROFILE_DATA } from 'Services/apiKeys';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { getFeedbackConfiguration } from '../Services/apiFunctions';
import { getAdminList, getProfile } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { HISTORY_FROM, PLATFORM } from 'constants.js';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import { FEEDBACK, TEAM } from '../constants';
import './style.scss';

const FEEDBACK_RECEIVED = 'Feedback received';
const FEEDBACK_GIVEN = 'Feedback sent';

const Index = ({ history }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const feedbackId = params.get('viewFeedback');
    const recipientUserId = params.get('sendFeedback');
    const { feedbackConfigs, isAdmin, managerId, subscription, platform } = useSelector(mapStateToProps, shallowEqual);

    const [showReadFeedbackModal, setShowReadFeedbackModal] = useState(false);
    const [data, setData] = useState();
    const [key, setKey] = useState(FEEDBACK_RECEIVED);
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [newFeedbackModal, setNewFeedbackModal] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showRequestFeedbackModal, setShowRequestFeedbackModal] = useState(false);
    const [feedbackCreated, setFeedbackCreated] = useState(false);
    const [showNoManagerModal, setShowNoManagerModal] = useState(false);
    const [showCantRequestFeedbackModal, setShowCantRequestFeedbackModal] = useState(false);
    const [showMessageData, setShowMessageData] = useState({});
    const [recipientUserData, setRecipientUserData] = useState({});
    const [feedbackCategories, setFeedbackCategories] = useState([]);

    const feedbackSentModalData = useRef({
        title: '',
    });
    const currentFeedback = useRef();

    const receivedCount = data?.totalReceived ? ` (${data.totalReceived})` : '';
    const receivedTabTitle = FEEDBACK_RECEIVED + receivedCount;

    const sentCount = data?.totalSent ? ` (${data.totalSent})` : '';
    const sentTabTitle = FEEDBACK_GIVEN + sentCount;

    const { data: workspaceAdmins } = useQuery([GET_ADMIN_LIST], getAdminList);
    const { isLoading } = useQuery([GET_FEEDBACK_CONFIGURATION], getFeedbackConfiguration, {
        onSuccess: (data) => {
            const config = data?.data;
            if (config?.hasOwnProperty('enabled')) {
                updateWorkspaceDetails(dispatch, { feedback: { ...config } });
            }
            setFeedbackCategories(config?.categories || []);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });
    const { data: userData } = useQuery([GET_PROFILE_DATA, recipientUserId], getProfile, {
        enabled: !!recipientUserId,
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const admins = (workspaceAdmins?.data || []).map((admin) => admin.userName);
    const NO_MANAGER_MODAL_DATA = (() => {
        const maxDisplayAdmins = 2;
        const adminsList = admins.slice(0, maxDisplayAdmins);
        const remainingCount = admins.length - maxDisplayAdmins;
        const remainingAdmins = admins.slice(maxDisplayAdmins);
        const displayedAdmins = adminsList.join(', ');
        return {
            confirm: 'Close',
            heading: `Cannot ${showCantRequestFeedbackModal ? 'request' : 'send'} feedback`,
            subHeading: (
                <div>
                    <p>
                        You need a manager to be assigned to you before you can{' '}
                        {showCantRequestFeedbackModal ? 'request' : 'send'} feedback. Since no manager is assigned to
                        you, contact your admins for help.
                    </p>
                    <p>
                        Admins:{' '}
                        <b className='workspace-admins-list'>
                            {displayedAdmins}
                            {remainingCount > 0 && (
                                <>
                                    {' and '}
                                    <Tooltip
                                        arrow
                                        title={<div className='tooltip-content'>{remainingAdmins.join(', ')}</div>}
                                    >
                                        <span className='admin-others-count'>
                                            {makePlural(remainingCount, 'other', false)}
                                        </span>
                                    </Tooltip>
                                </>
                            )}
                        </b>
                    </p>
                </div>
            ),
        };
    })();
    /**
     * Opens feedback sent modal and sets the data required to show
     * @param {string} messageUrl - slack message url to open
     * @param {string} userName - name of the user to whom the feedback was sent
     * @param {boolean} anonymous - feedback was sent anonymously or not
     */
    const handleFeedbackCreatedModalSuccess = (messageUrl, userName, anonymous) => {
        setShowFeedbackModal(false);
        feedbackSentModalData.current = {
            ...feedbackSentModalData.current,
            content: `Your feedback was shared with ${userName} ${anonymous ? 'anonymously' : ''}`,
            buttonText: 'View feedback',
            title: 'Feedback sent successfully',
            messageUrl,
        };
        setFeedbackCreated(true);
    };

    const handleRequestFeedbackModalSuccess = (usersName) => {
        setShowRequestFeedbackModal(false);

        let displayNames;
        if (usersName.length > 3) {
            displayNames = `${usersName.slice(0, 3).join(', ')} and ${usersName.length - 3} more`;
        } else {
            displayNames = usersName.join(', ');
        }

        feedbackSentModalData.current = {
            title: 'Feedback requested successfully',
            content: `Your feedback request was shared with ${displayNames}`,
        };
        setFeedbackCreated(true);
    };

    const handleShowFeedbackModalClick = () => {
        if (isSubscriptionCancelled(subscription)) {
            setSubscriptionCancelled(true);
            return;
        }
        if (feedbackConfigs?.feedbackLevel === TEAM && !managerId) {
            setShowNoManagerModal(true);
            return;
        }
        setShowFeedbackModal(true);
    };

    const requestFeedbackHandler = () => {
        if (feedbackConfigs?.feedbackLevel === TEAM && !managerId) {
            setShowNoManagerModal(true);
            setShowCantRequestFeedbackModal(true);
            return;
        }
        // requestFeebackhandler
        setShowRequestFeedbackModal(true);
    };

    const handleSentFeedbackShowMessage = () => {
        if (platform !== PLATFORM.SLACK) {
            currentFeedback.current = {
                messageUrl: feedbackSentModalData.current.messageUrl,
                ...showMessageData,
            };
            setKey(FEEDBACK_GIVEN);
            setFeedbackCreated(false);
            setShowReadFeedbackModal(true);
        } else {
            window.open(feedbackSentModalData.current?.messageUrl, '_blank');
            setFeedbackCreated(false);
            queryClient.invalidateQueries(GET_FEEDBACK_LIST);
        }
    };

    const closeNoManagerModal = () => {
        setShowNoManagerModal(false);
        setShowCantRequestFeedbackModal(false);
    };

    useEffect(() => {
        if (feedbackId) {
            const item = data?.data?.find((feedbackItem) => feedbackItem._id === feedbackId);
            if (item) {
                currentFeedback.current = item;
                setShowReadFeedbackModal(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if (recipientUserId) {
            const { _id, Name: userName, memberID, imageURL: pictureURL } = userData || {};
            setRecipientUserData({ _id, userName, memberID, pictureURL });
            handleShowFeedbackModalClick();
        }
    }, [recipientUserId, userData]);

    return (
        <div className='feedback-container navigatortabs'>
            <TitleBar title='Feedbacks'>
                {feedbackConfigs?.enabled && data?.data.length > 0 && (
                    <EWButton onClick={() => setNewFeedbackModal(true)}>
                        <div className='d-flex align-items-center'>
                            <span className='new-feedback-button-plus-icon'>+</span>
                            <span className='ml-2'>New Feedback</span>
                        </div>
                    </EWButton>
                )}
            </TitleBar>
            {isAdmin && !isLoading && !feedbackConfigs?.enabled && (
                <div className='mb-3'>
                    <Banner
                        heading={'You have disabled 360º feedback'}
                        subHeading={'Please enable to access it'}
                        buttonText='Enable 360º feedback'
                        handleClick={() => history.push(FEEDBACK.CONFIGURATION)}
                        image={<FeedbackDisabled className='feedback-banner' />}
                    />
                </div>
            )}
            <Tabs className='my-feedback-tab' activeKey={key} onSelect={setKey}>
                <Tab eventKey={FEEDBACK_RECEIVED} title={receivedTabTitle}>
                    {key === FEEDBACK_RECEIVED && (
                        <FeedbackReceived
                            history={history}
                            data={data}
                            setData={setData}
                            feedBackEnabled={feedbackConfigs?.enabled}
                            setNewFeedbackModal={setNewFeedbackModal}
                            platform={platform}
                            showReadFeedbackModal={showReadFeedbackModal}
                            setShowReadFeedbackModal={setShowReadFeedbackModal}
                            currentFeedback={currentFeedback}
                        />
                    )}
                </Tab>
                <Tab eventKey={FEEDBACK_GIVEN} title={sentTabTitle}>
                    {key === FEEDBACK_GIVEN && (
                        <FeedbackGiven
                            history={history}
                            data={data}
                            setData={setData}
                            feedBackEnabled={feedbackConfigs?.enabled}
                            setNewFeedbackModal={setNewFeedbackModal}
                            platform={platform}
                            showReadFeedbackModal={showReadFeedbackModal}
                            setShowReadFeedbackModal={setShowReadFeedbackModal}
                            currentFeedback={currentFeedback}
                        />
                    )}
                </Tab>
            </Tabs>
            {newFeedbackModal && (
                <NewFeedbackModal
                    open={newFeedbackModal}
                    onClose={() => setNewFeedbackModal(false)}
                    handleShowSendFeedbackModal={handleShowFeedbackModalClick}
                    handleShowRequestFeedbackModal={requestFeedbackHandler}
                />
            )}
            {showFeedbackModal && (
                <SendFeedbackModal
                    open={showFeedbackModal}
                    onSuccess={handleFeedbackCreatedModalSuccess}
                    onClose={() => setShowFeedbackModal(false)}
                    categories={feedbackCategories}
                    history={history}
                    setShowMessageData={setShowMessageData}
                    recipientUser={recipientUserData}
                />
            )}
            {showRequestFeedbackModal && (
                <RequestFeedbackModal
                    open={showRequestFeedbackModal}
                    onClose={() => setShowRequestFeedbackModal(false)}
                    onSuccess={handleRequestFeedbackModalSuccess}
                />
            )}
            <FeedbackCreatedModal
                open={feedbackCreated}
                data={feedbackSentModalData.current}
                onConfirm={handleSentFeedbackShowMessage}
                onClose={() => {
                    setFeedbackCreated(false);
                    queryClient.invalidateQueries(GET_FEEDBACK_LIST);
                }}
            />
            <ConfirmModal
                open={showNoManagerModal}
                onClose={closeNoManagerModal}
                onConfirm={closeNoManagerModal}
                data={NO_MANAGER_MODAL_DATA}
                singleButton
                height='235px'
                width='594px'
                subHeadingColor='#15222F'
            />
            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={HISTORY_FROM.FEEDBACK}
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = ({ Workspace, User, Payments }) => ({
    feedbackConfigs: Workspace.feedback,
    isAdmin: User.isAdmin,
    managerId: User?.managerId,
    subscription: Payments?.subscription,
    platform: Workspace.platform,
});

export default Index;
