/**
 * @fileoverview Custom Menu Component
 *
 * A flexible, portal-based dropdown menu solution that renders at the document root level.
 * Features include:
 * - Intelligent positioning with viewport awareness
 * - Position-aware flipping based on preferred placement
 * - Dynamic scrolling for oversized menus
 * - Keyboard interactions (Escape to close)
 * - Click-outside detection
 * - Scroll behavior management
 *
 * Position options:
 * - BOTTOM_LEFT: Menu appears below anchor, aligned to left edge
 * - BOTTOM_RIGHT: Menu appears below anchor, aligned to right edge
 * - TOP_LEFT: Menu appears above anchor, aligned to left edge
 * - TOP_RIGHT: Menu appears above anchor, aligned to right edge
 *
 * When space is constrained, the menu will flip vertically while maintaining
 * horizontal alignment (e.g., BOTTOM_LEFT flips to TOP_LEFT)
 *  * @example
 * // Basic usage
 * const MyComponent = () => {
 *   const [isOpen, setIsOpen] = useState(false);
 *   const buttonRef = useRef(null);
 *
 *   return (
 *     <>
 *       <button ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
 *         Toggle Menu
 *       </button>
 *       <CustomMenu
 *         open={isOpen}
 *         anchorEl={buttonRef.current}
 *         onClose={() => setIsOpen(false)}
 *       >
 *         <MenuList>
 *           <button onClick={() => {}}>Menu Item 1</button>
 *           <button onClick={() => {}}>Menu Item 2</button>
 *         </MenuList>
 *       </CustomMenu>
 *     </>
 *   );
 * };
 *
 * @example
 * // Usage with custom positioning and offset
 * <CustomMenu
 *   open={isOpen}
 *   anchorEl={buttonRef.current}
 *   onClose={() => setIsOpen(false)}
 *   position={MENU_POSITIONS.BOTTOM_RIGHT}
 *   offset={{ top: 8, left: 12 }}
 *   className="custom-menu-class"
 * >
 *   <MenuList>
 *     {items.map(item => (
 *       <button key={item.id} onClick={item.action}>
 *         {item.label}
 *       </button>
 *     ))}
 *   </MenuList>
 * </CustomMenu>
 */

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DEFAULT_OFFSET, MENU_POSITIONS, DEFAULT_Z_INDEX, EVENT_KEYS } from './constants';
import './styles.scss';

// Constants for positioning calculations
const SPACE_THRESHOLD = 32;
const MAX_HEIGHT = 400;

// Mapping of positions to their flipped variants
const FLIPPED_POSITIONS = {
    [MENU_POSITIONS.BOTTOM_LEFT]: MENU_POSITIONS.TOP_LEFT,
    [MENU_POSITIONS.BOTTOM_RIGHT]: MENU_POSITIONS.TOP_RIGHT,
    [MENU_POSITIONS.TOP_LEFT]: MENU_POSITIONS.BOTTOM_LEFT,
    [MENU_POSITIONS.TOP_RIGHT]: MENU_POSITIONS.BOTTOM_RIGHT,
};
/**
 * MenuList Component
 *
 * A container component that provides consistent padding and styling for menu items.
 * Should be used as a direct child of CustomMenu for proper styling.
 *
 * @component
 * @example
 * <MenuList>
 *   <button onClick={() => {}}>Option 1</button>
 *   <button onClick={() => {}}>Option 2</button>
 * </MenuList>
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Menu items to be rendered
 * @param {string} [props.className] - Additional CSS class for custom styling
 * @returns {React.ReactElement} Rendered MenuList component
 */
const MenuList = ({ children, className = '' }) => {
    return <div className={`menu-list ${className}`}>{children}</div>;
};

/**
 * CustomMenu Component
 *
 * A portal-based dropdown menu component that handles positioning and user interactions.
 * Features automatic viewport awareness and overflow protection.
 *
 * @component
 * @example
 * // Basic usage with required props
 * const [isOpen, setIsOpen] = useState(false);
 * const buttonRef = useRef(null);
 *
 * <CustomMenu
 *   open={isOpen}
 *   anchorEl={buttonRef.current}
 *   onClose={() => setIsOpen(false)}
 * >
 *   <MenuList>
 *     <button>Click me</button>
 *   </MenuList>
 * </CustomMenu>
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls menu visibility
 * @param {HTMLElement} props.anchorEl - Element to which the menu is anchored
 * @param {Function} props.onClose - Callback function when menu should close
 * @param {React.ReactNode} props.children - Menu content
 * @param {string} [props.className] - Additional CSS class for custom styling
 * @param {Object} [props.offset] - Offset from anchor element { top: number, left: number }
 * @param {string} [props.position] - Menu position relative to anchor. One of: BOTTOM_LEFT, BOTTOM_RIGHT, TOP_LEFT, TOP_RIGHT
 * @param {number} [props.zIndex] - CSS z-index value
 * @returns {React.ReactPortal | null} Rendered menu portal or null if closed
 */
const CustomMenu = ({
    open,
    anchorEl,
    onClose,
    children,
    className,
    offset = DEFAULT_OFFSET,
    position = MENU_POSITIONS.BOTTOM_LEFT,
    zIndex = DEFAULT_Z_INDEX,
}) => {
    const menuRef = useRef(null);
    const [currentPosition, setCurrentPosition] = useState(position);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        const handleScroll = (event) => {
            if (menuRef.current?.contains(event.target)) return;
            onClose();
        };

        const handleResize = () => {
            if (open) adjustMenuPosition();
        };

        const handleKeyDown = (event) => {
            if (EVENT_KEYS.includes(event.key)) onClose();
        };

        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleResize);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('scroll', handleScroll, true);
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose, open]);

    useEffect(() => {
        const handleOpen = () => {
            // Adjust menu position when the menu is open
            if (menuRef.current) adjustMenuPosition();
        };

        const handleClose = () => {
            // Reset to preferred position when menu closes
            setCurrentPosition(position);
        };

        open ? handleOpen() : handleClose();
    }, [open, position]);

    /**
     * Determines if a position is a top position
     * @private
     * @param {string} pos - Position to check
     * @returns {boolean} True if position is a top position
     */
    const isTopPosition = (pos) => {
        return pos === MENU_POSITIONS.TOP_LEFT || pos === MENU_POSITIONS.TOP_RIGHT;
    };

    /**
     * Determines if a position is a right position
     * @private
     * @param {string} pos - Position to check
     * @returns {boolean} True if position is a right position
     */
    const isRightPosition = (pos) => {
        return pos === MENU_POSITIONS.BOTTOM_RIGHT || pos === MENU_POSITIONS.TOP_RIGHT;
    };

    /**
     * Calculates available space and determines optimal menu position
     * Maintains horizontal alignment while flipping vertically if needed
     * @private
     */
    const adjustMenuPosition = () => {
        if (!menuRef.current || !anchorEl) return;

        const menuRect = menuRef.current.getBoundingClientRect();
        const anchorRect = anchorEl.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        // Calculate available space
        const spaceBelow = viewportHeight - anchorRect.bottom;
        const spaceAbove = anchorRect.top;
        const menuHeight = menuRect.height;

        // Determine if we need to flip from the current position
        const isCurrentlyTop = isTopPosition(currentPosition);
        const availableSpace = isCurrentlyTop ? spaceAbove : spaceBelow;
        const oppositeSpace = isCurrentlyTop ? spaceBelow : spaceAbove;

        // Flip if there's not enough space in current position and more space in opposite position
        if (availableSpace < menuHeight + SPACE_THRESHOLD && oppositeSpace > availableSpace) {
            setCurrentPosition(FLIPPED_POSITIONS[currentPosition]);
        }

        // Handle horizontal positioning
        if (isRightPosition(currentPosition)) {
            const rightOverflow = anchorRect.right > viewportWidth;
            if (rightOverflow) {
                menuRef.current.style.left = `${Math.max(0, viewportWidth - menuRect.width)}px`;
            }
        } else {
            const leftOverflow = anchorRect.left + menuRect.width > viewportWidth;
            if (leftOverflow) {
                menuRef.current.style.left = `${Math.max(0, viewportWidth - menuRect.width)}px`;
            }
        }

        // Add scrolling if needed
        const needsScroll = menuHeight > availableSpace - SPACE_THRESHOLD;
        setIsScrollable(needsScroll);
    };

    if (!anchorEl || !open) return null;

    const rect = anchorEl.getBoundingClientRect();

    /**
     * Calculates menu position based on current position state
     * @private
     * @returns {Object} CSS position properties
     */
    const getMenuPosition = () => {
        const isTop = isTopPosition(currentPosition);
        const isRight = isRightPosition(currentPosition);

        const basePosition = {
            position: 'fixed',
            ...(isTop
                ? {
                      bottom: window.innerHeight - rect.top + offset.top,
                  }
                : {
                      top: rect.bottom + offset.top,
                  }),
            ...(isRight
                ? {
                      left: rect.right - offset.left,
                      transform: 'translateX(-100%)',
                  }
                : {
                      left: rect.left + offset.left,
                  }),
        };

        return {
            ...basePosition,
            maxWidth: '90vw',
            transition: 'all 0.2s ease',
        };
    };

    const style = {
        ...getMenuPosition(),
        zIndex,
    };

    return ReactDOM.createPortal(
        <ClickAwayListener onClickAway={onClose}>
            <div
                ref={menuRef}
                className={`custom-menu ${className} ${currentPosition.toLowerCase()} ${
                    isScrollable ? 'scrollable' : ''
                }`}
                style={style}
            >
                {children}
            </div>
        </ClickAwayListener>,
        document.body
    );
};

CustomMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.instanceOf(Element),
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    offset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    position: PropTypes.oneOf(Object.values(MENU_POSITIONS)),
    zIndex: PropTypes.number,
};

MenuList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export { CustomMenu, MenuList, MENU_POSITIONS };
