import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({ open, onClose, data, userButtonHandler, currentUserId }) => {
    const modalData = {
        heading: data?.receiver?._id === currentUserId ? 'Feedback received' : 'Feedback sent',
    };

    return (
        <EWModal open={open} onClose={onClose} width='450px' modalData={modalData}>
            <div className='read-feedback-modal-container'>
                <div className='feedback-user-date-container'>
                    <div className='read-feedback-info-container'>
                        <div>{data?.receiver?._id === currentUserId ? 'Received from' : 'Sent to'}</div>
                        {userButtonHandler(data, data?.receiver?._id === currentUserId ? 'sender' : 'receiver')}
                    </div>
                    <div className='read-feedback-info-container'>
                        <div>Sent on</div>
                        {format(parseISO(data.date), 'do MMM, yyyy')}
                    </div>
                </div>

                {data.strength.length > 0 && (
                    <div className='read-feedback-info-container'>
                        <div className='read-feedback-heading'>Strength</div>
                        <div>{data.strength.join(', ')}</div>
                    </div>
                )}
                {data.improvement.length > 0 && (
                    <div className='read-feedback-info-container'>
                        <div className='read-feedback-heading'>Needs Improvement</div>
                        <div>{data.improvement.join(', ')}</div>
                    </div>
                )}

                <div className='read-feedback-info-container'>
                    <div className='read-feedback-heading'>Feedback message</div>
                    <div className='feedback-message-container'>{data.message}</div>
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    userButtonHandler: PropTypes.func,
    currentUserId: PropTypes.string,
};

export default Index;
