import PromiseWindow from "./PromiseWindow.js";
import { castArray, get, uniqBy } from "lodash";
import deepmerge from "deepmerge";
import load from "load-script";

const BASE_URL = "https://app.truto.one";
const API_BASE_URL = "https://api.truto.one";

const createIframe = (url, onResponse) => {
  const iframe = document.createElement("iframe");
  const eventListener = (event) => {
    if (
      ["http://localhost:5173", "https://app.truto.one"].includes(event.origin)
    ) {
      if (event.data.error && event.data.is_truto) {
        const err = new Error(event.data.error);
        Object.keys(event.data).forEach((key) => {
          err[key] = event.data[key];
        });
        window.removeEventListener("message", eventListener);
        iframe.remove();
        onResponse(err);
      }
      if (event.data === "closed" || event.data.is_truto) {
        window.removeEventListener("message", eventListener);
        iframe.remove();
        onResponse(event.data);
      }
    }
  };
  window.addEventListener("message", eventListener);
  iframe.src = url;
  iframe.style.position = "fixed";
  iframe.style.top = "0";
  iframe.style.left = "0";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.border = "none";
  iframe.style.background = "rgba(0, 0, 0, 0.8)";
  iframe.id = "truto-link-iframe";
  document.body.appendChild(iframe);
};

const authenticate = (linkToken, options = {}) => {
  const iframe = options.iframe !== false;
  const sameWindow = options.sameWindow === true;
  const url = new URL(`${options.baseUrl || BASE_URL}/connect-account`);
  url.searchParams.append("link_token", linkToken);
  if (options.integration) {
    url.searchParams.append("integration", options.integration);
  }
  if (options.noBack) {
    url.searchParams.append("no_back", options.noBack);
  }
  if (options.authFormat) {
    url.searchParams.append("auth_format", options.authFormat);
  }
  if (options.skipRapidForm) {
    url.searchParams.append("skip_rapid_form", "true");
  }
  if (iframe && !sameWindow) {
    url.searchParams.append("iframe", "true");
  }
  if (options.integrations) {
    url.searchParams.append("integrations", options.integrations.join(","));
  }

  if (options.sameWindow) {
    window.location.href = url.toString();
    return;
  }

  if (iframe) {
    return new Promise((resolve, reject) => {
      createIframe(url.toString(), (result) => {
        if (result === "closed") {
          return reject(result);
        }
        if (result.error) {
          return reject(result);
        }
        resolve(result);
      });
    });
  }

  const promiseWindow = new PromiseWindow(url.toString(), {
    windowName: "Truto Connect",
    width: options.width || 700,
    height: options.height || 800,
  });

  return new Promise((resolve, reject) => {
    promiseWindow.open().then(
      (result) => {
        if (result.error) return reject(result);
        resolve(result);
      },
      function (error) {
        reject(error);
      },
    );
  });
};

const updateIntegratedAccountContext = async (
  integratedAccountToken,
  items,
) => {
  const integratedAccount = await fetchIntegratedAccount(
    integratedAccountToken,
  );
  const response = await fetch(`${API_BASE_URL}/integrated-account/me`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${integratedAccountToken}`,
    },
    body: JSON.stringify({
      context: {
        ...integratedAccount.context,
        drive_items: items,
      },
    }),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

const fetchIntegratedAccount = async (integratedAccountToken) => {
  const response = await fetch(`${API_BASE_URL}/integrated-account/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${integratedAccountToken}`,
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
};

async function useSharepointFilePicker(integratedAccountToken, config = {}) {
  return new Promise(async (resolve, reject) => {
    let integratedAccount;
    try {
      integratedAccount = await fetchIntegratedAccount(integratedAccountToken);
    } catch (err) {
      return reject(err.message);
    }
    let win = window.open("", "Picker", "width=1080,height=680");
    let port = null;
    const options = deepmerge(
      {
        sdk: "8.0",
        messaging: {
          channelId: crypto.randomUUID(),
          origin: window.location.origin,
        },
        search: {
          enabled: true,
        },
        selection: {
          mode: "multiple",
          enablePersistence: true,
          enableNotifications: false,
          sourceItems: get(integratedAccount, "context.drive_items", []),
        },
        upload: {
          enabled: true,
        },
        createFolder: {
          enabled: true,
        },
      },
      config,
    );

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(options),
      locale: "en-us",
    });

    const baseUrl = get(integratedAccount, "context.rootSiteUrl");

    const url = baseUrl + `/_layouts/15/FilePicker.aspx?${queryString}`;

    const form = win.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");

    win.document.body.append(form);

    form.submit();
    window.addEventListener("message", (event) => {
      if (event.source && event.source === win) {
        const message = event.data;
        if (
          message.type === "initialize" &&
          message.channelId === options.messaging.channelId
        ) {
          port = event.ports[0];
          port.addEventListener("message", messageListener);
          port.start();
          port.postMessage({
            type: "activate",
          });
        }
      }
    });

    async function messageListener(message) {
      switch (message.data.type) {
        case "notification":
          break;
        case "command":
          port.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });
          const command = message.data.data;
          switch (command.command) {
            case "close":
              win.close();
              break;
            case "pick":
              const pickedItems = uniqBy(castArray(command.items), "id");
              await updateIntegratedAccountContext(
                integratedAccountToken,
                pickedItems,
              );
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });
              win.close();
              resolve(pickedItems);
              break;
            default:
              reject(`Unsupported command: ${JSON.stringify(command)}`);
              port.postMessage({
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command,
                },
                isExpected: true,
              });
              break;
          }
          break;
      }
    }
  });
}

export const rapidForm = (integratedAccountToken, options = {}) => {
  const iframe = options.iframe !== false;
  const sameWindow = options.sameWindow === true;
  const url = new URL(
    `${options.baseUrl || BASE_URL}/connect-account/post-connect`,
  );
  url.searchParams.append(
    "integrated_account_session_token",
    integratedAccountToken,
  );

  if (options.integration) {
    url.searchParams.append("integration", options.integration);
  }
  if (iframe) {
    url.searchParams.append("iframe", "true");
  }
  if (options.additionalContext) {
    url.searchParams.append(
      "truto_additional_context",
      JSON.stringify(options.additionalContext),
    );
  }
  if (options.preventDeselect) {
    url.searchParams.append(
      "truto_prevent_deselect",
      JSON.stringify(options.preventDeselect),
    );
  }
  if (options.disabledFields) {
    url.searchParams.append(
      "truto_disabled_fields",
      JSON.stringify(options.disabledFields),
    );
  }

  if (options.sameWindow) {
    window.location.href = url.toString();
    return;
  }

  const promiseWindow = new PromiseWindow(url, {
    windowName: "Truto RapidForm",
    width: options.width || 700,
    height: options.height || 800,
  });

  if (iframe) {
    return new Promise((resolve, reject) => {
      createIframe(url, (result) => {
        if (result === "closed") {
          return reject(result);
        }
        if (result.error) {
          return reject(result);
        }
        resolve(result);
      });
    });
  }

  return new Promise((resolve, reject) => {
    promiseWindow.open().then(
      (result) => {
        if (result.error) return reject(result);
        resolve(result);
      },
      function (error) {
        reject(error);
      },
    );
  });
};

export const showFilePicker = (
  integrationName,
  integratedAccountToken,
  config = {},
) => {
  switch (integrationName) {
    case "sharepoint":
      return useSharepointFilePicker(integratedAccountToken, config);
    case "googledrive":
      return useGoogleFilePicker(integratedAccountToken);
    default:
      break;
  }
};

async function initializePicker() {
  await gapi.client.load(
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  );
}

export async function useGoogleFilePicker(integratedAccountToken) {
  await new Promise((resolve, reject) => {
    load("https://apis.google.com/js/api.js", function (err) {
      if (err) {
        reject(err);
      } else {
        gapi.load("client:picker", initializePicker);
        resolve();
      }
    });
  });
  await new Promise((resolve, reject) => {
    load("https://accounts.google.com/gsi/client", function (err) {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
  return new Promise(async (resolve, reject) => {
    let integratedAccount;
    try {
      integratedAccount = await fetchIntegratedAccount(integratedAccountToken);
    } catch (err) {
      return reject(err.message);
    }
    await gapi.client.load(
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
    );
    const accessToken = get(
      integratedAccount,
      "context.oauth.token.access_token",
    );
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setTitle("Select files")
      .setOAuthToken(accessToken)
      .addView(
        new google.picker.DocsView(google.picker.ViewId.DOCS)
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true),
      )
      .addView(new google.picker.DocsView().setEnableDrives(true))
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
    async function pickerCallback(data) {
      if (data.action === google.picker.Action.PICKED) {
        await updateIntegratedAccountContext(integratedAccountToken, data.docs);
        resolve(data.docs);
      }
    }
  });
}

export default authenticate;
