import React, { useEffect, useState } from 'react';
import { ReactComponent as EngageWithLogo } from 'Assets/images/engagewith-certificate.svg';
import { ReactComponent as CertificateLogo } from 'Assets/images/certificate-logo.svg';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Certificate = ({ awardName, awardee, awardDate, certificateRef, awardIcon, setLoading }) => {
    const { workspace } = useSelector(mapStateToProps, shallowEqual);

    // State to hold the base64 image data for awardIcon
    const [awardIconBase64, setAwardIconBase64] = useState('');
    const [workspaceLogoBase64, setWorkspaceLogoBase64] = useState('');

    useEffect(() => {
        // Convert the awardIcon to a base64 Data URL if provided
        if (awardIcon) {
            setLoading(true);
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = awardIcon;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                setAwardIconBase64(canvas.toDataURL('image/png'));
                setLoading(false);
            };
        }

        // Convert the workspace logo to a base64 Data URL if provided
        if (workspace.workspaceProfilePhoto) {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = workspace.workspaceProfilePhoto;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                setWorkspaceLogoBase64(canvas.toDataURL('image/png'));
            };
        }
    }, [awardIcon, workspace.workspaceProfilePhoto]);

    return (
        <div>
            <div ref={certificateRef} className='certificate-wrapper'>
                <div className='engagewith-logo'>
                    <EngageWithLogo height={'16px'} />
                </div>
                <div className='certificate-header'>
                    {awardIconBase64 ? (
                        <img src={awardIconBase64} alt='Award Icon' height={'90px'} width={'90px'} />
                    ) : (
                        <CertificateLogo height={'90px'} width={'90px'} />
                    )}
                    <span>{awardName}</span>
                </div>
                <div className='certificate-descrition'>
                    This <b>Honourable Certificate of Recognition</b> is proudly awarded to{' '}
                </div>
                <div className='certificate-awardee-section'>
                    <h1 className='certificate-awardee'>{awardee}</h1>
                    <p className='certificate-awardee-desc'>
                        Kudos to your perseverance, work ethics, ownership, and the invaluable efforts. You have aced
                        <br />
                        your work like no other and we are thankful for all that you do!
                    </p>
                </div>

                <div className='certificate-footer'>
                    <span>{awardDate}</span>
                    <div className='d-flex'>
                        {workspaceLogoBase64 && (
                            <img
                                src={workspaceLogoBase64}
                                alt={workspace.workspaceName}
                                height={'40px'}
                                width={'40px'}
                            />
                        )}
                        <p className='certificate-workspace-name'>{workspace.workspaceName}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    workspace: Workspace,
});

Certificate.propTypes = {
    awardDate: PropTypes.string,
    awardName: PropTypes.string,
    awardee: PropTypes.string,
    certificateRef: PropTypes.object,
    awardIcon: PropTypes.string,
    setLoading: PropTypes.func,
};
export default Certificate;
