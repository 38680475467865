import React from 'react';
import clsx from 'clsx';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import UserSearchDropdown from 'components/ReusableComponents/UserSearchDropdown';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const Index = ({
    open,
    onClose,
    modalData,
    selectedUsers,
    setSelectedUsers,
    handleSave,
    loading,
    customClassname,
    width = '594px',
}) => {
    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
        } else {
            const reducedUser = {
                memberId: user.memberID,
                pictureURL: user.pictureURL,
                _id: user._id,
                name: user.userName,
            };
            setSelectedUsers([...selectedUsers, reducedUser]);
        }
    };

    return (
        <EWModal open={open} onClose={() => onClose(false)} width={width} modalData={modalData}>
            <div className={clsx('modal-container', customClassname)}>
                <div>
                    <UserSearchDropdown
                        dropDownID='searchAdmins'
                        dropdownWidth={500}
                        dropdownHeight={200}
                        width={500}
                        selectedUsers={selectedUsers}
                        handleUserSelection={handleUserSelection}
                        placeholder='Select for users'
                        customAddSearch={true}
                    />
                    <CustomUserTags users={selectedUsers} onRemove={(user) => setSelectedUsers(user)} />
                </div>
                <div className='admins-button-container'>
                    <EWButton
                        onClick={() => handleSave(selectedUsers)}
                        disabled={selectedUsers?.length === 0}
                        loading={loading}
                        buttonText='Save'
                    />
                </div>
            </div>
        </EWModal>
    );
};

Index.defaultProps = {
    customClassname: '',
    width: '594px',
    loading: false,
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    setSelectedUsers: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    customClassname: PropTypes.string,
    width: PropTypes.string,
};

export default Index;
