import React, { useState } from 'react';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import UserFilterList from './UserFilterList';
import { useToaster } from 'Context/SnackbarContext';
import { debounce } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import PropTypes from 'prop-types';
import './style.scss';

const AllUsersTab = ({ selectedUsers, handleUserSelection }) => {
    const { SetSnackbar } = useToaster();
    const [search, setSearch] = useState('');

    const {
        data: dropdownData,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery([GET_USERS_DATA, { search }], getUsers, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
        retry: 0,
        staleTime: 5 * 60 * 1000,
    });

    const handleSearch = debounce((value) => {
        setSearch(value.trim());
    }, 300);

    return (
        <div>
            {search || dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                <div>
                    <CustomSearchBox
                        containerClassName='cr-user-filter-dropdown-search'
                        width='100%'
                        handleSearch={handleSearch}
                        placeholder='Search for users in my organisation'
                        value={search}
                    />
                    {dropdownData?.pages[0]?.data?.users?.length > 0 ? (
                        <UserFilterList
                            dropdownData={dropdownData}
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage}
                            selectedUsers={selectedUsers}
                            handleUserSelection={(user) => handleUserSelection(user)}
                        />
                    ) : (
                        <p className='p-2 text-muted'>No users found</p>
                    )}
                </div>
            ) : (
                <div>{(isLoading || isFetching) && <CustomLoader size={10} />}</div>
            )}
        </div>
    );
};

AllUsersTab.propTypes = {
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default AllUsersTab;
