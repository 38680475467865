import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Results from './Results';
import Information from './Information';
import Responses from './Responses';
import { useToaster } from 'Context/SnackbarContext';
import { downLoadFile } from 'Services/apiCall';
import download from 'downloadjs';
import FilterDropDown from 'components/ReusableComponents/FilterDropDown';
import { showSnackBarMessage, formatDate, getOrdinal } from 'utils/HelperFunctions';
import InsightsLocalBanner from './InsightsLocalBanner';
import clsx from 'clsx';
import './style.scss';
import { useQuery } from 'react-query';

import { GET_PULSE_DETAILS_DATA, GET_PULSE_RESPONSE_DATA, GET_PULSE_SUMMARY_DATA } from 'Services/apiKeys';
import { getPulseDetailsData, getPulseResponseData, getPulseSummaryData } from 'Services/apiFunctions';
import { PULSE_TYPE } from '../constants';

const Index = ({ location, match, history, enps }) => {
    const [key, setKey] = useState('Results');
    const [pulseDetails, setPulseDetails] = useState();
    const [pulseSummary, setPulseSummary] = useState();
    const [pulseResponse, setPulseResponse] = useState();
    const [instanceNumber, setInstanceNumber] = useState();
    const { SetSnackbar } = useToaster();
    const [filter, setFilter] = useState([]);
    const [pulseIssueId, setPulseIssueId] = useState(location?.state?.pulseIssueId);

    const { loading: pulseDetailsLoading, isFetching: pulseDetailsFetching } = useQuery(
        [GET_PULSE_DETAILS_DATA, match.params.id, pulseIssueId, true],
        getPulseDetailsData,
        {
            onSuccess: (data) => {
                setPulseDetails(data);
            },
            onError: (err) => {
                showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
            },
        }
    );

    const { loading: pulseSummaryLoading, isFetching: pulseSummaryFetching } = useQuery(
        [GET_PULSE_SUMMARY_DATA, match.params.id, pulseIssueId],
        getPulseSummaryData,
        {
            onSuccess: (data) => {
                setPulseSummary(data);
            },
            onError: (err) => {
                showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
            },
        }
    );

    const { loading: pulseResponseLoading, isFetching: pulseResponseFetching } = useQuery(
        [GET_PULSE_RESPONSE_DATA, match.params.id, pulseIssueId],
        getPulseResponseData,
        {
            onSuccess: (data) => {
                const dateArray =
                    data?.data[0]?.pulseIssues?.map((issue, index) => ({
                        value: issue._id,
                        label: (index < 1 ? `Latest: ` : ``) + formatDate(issue?.sentAt, issue?.endAt),
                    })) || [];
                setPulseResponse(data?.data[0] || {});
                setFilter(dateArray);
            },
            onError: (err) => {
                showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
            },
        }
    );

    function instanceNumberHandler(issueId, filter) {
        const index = filter.findIndex((item) => item.value === issueId);
        const resultIndex = index === -1 ? 0 : index;
        const instanceNumber = filter.length - resultIndex;
        let suffix = getOrdinal(instanceNumber);
        if (instanceNumber === filter.length) {
            suffix = `${suffix} (Latest instance)`;
        }
        return suffix;
    }

    useEffect(() => {
        setInstanceNumber(instanceNumberHandler(pulseIssueId || pulseSummary?.currentPulseIssue?._id, filter));
    }, [filter, pulseIssueId]);

    const loading = pulseDetailsLoading || pulseSummaryLoading || pulseResponseLoading;

    const isFetching = pulseDetailsFetching || pulseSummaryFetching || pulseResponseFetching;

    const downloadResponse = async () => {
        try {
            const pulseIssueId = pulseResponse?.issueId;
            const downloadFileData = await downLoadFile(
                `pulse/${match.params.id}/download?pulseIssueId=${pulseIssueId}`,
                'get'
            );
            download(downloadFileData?.data, `${pulseDetails?.name || 'Pulse-responses'}.xlsx`);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', 'Failed to start download');
        }
    };

    const handleFilter = (index) => {
        const issueId = filter[index].value;
        if (issueId !== pulseResponse?.issueId) {
            setPulseIssueId(issueId);
        }
    };

    const translateRating = (rating) => {
        if (rating > 8) {
            return 'Promoter';
        }
        if (rating > 6) {
            return 'Passive';
        }
        return 'Detractor';
    };

    const translateEmoji = (rating) => {
        if (rating > 8) {
            return '😁';
        }
        if (rating > 6) {
            return '😐';
        }
        return '😔';
    };

    const onBack = () => (enps ? history.push('/dashboard/eNPS') : history.push('/dashboard/pulse'));

    const filterDropDownName =
        pulseResponse?.pulseIssues?.length > 1 &&
        `${pulseResponse?.issueDate === pulseResponse?.pulseIssues[0].sentAt ? 'Latest: ' : ''}` +
            formatDate(pulseResponse?.issueDate, pulseResponse?.endAt);

    if (loading || isFetching || !pulseDetails) {
        return <CustomLoader />;
    }

    return (
        <div>
            <button className='pulse-back-button' onClick={onBack}>
                {' '}
                {'< back '}
            </button>
            <div>
                <Information
                    details={pulseDetails}
                    responseCount={pulseResponse?.participants?.length || 0}
                    enps={enps}
                    pulseResponse={pulseResponse}
                />
                <div className='d-flex pt-3 pb-3'>
                    <div className='d-flex align-items-center'>
                        {pulseResponse?.pulseIssues?.length > 1 && (
                            <>
                                <span className='filter-by-label color-normal'>Filter by</span>
                                <FilterDropDown
                                    moreFilters={filter}
                                    name={filterDropDownName}
                                    handleMoreCheck={(index) => handleFilter(index)}
                                    dropDownID='pulseResultFilterDropdown'
                                    className={'width-200 pb-2 mr-3'}
                                    singleSelect={true}
                                />
                            </>
                        )}
                    </div>
                    {pulseResponse?.participants && (
                        <button className='ew-btn lb-btn' onClick={downloadResponse}>
                            Download Report
                        </button>
                    )}
                </div>
                {(enps || pulseDetails.type === PULSE_TYPE.RECURRING) && (
                    <InsightsLocalBanner
                        details={pulseDetails}
                        responseCount={pulseResponse?.participants?.length || 0}
                        eNPS={enps}
                        responseDetails={pulseResponse}
                        instanceNumber={instanceNumber}
                        status={pulseSummary?.currentPulseIssue?.status}
                    />
                )}
                <div className={clsx({ hideTab: key !== 'Results' })}>
                    <Results
                        response={pulseResponse}
                        summary={pulseSummary}
                        setPulseSummary={setPulseSummary}
                        value={key}
                        setKey={setKey}
                        enps={enps}
                        translateRating={translateRating}
                        translateEmoji={translateEmoji}
                        loading={loading || isFetching}
                        respondentTotal={pulseDetails?.requestedUsers?.length || pulseDetails?.requestedUsersCount}
                    />
                </div>
                <div className={clsx({ hideTab: key === 'Results' })}>
                    <Responses
                        response={pulseResponse}
                        id={match.params.id}
                        setPulseResponse={setPulseResponse}
                        value={key}
                        setKey={setKey}
                        enps={enps}
                        translateRating={translateRating}
                        translateEmoji={translateEmoji}
                    />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            pulseIssueId: PropTypes.string,
        }),
    }),
    match: PropTypes.object,
    enps: PropTypes.bool,
    history: PropTypes.object,
};

export default Index;
