import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useCommonContext } from 'Context/CommonContext';
import { PLATFORM } from 'constants.js';
import { conditionalArrayElem } from 'utils/HelperFunctions';

// Eager loaded components
import Leaderboard from '../Leaderboard';
import UserRoute from '../Users';
import EnpsComponent from '../ENPS';
import Analytics from '../AnalyticsV2';
import RedeemPoints from '../RedeemPoints';
import GlobalAdminSettings from '../GlobalAdminSettings';
import RedemptionConfiguration from '../RedemptionConfigurations';
import Profile from '../Profile';
import Catalog from '../Catalog';
import PaymentsV2 from '../PaymentsV2';
import Celebration from '../Celebration';
import Feedback from '../Feedback';
import MyFeedback from '../Feedback/MyFeedback';
import SuggestionBox from '../SuggestionBox';
import Bonus from '../Bonus';
import AllSuggestion from '../SuggestionBox/AllSuggestions';
import Tasks from '../Recognitions/Tasks';
import RecognitionsConfigurations from '../Recognitions/Configuration';
import Activities from '../Activities';
import Notifications from '../Notifications';
import Home from '../Home';

// Lazy loaded components
const PulseSurvey = lazy(() => import('../PulseSurvey'));
const EmployeeAwards = lazy(() => import('../EmployeeAwards'));

const openRouteList = ({ isPulseEnabled, platform }) => {
    const isWebPlatform = platform === PLATFORM.WEB.toLowerCase();

    return [
        // Common routes for all platforms
        {
            path: '/home',
            render: (props) => <Home {...props} />,
        },
        {
            path: '/tasks',
            render: (props) => <Tasks {...props} />,
        },
        {
            path: '/activities',
            render: (props) => <Activities {...props} />,
        },
        {
            path: '/analytics/leaderboard',
            render: (props) => <Leaderboard {...props} />,
        },
        {
            path: '/redemptions/redeem',
            render: (props) => <RedeemPoints {...props} />,
        },
        {
            path: '/profile/:id',
            render: (props) => <Profile {...props} />,
        },
        {
            path: '/analytics',
            render: (props) => <Analytics {...props} />,
        },
        isPulseEnabled && {
            path: '/pulse',
            render: (props) => <PulseSurvey {...props} />,
        },
        {
            path: '/feedback/my-feedback',
            render: (props) => <MyFeedback {...props} />,
        },
        ...conditionalArrayElem(!isWebPlatform, [
            {
                path: '/suggestion-box/all-suggestions',
                render: (props) => <AllSuggestion {...props} />,
            },
        ]),
        // Web-only routes
        ...conditionalArrayElem(isWebPlatform, [
            {
                path: '/notifications',
                render: (props) => <Notifications {...props} />,
            },
        ]),
    ].filter(Boolean);
};

const adminRouteList = (platform) => {
    const isWebPlatform = platform === PLATFORM.WEB.toLowerCase();

    return [
        // Common admin routes
        {
            path: '/users',
            render: (props) => <UserRoute {...props} />,
        },
        {
            path: '/global-settings',
            render: (props) => <GlobalAdminSettings {...props} />,
        },
        {
            path: '/payments',
            render: (props) => <PaymentsV2 {...props} />,
        },
        {
            path: '/redemptions/catalog',
            render: (props) => <Catalog {...props} />,
        },
        {
            path: '/redemptions/configuration',
            render: (props) => <RedemptionConfiguration {...props} />,
        },
        {
            path: '/recognitions/configuration',
            render: (props) => <RecognitionsConfigurations {...props} />,
        },
        {
            path: '/celebration',
            render: (props) => <Celebration {...props} />,
        },
        {
            path: '/bonus',
            render: (props) => <Bonus {...props} />,
        },
        {
            path: '/eNPS',
            render: (props) => <EnpsComponent {...props} />,
        },
        {
            path: '/feedback',
            render: (props) => <Feedback {...props} />,
        },
        // Non-web platform routes
        ...conditionalArrayElem(!isWebPlatform, [
            {
                path: '/employee-awards',
                render: (props) => <EmployeeAwards {...props} />,
            },
            {
                path: '/suggestion-box',
                render: (props) => <SuggestionBox {...props} />,
            },
        ]),
    ].filter(Boolean);
};

const Index = ({ setContent }) => {
    const { isPulseEnabled = true } = useCommonContext();
    const { isAdmin, platform, config } = useSelector(mapStateToProps, shallowEqual);
    const BASE_PATH = '/dashboard';

    const mapToRoutes = ({ path, render }) => (
        <Route key={path} path={BASE_PATH + path} render={(props) => render({ ...props, setContent })} />
    );

    return (
        <div style={{ overflow: 'auto' }} className='dashboard-content-scroll'>
            <Suspense fallback={<CustomLoader />}>
                <Switch>
                    {[...openRouteList({ isPulseEnabled, platform }), ...(isAdmin ? adminRouteList(platform) : [])].map(
                        mapToRoutes
                    )}
                    {config && <Redirect to='/dashboard/redemptions/redeem' />}
                </Switch>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAdmin: state.User.isAdmin,
    platform: state.Workspace.platform?.toLowerCase(),
    config: state.Workspace.config,
});

Index.propTypes = {
    setContent: PropTypes.func,
};

export default Index;
