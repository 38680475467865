import React from 'react';
import clsx from 'clsx';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import PropTypes from 'prop-types';
import './styles.scss';

export const Index = ({ history, memberID, memberPhoto, memberName, isAdmin }) => {
    const isClickable = memberID && isAdmin;
    return (
        <button
            className={clsx('member-tag', { clickable: isClickable })}
            onClick={() =>
                isClickable
                    ? handlePageRedirectionWithinSubMenu({
                          history,
                          redirectionURL: `/dashboard/profile/${memberID}`,
                          navMenuCode: HISTORY_FROM.ACTIVITIES,
                      })()
                    : null
            }
        >
            <img src={memberPhoto || require('Assets/images/defaultUser.png')} alt='' />

            <EllipsisText text={memberName} white customClassName='mb-0 member-tag-name' />
        </button>
    );
};

Index.defaultProps = {
    isAdmin: false,
};

Index.propTypes = {
    history: PropTypes.object,
    memberID: PropTypes.string,
    memberPhoto: PropTypes.string,
    memberName: PropTypes.string,
    isAdmin: PropTypes.bool,
};

export default Index;
