import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import download from 'downloadjs';
import Table from 'components/ReusableComponents/Table';
import { CustomCheckbox } from 'components';
import { useCommonContext } from 'Context/CommonContext';
import VertMenu from 'components/ReusableComponents/VertMenu';
import { find, filter, debounce, findIndex } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import Tooltip from 'components/Styles/Tooltip';
import { downLoadFile } from 'Services/apiCall';
import DeactivateUserModal from 'components/Modals/DeactivateUser';
import ErrorActivationUserModal from 'components/Modals/ErrorActivationUserModal';
import TopUpRecognitionModal from 'components/Modals/TopUpRecognitionModal';
import { getPaginatedUsers, getManagerList, modifyUser, getAllUsers } from 'Services/apiFunctions';
import { GET_PAGINATED_USERS, GET_ALL_USERS_LIST, GET_MANAGERS_LIST } from 'Services/apiKeys';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import ChangeTeamManager from 'components/Modals/ChangeTeamManager';
import MemberAssignModal from 'components/Modals/MemberAssignModal';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { ReactComponent as EditIcon } from 'Assets/images/editIcon.svg';
import { ReactComponent as Done } from 'Assets/images/wright-tick.svg';
import { ReactComponent as Cancel } from 'Assets/images/wrong-tick.svg';
import { useModifyUser } from './ApiHooks';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ReactComponent as ExportIcon } from 'Assets/images/bx_export.svg';
import clsx from 'clsx';
import BulkUpdateConfirm from 'components/Modals/BulkUpdateConfirm';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { ORG_STATUS_FILTER_OPTION } from './constants';
import MarkAsManagerModal from 'components/Modals/MarkAsManagerModal';
import ViewTeamModal from 'components/Modals/ViewTeamModal';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import TeamCount from 'components/ReusableComponents/TeamCount';
import AssignOrChangeManagerModal from 'components/Modals/AssignOrChangeManagerModal';
import EWButton from 'components/ReusableComponents/EWButton';
import SuccessModal from 'components/Modals/SuccessModal';

const ENGAGEWITH_UNASSIGNED = 'ENGAGEWITH_UNASSIGNED';
const DEACTIVATE_ADMIN_TEXT =
    'You cannot deactivate an admin. Please remove the user as an admin before deactivating them.';
const EDIT_USER_DETAILS = 'Edit User Details';
const MANAGE_TEAM = 'Manage team';
const MARK_MANAGER = 'Mark as Manager';
const CHANGE_MANAGER = 'Change Manager';
const ASSIGN_MANAGER = 'Assign Manager';
const TOP_UP_RECOGNITION_POINTS = 'Top-up recognition points';
const EXPORT_FILE_NAME = 'UserDetails.csv';

const PointsTable = ({ history }) => {
    const { managerflow } = useCommonContext();
    const queryClient = useQueryClient();
    const { team, nameForPoints, workspaceCurrency } = useSelector(mapStateToProps, shallowEqual);
    const [statusFilter, setStatusFilter] = useState(1);
    const [managerFilter, setManagerFilter] = useState('');
    const [onlyManager, setOnlyManager] = useState(false);
    const [onlyUnassigned, setOnlyUnassigned] = useState(false);
    const [managerNameSort, setManagerNameSort] = useState(undefined);
    const [nameSort, setNameSort] = useState(true);
    const [departmentSort, setDepartmentSort] = useState(undefined);
    const [allowanceSort, setAllowanceSort] = useState(undefined);
    const [statusSort, setStatusSort] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [points, setPoints] = useState('');
    const [deactivate, setDeactivate] = useState(false);
    const [removedUserErrorModal, setRemovedUserErrorModal] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedMembersID, setSelectedMembersID] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [search, setSearch] = useState('');
    const { SetSnackbar } = useToaster();
    const [topUpModal, setTopUpModal] = useState(false);
    const [topUpSuccessModal, setTopUpSuccessModal] = useState(false);
    const [assignManagerBottomOpen, setAssignManagerBottomOpen] = useState(false);
    const [assignDepartmentBottomOpen, setAssignDepartmentBottomOpen] = useState(false);
    const [currentMember, setCurrentMember] = useState(undefined);
    const [editPoint, setEditPoint] = useState(-1);
    const [monthlyPoints, setMonthlyPoints] = useState(0);
    const [filteredMember, setFilteredMember] = useState([]);
    const [filterOption, setFilterOption] = useState({
        status: ORG_STATUS_FILTER_OPTION,
        manager: [],
    });
    const [bulkConfirm, setBulkConfirm] = useState({ open: false });
    const [showViewTeamModal, setShowViewTeamModal] = useState(false);
    const [showChangeTeamManagerModal, setShowChangeTeamManagerModal] = useState(false);
    const [showAssignOrChangeManagerModal, setShowAssignOrChangeManagerModal] = useState(false);
    const [showMarkAsManagerModal, setShowMarkAsManagerModal] = useState(false);

    const topUpModalData = useRef({
        note: 'This top up points are only for current month & will expire if not used',
        buttonText: 'Done',
    });

    const handleTopUpModalSuccess = () => {
        setTopUpModal(false);
        topUpModalData.current = {
            ...topUpModalData.current,
            content: `Recognition balance for selected users topped up by ${points} ${nameForPoints}`,
        };
        setTopUpSuccessModal(true);
    };

    const header = [
        <span key='select-all' className='checkbox-update select-all'>
            <CustomCheckbox checked={selectAll} onClick={() => handleSelectAll()} />
        </span>,
        'NAME',
        'MANAGER',
        'DEPARTMENT',
        'MONTHLY ALLOWANCE',
        'REDEEMABLE BALANCE',
        'STATUS',
        '',
    ];

    const getNoSortColumns = () => {
        return [0, 5, 6, header.length - 1];
    };

    const getColumnWidth = () => {
        return ['1%', '13%', '16%', '15%', '22%', '16%', '10%', '1%'];
    };

    const noSortColumns = getNoSortColumns();
    const columnWidth = getColumnWidth();

    const {
        data: userData,
        isLoading,
        isFetching,
    } = useQuery(
        [
            GET_PAGINATED_USERS,
            currentPage,
            statusFilter,
            managerFilter,
            onlyManager,
            onlyUnassigned,
            managerNameSort,
            nameSort,
            departmentSort,
            allowanceSort,
            statusSort,
            search,
        ],
        getPaginatedUsers,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 0,
            onSuccess: (data) => {
                handlePagination(data);
            },
        }
    );

    /**
     * Data from fetchAllUser api to be filtered based on status filter
     * @param {*} data Data from fetchAllUser api
     * @param {*} index value of the status filter ( active / inactive / all)
     */
    const updateFilteredMembers = (data, index) => {
        if (index === 1) {
            setFilteredMember(data.filter((item) => item.isActive));
        } else if (index === 2) {
            setFilteredMember(data.filter((item) => !item.isActive));
        } else {
            setFilteredMember(data);
        }
    };

    const { data: activeMembers, isFetching: fetchingAll } = useQuery([GET_ALL_USERS_LIST], getAllUsers, {
        onSuccess: (data) => updateFilteredMembers(data, statusFilter),
    });

    const handleManagers = (data) => {
        const tempList = [
            { label: 'No Manager Assigned', value: ENGAGEWITH_UNASSIGNED },
            { label: 'All', value: '' },
        ];
        data.forEach((user) => tempList.push({ ...user, label: user?.userName, value: user?.userName }));
        setFilterOption({ manager: tempList, status: filterOption.status });
    };

    useQuery([GET_MANAGERS_LIST], getManagerList, {
        onSuccess: (data) => handleManagers(data?.managers || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const onSuccess = () => showSnackBarMessage(SetSnackbar, 'success', 'Updated Successfully');

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    const { mutateAsync, isLoading: isUpdating } = useModifyUser({ onSuccess, onError });

    /**
     * Update the user data in fetch all user list api
     * The data is updated using setQueryData so that there is no need
     * to call the api again
     * @param {*} userList selected users list
     */
    const updateAllUsersListData = (userList) => {
        queryClient.setQueryData([GET_ALL_USERS_LIST], (oldData) => {
            const tempData = [...oldData];
            userList.forEach((member) => {
                const index = findIndex(oldData, (user) => user._id === (member._id || member));
                if (index >= 0) {
                    tempData[index].isActive = !oldData[index].isActive;
                }
            });
            return tempData;
        });
    };

    const handlePagination = (usersInfo) => {
        if (usersInfo.users.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    /**
     * Handle users activation/deactivation in bulk from  select all / checkbox selection
     * @param {*} activate activate/dactivate
     */
    const handleActivate = async (activate) => {
        const adminUsers = selectedMembers.filter((user) => user.isAdmin).map((user) => user.userName);
        if (adminUsers.length > 0 && !activate) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `You cannot deactivate an admin. Please remove ${adminUsers.join()} as an admin before deactivating them.`
            );
            return;
        }

        setDeactivateLoading(true);
        const apiData = {
            userIds: selectedMembers?.map((member) => member._id) || [],
            isActive: activate,
        };
        try {
            await modifyUser({ apiData });
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
            updateAllUsersListData(selectedMembers);
            setSelectedMembersID([]);
            setDeactivateLoading(false);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setDeactivateLoading(false);
        }
    };

    const handleExportUserData = async (selectedMembers, setSelectedMembersID, SetSnackbar) => {
        const sortOrder = (value) => (value ? 1 : -1);
        const sortCheck = ({ sortType, sortValue }) =>
            typeof sortValue === 'boolean' && { sortBy: sortType, sortOrder: sortOrder(sortValue) };
        const apiData = {
            ...(!selectAll && { userIds: selectedMembers?.map((member) => member._id) || [] }),
            ...(selectAll && { selectedAll: selectAll }),
            ...(statusFilter && { status: statusFilter === 1 }),
            ...(onlyUnassigned && { managerUnassigned: onlyUnassigned }),
            ...(managerFilter && { managerId: managerFilter }),
            ...(onlyManager && { manager: onlyManager }),
            ...(search && { search }),
            limit: userData?.count,
            ...sortCheck({ sortType: 'managerName', sortValue: managerNameSort }),
            ...sortCheck({ sortType: 'userName', sortValue: nameSort }),
            ...sortCheck({ sortType: 'dept', sortValue: departmentSort }),
            ...sortCheck({ sortType: 'monthlyAllowance', sortValue: allowanceSort }),
            ...sortCheck({ sortType: 'status', sortValue: statusSort }),
        };
        try {
            const downloadFileData = await downLoadFile('organization/users/export', 'post', apiData);
            download(downloadFileData?.data, EXPORT_FILE_NAME);
            setSelectedMembersID([]);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.response?.data?.message);
        }
    };

    /**
     * Handle checkbox selection
     */
    const handleSelectAll = () => {
        if (!selectAll) {
            const activeMembersID = filteredMember?.map((member) => member._id) || [];
            const tempSelected = [...selectedMembersID, ...activeMembersID];
            const allSelectedMembersID = [...new Set(tempSelected)];
            setSelectedMembersID([...allSelectedMembersID]);
        } else {
            setSelectedMembersID([]);
        }
        setSelectAll(!selectAll);
    };

    const activation = (isActive) =>
        isActive ? [{ label: 'Deactivate', value: 'changeStatus' }] : [{ label: 'Activate', value: 'changeStatus' }];

    const managerOptions = (isManager) =>
        isManager ? [{ label: MANAGE_TEAM, value: MANAGE_TEAM }] : [{ label: MARK_MANAGER, value: MARK_MANAGER }];

    const getMenuOptions = (member) => {
        if (!member?.status) {
            return [...activation(member?.status)];
        }
        return [
            ...managerOptions(member?.team?.length),
            ...[
                {
                    label: member?.manager?.userName ? CHANGE_MANAGER : ASSIGN_MANAGER,
                    value: CHANGE_MANAGER,
                },
            ],
            ...activation(member?.status),
            ...[
                {
                    label: TOP_UP_RECOGNITION_POINTS,
                    value: TOP_UP_RECOGNITION_POINTS,
                },
            ],
        ];
    };

    const handleSearchChange = debounce((value) => {
        setSearch(value.trim());
    }, 300);

    const handleChangeManager = (member) => {
        setCurrentMember(member);
        setShowAssignOrChangeManagerModal(true);
    };

    const handleViewTeam = (member) => {
        const manager = find(userData.users, (user) => user._id === member._id);
        if (manager) {
            setCurrentMember(manager);
            setShowViewTeamModal(true);
        } else {
            showSnackBarMessage(SetSnackbar, 'error', 'Unable to view team');
        }
    };

    const updateUser = async (apiData) => {
        try {
            setDeactivateLoading(true);
            await modifyUser({ apiData });
            setDeactivateLoading(false);
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
            updateAllUsersListData(apiData.userIds);
            if (deactivate) {
                setDeactivate(false);
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    /**
     * Handle user deactivation after confirmation from modal
     * @param {*} value
     */
    const handleDeactivation = (value) => {
        const apiData = {
            userIds: [currentMember._id],
            isActive: !currentMember.status,
            canRedeemRewards: value,
        };
        updateUser(apiData);
    };

    /**
     * Handle activate/deactivate user from menu options
     * @param {*} member user to be activated/deactivated
     * @returns
     */
    const handleChangeStatus = async (member) => {
        if (member?.isAdmin && member?.status) {
            showSnackBarMessage(SetSnackbar, 'error', DEACTIVATE_ADMIN_TEXT);
            return;
        }
        if (!member.status || deactivate) {
            if (member?.deleted) {
                setRemovedUserErrorModal(true);
                return;
            }
            const apiData = {
                userIds: [member._id],
                isActive: !member.status,
            };
            updateUser(apiData);
        } else {
            setCurrentMember({ ...member, Name: member?.userName });
            setDeactivate(true);
        }
    };

    const handleSort = (index) => {
        if (noSortColumns.includes(index)) {
            return;
        }
        setNameSort(index === 1 ? !nameSort : undefined);
        setManagerNameSort(index === 2 ? !managerNameSort : undefined);
        setDepartmentSort(index === 3 ? !departmentSort : undefined);
        setAllowanceSort(index === 4 ? !allowanceSort : undefined);
        setStatusSort(index === 5 ? !statusSort : undefined);
    };

    const saveEditPoints = async (user, points) => {
        try {
            const apiData = { monthlyAllowance: points, userIds: [user._id] };
            await mutateAsync({ apiData, team });
            setEditPoint(-1);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const handlePointsEdit = (index, orgMember) => {
        setEditPoint(index);
        setMonthlyPoints(orgMember?.monthlyAllowance);
    };

    const handleVertMenuOption = (option, member) => {
        switch (option.value) {
            case CHANGE_MANAGER:
                handleChangeManager(member);
                break;
            case EDIT_USER_DETAILS:
                history.push(`/dashboard/profile/${member._id}`);
                break;
            case MARK_MANAGER:
                setCurrentMember(member);
                setShowMarkAsManagerModal(true);
                break;
            case MANAGE_TEAM:
                handleViewTeam(member);
                break;
            case TOP_UP_RECOGNITION_POINTS:
                setCurrentMember(member?._id);
                setTopUpModal(true);
                break;
            case 'changeStatus':
                handleChangeStatus(member);
                break;
            default:
                return;
        }
    };

    const selectnAssign = (member) => {
        const memberIndex = selectedMembersID.findIndex((selectedMember) => selectedMember === member._id);
        if (memberIndex > -1) {
            selectedMembersID.splice(memberIndex, 1);
        } else {
            selectedMembersID.push(member._id);
        }
        setSelectedMembersID([...selectedMembersID]);
    };

    const handleDepartmentModal = () => {
        const haveDepartmentCount = selectedMembersID?.filter((selectedMember) =>
            activeMembers?.some((activeMember) => selectedMember === activeMember?._id && !!activeMember?.department)
        ).length;
        if (haveDepartmentCount > 0) {
            setBulkConfirm({ open: true, data: { count: haveDepartmentCount, type: 'department' } });
            setSelectedMembers(filteredMember?.filter((member) => selectedMembersID.includes(member?._id)) || []);
        } else {
            setAssignDepartmentBottomOpen(true);
        }
    };

    const handleManagerDropdown = (index) => {
        if (filterOption.manager[index].value === ENGAGEWITH_UNASSIGNED) {
            setOnlyUnassigned(true);
            setManagerFilter('');
        } else {
            setManagerFilter(index ? filterOption.manager[index]._id : '');
            setOnlyUnassigned(false);
        }
    };

    const activateCTA = useMemo(() => {
        //this function is used to check if the CTA action on multiple selction should be active or deactivate
        const selectedActiveCount = filter(selectedMembers, (member) => member.isActive).length;
        return selectedActiveCount <= selectedMembers.length - selectedActiveCount;
    }, [selectedMembers]);

    useEffect(() => {
        setCurrentPage(1);
    }, [
        statusFilter,
        managerFilter,
        onlyManager,
        onlyUnassigned,
        managerNameSort,
        nameSort,
        departmentSort,
        allowanceSort,
        search,
    ]);

    useEffect(() => {
        setSelectedMembersID([]);
    }, [statusFilter, managerFilter, onlyManager]);

    useEffect(() => {
        setSelectAll(selectedMembersID?.length > 0 && filteredMember?.length === selectedMembersID?.length);
        setSelectedMembers(filteredMember?.filter((member) => selectedMembersID.includes(member?._id)) || []);
    }, [userData, selectedMembersID]);

    if (!userData) {
        return <CustomLoader marginTop />;
    }

    const data = userData?.users?.map((user, index) => {
        const tempRowArray = [
            <span className='checkbox-update' key={user._id}>
                <CustomCheckbox checked={selectedMembersID.includes(user._id)} onClick={() => selectnAssign(user)} />
            </span>,
            <div
                key={user._id}
                className={clsx({
                    'org-name': editPoint !== index,
                    editpoint: editPoint === index,
                    'd-flex justify-content-start align-items-center': true,
                })}
            >
                <img
                    src={user.pictureURL || require('Assets/images/defaultUser.png')}
                    className='avatar-32 mr-2'
                    alt=''
                />
                <EWButton
                    buttonStyleClass='pointer-cursor'
                    onClick={handlePageRedirectionWithinSubMenu({
                        history,
                        redirectionURL: `/dashboard/profile/${user._id}`,
                        navMenuCode: HISTORY_FROM.ORG,
                    })}
                    height='auto'
                    primary={false}
                    plainTextButton={true}
                >
                    <EllipsisText maxWidth='130px' text={user.userName} />
                </EWButton>
                {user?.team?.length > 0 && (
                    <TeamCount
                        count={user?.team?.length}
                        handleClick={() => handleViewTeam(user)}
                        showPointerCursor={true}
                    />
                )}
                {user?.newUser && <div className='new-tag'>NEW</div>}
            </div>,
            <div key={user._id}>
                {user?.manager?.userName ? (
                    <span className={clsx({ editpoint: true, 'org-name': editPoint !== index })}>
                        <img
                            src={user.manager.pictureURL || require('Assets/images/defaultUser.png')}
                            className='avatar-16 mr-1'
                            alt=''
                        />

                        <EllipsisText maxWidth='130px' text={user?.manager?.userName} />
                    </span>
                ) : (
                    'Unassigned'
                )}
            </div>,
            <span key={user._id}>{user?.department ?? '--'}</span>,
            <div key={user._id}>
                {editPoint !== index && (
                    <span>
                        {user?.monthlyAllowance ? `${user?.monthlyAllowance} ` : '--'}
                        <EWButton
                            buttonStyleClass='ml-2 hide-action pointer-cursor'
                            onClick={() => handlePointsEdit(index, user)}
                            plainTextButton={true}
                            height='auto'
                            primary={false}
                        >
                            <EditIcon />
                        </EWButton>
                    </span>
                )}
                {editPoint === index && (
                    <span className='monthly-points-input'>
                        <input type='number' value={monthlyPoints} onChange={(e) => setMonthlyPoints(e.target.value)} />
                        <Done
                            className='pointer-cursor bg-transparent mx-4'
                            onClick={() => saveEditPoints(user, monthlyPoints)}
                        />
                        <Cancel className='pointer-cursor bg-transparent ml-2' onClick={() => setEditPoint(-1)} />
                    </span>
                )}
            </div>,
            <span key={user._id}>{`${workspaceCurrency} ${user?.redeemableBalance || 0}`}</span>,
            <span key={user._id} className={user?.status ? 'success-label' : 'warning-label'}>
                {user?.status ? 'ACTIVE' : 'INACTIVE'}
            </span>,
            <div key={user._id} className='text-left' style={{ width: '60px', height: '40px' }}>
                <span className='hide-action'>
                    <VertMenu
                        placement={'bottom-end'}
                        disabled={!!selectedMembers.length}
                        optionMenu={getMenuOptions(user)}
                        getSelected={(optionIndex) => handleVertMenuOption(getMenuOptions(user)[optionIndex], user)}
                    >
                        <MoreHorizIcon />
                    </VertMenu>
                </span>
            </div>,
        ];
        return { id: user._id, row: tempRowArray };
    });

    const paginationProps = {
        currentPage,
        totalCount: userData?.count,
        setCurrentPage,
        onChange: (page) => setCurrentPage(page),
        pageItem: 'users',
    };

    const getManagerDropDownName = () => {
        if (!managerFilter) {
            return onlyUnassigned ? 'No Manager Assigned' : 'All';
        }
        return `${find(filterOption.manager, (manager) => manager._id === managerFilter)?.userName}'s team`;
    };

    const handleBackFromChangeTeamManagerModal = () => {
        setShowChangeTeamManagerModal(false);
        setShowViewTeamModal(true);
    };

    const getDisplayText = () => {
        if (selectedMembersID.length === userData?.totalUsers) {
            return `All ( ${selectedMembersID.length} )`;
        }
        if (selectAll) {
            return `${userData?.count}`;
        }
        return `${selectedMembersID.length}`;
    };

    const handleConfirm = () => {
        handleDeactivation(false);
    };

    return (
        <>
            <div className='user-wrapper'>
                <div className='mt-2 overall-user-container'>
                    <div
                        className={clsx({
                            'd-flex justify-content-between align-items-center mb-2': true,
                            'disable-view': isLoading || isFetching || fetchingAll,
                        })}
                    >
                        <div className='d-flex justify-content-start align-items-center'>
                            <div className='d-flex flex-column ml-3'>
                                <CustomFilterDropdown
                                    title='STATUS'
                                    filterOptions={filterOption.status}
                                    optionsSelected
                                    selectedName={filterOption.status[statusFilter].label}
                                    handleSelection={(index) => {
                                        setStatusFilter(index);
                                        updateFilteredMembers(activeMembers, index);
                                    }}
                                    dropDownID='usersFilterStatus'
                                    singleSelect
                                    buttonStyleClass='width-100px'
                                />
                            </div>

                            {managerflow && (
                                <>
                                    <div className='d-flex flex-column ml-3'>
                                        <CustomFilterDropdown
                                            title='MANAGER'
                                            filterOptions={filterOption.manager}
                                            selectedName={getManagerDropDownName()}
                                            optionsSelected
                                            handleSelection={handleManagerDropdown}
                                            dropDownID='usersFilterManager'
                                            singleSelect
                                            buttonStyleClass='width-203'
                                            search
                                        />
                                    </div>

                                    <div className='d-flex justify-content-start ml-3 mt-3' style={{ height: '40px' }}>
                                        <button
                                            className={`chip${onlyManager ? '-selected' : ''} chip-button`}
                                            onClick={() => setOnlyManager(!onlyManager)}
                                        >
                                            Only Managers
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <CustomSearchBox
                            width='300px'
                            handleSearch={handleSearchChange}
                            placeholder='Search'
                            value={search}
                        />
                    </div>

                    {data?.length < 1 && isLoading ? (
                        <CustomLoader />
                    ) : (
                        <div className='user-table'>
                            <Table
                                header={header}
                                data={data}
                                noSortColumns={noSortColumns}
                                handleSort={handleSort}
                                paginationProps={paginationProps}
                                loading={isFetching || isLoading || deactivateLoading || isUpdating}
                                columnWidth={columnWidth}
                            />
                        </div>
                    )}
                </div>

                {selectedMembersID.length > 0 && (
                    <div className='assign-button-container'>
                        <p className='selection-info'>{getDisplayText()} users selected</p>
                        <div className='d-inline-block  '>
                            <Tooltip title='Export the selected entries in CSV Format'>
                                <EWButton
                                    buttonStyleClass='mr-2 pointer-cursor'
                                    onClick={() =>
                                        handleExportUserData(selectedMembers, setSelectedMembersID, SetSnackbar)
                                    }
                                    primary={false}
                                    plainTextButton={true}
                                >
                                    <ExportIcon />
                                </EWButton>
                            </Tooltip>
                            {statusFilter < 2 && (
                                <button className='ew-btn ob-btn mr-3' onClick={handleDepartmentModal}>
                                    Add to department
                                </button>
                            )}
                            <button
                                className='ew-btn ob-btn mr-3'
                                onClick={() => {
                                    setTopUpModal(true);
                                }}
                            >
                                Top-up recognition points
                            </button>

                            <button
                                disabled={deactivateLoading}
                                className='ew-btn or-btn'
                                onClick={() => handleActivate(activateCTA)}
                            >
                                {activateCTA ? 'Activate' : 'Deactivate'}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {showChangeTeamManagerModal && (
                <ChangeTeamManager
                    open={showChangeTeamManagerModal}
                    setOpen={setShowChangeTeamManagerModal}
                    currentManager={currentMember}
                    handleBack={handleBackFromChangeTeamManagerModal}
                />
            )}
            <TopUpRecognitionModal
                open={topUpModal}
                setOpen={setTopUpModal}
                selectedUserIds={selectedMembersID}
                currentId={currentMember}
                onSuccess={handleTopUpModalSuccess}
                points={points}
                setPoints={setPoints}
            />

            {deactivate && (
                <DeactivateUserModal
                    profile={currentMember}
                    setDeactivateUser={setDeactivate}
                    isDeactivating={deactivateLoading}
                    handleConfirm={handleConfirm}
                />
            )}
            {removedUserErrorModal && <ErrorActivationUserModal setRemovedUserErrorModal={setRemovedUserErrorModal} />}

            {showAssignOrChangeManagerModal && (
                <AssignOrChangeManagerModal
                    open={showAssignOrChangeManagerModal}
                    onClose={() => {
                        setShowAssignOrChangeManagerModal(false);
                        setCurrentMember();
                    }}
                    userData={currentMember}
                />
            )}

            {assignManagerBottomOpen && (
                <MemberAssignModal
                    assign={true}
                    assignType={'Manager'}
                    selectedMember={selectedMembers}
                    open={assignManagerBottomOpen}
                    setOpen={setAssignManagerBottomOpen}
                    setSelectedMembers={setSelectedMembers}
                />
            )}

            {assignDepartmentBottomOpen && (
                <MemberAssignModal
                    assign={false}
                    assignType={'Department'}
                    selectedMember={selectedMembers}
                    open={assignDepartmentBottomOpen}
                    setOpen={setAssignDepartmentBottomOpen}
                    setSelectedMembers={setSelectedMembers}
                />
            )}

            {bulkConfirm.open && (
                <BulkUpdateConfirm
                    open
                    onClose={() => setBulkConfirm({ open: false })}
                    data={bulkConfirm.data}
                    setManager={setAssignManagerBottomOpen}
                    setDepartment={setAssignDepartmentBottomOpen}
                />
            )}

            {showMarkAsManagerModal && (
                <MarkAsManagerModal
                    open={showMarkAsManagerModal}
                    onClose={() => {
                        setShowMarkAsManagerModal(false);
                        setCurrentMember();
                    }}
                    selectedManagerData={currentMember}
                />
            )}

            {showViewTeamModal && (
                <ViewTeamModal
                    open={showViewTeamModal}
                    onClose={() => {
                        setShowViewTeamModal(false);
                        setCurrentMember();
                    }}
                    managerData={currentMember}
                    handleChangeTeamManager={() => {
                        setShowChangeTeamManagerModal(true);
                        setShowViewTeamModal(false);
                    }}
                    userDetails={activeMembers}
                />
            )}
            <SuccessModal
                open={topUpSuccessModal}
                setOpen={() => {
                    setTopUpSuccessModal(false);
                }}
                data={topUpModalData.current}
                onConfirm={() => {
                    setTopUpSuccessModal(false);
                    setSelectedMembersID([]);
                    setPoints('');
                }}
            />
        </>
    );
};
PointsTable.propTypes = {
    history: PropTypes.object,
};
const mapStateToProps = ({ Workspace }) => ({
    team: Workspace.team,
    nameForPoints: Workspace.nameForPoints,
    workspaceCurrency: Workspace.workspaceCurrency,
});

export default PointsTable;
