import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components';
import UserButton from 'components/ReusableComponents/UserButton';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import './style.scss';
import { HISTORY_FROM } from 'constants.js';

const LogItem = ({ history, date, userDetails, text, newValue, settingName }) => {
    const { _id, userName, pictureURL } = userDetails;
    const updatedCountry = settingName === 'country_user_update';

    const openUserProfile = () => {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `/dashboard/profile/${_id}`,
            navMenuCode: HISTORY_FROM.AUDIT_LOGS,
        })();
    };

    const generateCountryUpdateText = () => {
        const names = newValue.split(',').map((name) => name.trim());
        const primaryName = names[0];
        // Extract additional names for tooltip
        const additionalNames = names.length > 3 ? names.slice(2).join(', ') : null;
        return (
            <span>
                updated <b>{primaryName}'s</b>
                {names.length === 2 && <b>{` and ${names[1]}'s`}</b>}
                {names.length === 3 && <b>{`, ${names[1]}'s and ${names[2]}'s`}</b>}
                {names.length > 3 && (
                    <b>
                        {`, ${names[1]}'s and `}
                        <Tooltip arrow title={additionalNames}>
                            <span style={{ textDecoration: 'underline' }}>{names.length - 2} others'</span>
                        </Tooltip>
                    </b>
                )}{' '}
                country to <b>{text}</b>
            </span>
        );
    };

    const displayText = updatedCountry ? generateCountryUpdateText() : text.replace(userName, '').trim();

    return (
        <div className='log-item'>
            <span className='log-date'>{date}</span>
            <div className='log-content'>
                <UserButton
                    senderName={userName}
                    onClick={openUserProfile}
                    imageURL={pictureURL}
                    clickableUser={true}
                />
                &nbsp;
                {updatedCountry ? (
                    <span className='log-text'>{displayText}</span>
                ) : (
                    <span className='log-text' dangerouslySetInnerHTML={{ __html: displayText }} />
                )}
            </div>
        </div>
    );
};

LogItem.propTypes = {
    date: PropTypes.string,
    userDetails: PropTypes.object,
    text: PropTypes.string,
    history: PropTypes.object,
    newValue: PropTypes.string,
    settingName: PropTypes.string,
};

export default LogItem;
