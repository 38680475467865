import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { useToaster } from 'Context/SnackbarContext';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { ReactComponent as EmptyUsers } from 'Assets/images/empty-user-analytics-table.svg';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import Tooltip from 'components/Styles/Tooltip';
import { HISTORY_FROM, COHORT_TYPE } from 'constants.js';
import { ANALYTICS } from '../../constants';
import Table from 'components/ReusableComponents/Table';
import { getTableHeadingTitle, showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_USER_ANALYTICS_TABLE_DATA } from 'Services/apiKeys';
import { getAnalyticsPageTableData } from 'Services/apiFunctions';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ToolTipInfo from '../../ToolTipInfo';
import PropTypes from 'prop-types';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import EWButton from 'components/ReusableComponents/EWButton';
import clsx from 'clsx';

const userTableHeader = ['NAME', 'TOTAL RECOGNITIONS', 'REDEMPTIONS', 'YET TO BE REDEEMED', 'MOST RECOGNIZED FOR'];
const userNoSortColumns = [0, 4];
const userColumnWidth = ['28%', '19%', '14%', '19%', '20%'];
const sortKeys = ['name', 'totalRecognitions', 'redeemed', 'redeemBalance', 'mostRecognizedFor'];

const Index = ({ history, selectedDateRange, selectedCohort, showManagerAnalytics, isFullView = false }) => {
    const { SetSnackbar } = useToaster();
    const [page, setPage] = useState(1);
    const [userTableData, setUserTableData] = useState({});
    const [userFilter, setUserFilter] = useState({
        page,
        cohortId: selectedCohort?._id,
        cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        limit: isFullView ? 10 : 5,
        view: isFullView ? 'full' : 'card',
    });

    useEffect(() => {
        setUserFilter({
            ...userFilter,
            cohortId: selectedCohort?._id,
            cohortType: COHORT_TYPE[selectedCohort?.type?.toUpperCase()],
            startDate: selectedDateRange?.startDate,
            endDate: selectedDateRange?.endDate,
        });
    }, [selectedCohort, selectedDateRange]);

    const formatUserTableData = (apiData = []) => {
        return apiData.map((user) => ({
            id: user?._id,
            row: [
                <>
                    <img
                        src={user?.imageURL || require('Assets/images/defaultUser.png')}
                        className='avatar-32 mr-3'
                        alt=''
                    />
                    <EWButton
                        buttonStyleClass={clsx('ew-btn tb-btn', { 'pointer-cursor': !showManagerAnalytics })}
                        primary={false}
                        plainTextButton={true}
                        {...(!showManagerAnalytics && {
                            onClick: handlePageRedirectionWithinSubMenu({
                                history,
                                redirectionURL: `/dashboard/profile/${user?._id}`,
                                navMenuCode: HISTORY_FROM.ANALYTICS,
                            }),
                        })}
                    >
                        <EllipsisText text={user?.userName} maxWidth='115px' />
                    </EWButton>
                    {!user?.isActive && <span className='status-tag'>INACTIVE</span>}
                </>,
                <div key={'userTotalRecog' + user?._id}>
                    <span className={`${isFullView ? 'ac-medium-font' : 'ac-dark-font'} pointer-cursor`}>
                        {user?.totalRecognitions}
                        <Tooltip
                            white
                            arrow
                            title={
                                <ToolTipInfo
                                    givenRecognitions={user?.givenRecognitions}
                                    receivedRecognitions={user?.receivedRecognitions}
                                />
                            }
                        >
                            <InfoIcon className='ac-info-icon' />
                        </Tooltip>
                    </span>
                </div>,
                <span key={user._id} className={isFullView ? 'ac-medium-font' : 'ac-dark-font'}>
                    {user?.walletCurrency ? `${user.walletCurrency} ${user?.redeemed?.toFixed(2)}` : '-'}
                </span>,
                <span key={user._id} className={isFullView ? 'ac-medium-font' : 'ac-dark-font'}>
                    {user?.walletCurrency ? `${user.walletCurrency} ${user?.redeemBalance?.toFixed(2)}` : '-'}
                </span>,
                <span className={isFullView ? 'ac-medium-font' : 'ac-light-font'} key={'userRecog' + user?._id}>
                    <EllipsisText white maxWidth='180px'>
                        {user?.mostRecognizedFor || '-'}
                    </EllipsisText>
                </span>,
            ],
        }));
    };

    const { isLoading, isFetching, isFetched, isRefetching } = useQuery(
        [GET_USER_ANALYTICS_TABLE_DATA, userFilter],
        () => getAnalyticsPageTableData(userFilter),
        {
            keepPreviousData: true,
            onSuccess: (data) => setUserTableData(data),
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    if (isLoading) {
        return (
            <div className='ac-table-container'>
                <CustomLoader />
            </div>
        );
    }

    let userAnalyticsData = {
        data: formatUserTableData(userTableData?.data),
        total: userTableData?.total,
        currency: userTableData?.currency,
    };

    const showEmptyState = userAnalyticsData?.data?.length === 0;

    const handleUserTableSort = (index) => {
        const sortValue = -(userFilter.sortValue || -1);
        setUserFilter({ ...userFilter, page, sortKey: sortKeys[index], sortValue });
    };

    const handleUserTableSearch = debounce((value) => {
        setPage(1);
        setUserFilter({ ...userFilter, searchText: value, page: 1 });
    }, 300);

    let paginationProps = null;

    if (isFullView) {
        paginationProps = {
            currentPage: page,
            totalCount: userAnalyticsData?.total,
            setCurrentPage: setPage,
            onChange: (page) => setUserFilter({ ...userFilter, page }),
        };
    }

    const getLoadingOrEmptyStateUserComponent = (isFetched, isRefetching) => {
        return isFetched && !isRefetching ? (
            <EmptyScreen image={<EmptyUsers />} headingText={'No data available yet'} />
        ) : (
            <CustomLoader />
        );
    };

    return (
        <div className='ac-table-container'>
            <div className='ac-total-count'>
                <p>{getTableHeadingTitle(userAnalyticsData?.total)}</p>
                {isFullView && (
                    <CustomSearchBox width='35%' handleSearch={handleUserTableSearch} placeholder='Search by name' />
                )}
            </div>
            {!showEmptyState ? (
                <>
                    <Table
                        header={userTableHeader}
                        data={userAnalyticsData.data}
                        noShadow={true}
                        loading={isFetching}
                        noSortColumns={userNoSortColumns}
                        handleSort={handleUserTableSort}
                        className='margin-top-8'
                        columnWidth={userColumnWidth}
                        {...(isFullView && { paginationProps })}
                    />
                    {userAnalyticsData?.total > 5 && !isFullView && (
                        <EWButton
                            buttonStyleClass='ac-view-all'
                            primary={false}
                            plainTextButton={true}
                            onClick={() => {
                                history.push(ANALYTICS.ANALYTICS_TEAM_INSIGHTS_USERS, {
                                    selectedCohort: showManagerAnalytics
                                        ? { ...selectedCohort, showManagerAnalytics }
                                        : {},
                                });
                            }}
                        >
                            {`View all ${userAnalyticsData?.total} Users`} <ArrowForwardIcon />
                        </EWButton>
                    )}
                </>
            ) : (
                getLoadingOrEmptyStateUserComponent(isFetched, isRefetching)
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    selectedDateRange: PropTypes.object,
    selectedCohort: PropTypes.object,
    showManagerAnalytics: PropTypes.bool,
    isFullView: PropTypes.bool,
};
export default Index;
