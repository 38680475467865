import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ open, onClose, width = '460px', height = 'auto', heading, children, hideCloseButton }) => {
    if (!open) {
        return null;
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className='container-side-overlay' style={{ width, height }} data-testid='container-side-overlay'>
                <div className='side-overlay-heading'>
                    {heading}
                    {!hideCloseButton && (
                        <CloseIcon data-testid='side-overlay-btn-close' className='pointer-cursor' onClick={onClose} />
                    )}
                </div>
                {children}
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heading: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    children: PropTypes.node,
};

export default Index;
