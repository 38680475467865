import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import EllipsisText from 'components/ReusableComponents/EllipsisText';

export const MemberTag = ({ history, memberID, memberPhoto, memberName, isAdmin }) => {
    return (
        <button
            className='member task-user-button'
            onClick={() =>
                memberID && isAdmin
                    ? handlePageRedirectionWithinSubMenu({
                          history,
                          redirectionURL: `/dashboard/profile/${memberID}`,
                          navMenuCode: HISTORY_FROM.TASKS,
                      })()
                    : null
            }
        >
            <img src={memberPhoto || require('Assets/images/defaultUser.png')} alt='' />

            <EllipsisText
                text={memberName}
                white
                customClassName={isAdmin ? 'mb-0 task-user-name' : 'mb-0 task-user-name-admin'}
                maxWidth='200px'
            />
        </button>
    );
};

MemberTag.propTypes = {
    history: PropTypes.object,
    memberID: PropTypes.string,
    memberPhoto: PropTypes.string,
    memberName: PropTypes.string,
    isAdmin: PropTypes.bool,
};

export default MemberTag;
