import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useToaster } from 'Context/SnackbarContext';
import { useQuery } from 'react-query';
import { getIntegrationList } from '../../ApiHooks';
import EWModal from 'components/ReusableComponents/EWModal';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { DATA_FETCHED } from './integrationsData';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as ImportantNoteIcon } from 'Assets/images/imp_note.svg';
import { ReactComponent as ConnectionIcon } from 'Assets/images/connection-green-icon.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { GET_INTEGRATION_LIST } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ open, onClose, handleConnect, isLoading, selectedProvider }) => {
    const { SetSnackbar } = useToaster();
    const [searchTerm, setSearchTerm] = useState('');
    const [integrationList, setIntegrationList] = useState([]);

    useQuery([GET_INTEGRATION_LIST], getIntegrationList, {
        onSuccess: (data) => {
            if (data?.ok) {
                setIntegrationList(data?.data);
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const buttonText = () => (
        <div className='btn-text'>
            <ConnectionIcon />
            Connect
        </div>
    );

    const filteredIntegrationsList = integrationList.filter((integration) =>
        integration.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const modalData = {
        heading: `${filteredIntegrationsList.length} HRMS platform`,
    };

    return (
        <EWModal open={open} onClose={onClose} width='764px' height='541px' modalData={modalData}>
            <CustomSearchBox handleSearch={setSearchTerm} placeholder='Search name' />
            <div className='data-fetched-container'>
                <p className='note'>
                    <span>Important Note:</span> We will only fetch the data given below
                </p>
                <ImportantNoteIcon className='imp-note-icon' />
                <div className='data-points-container'>
                    {DATA_FETCHED.map((point, idx) => (
                        <span key={point} className='point'>
                            {point}{' '}
                            {idx < DATA_FETCHED.length - 1 && <FiberManualRecordIcon className='separator-icon' />}
                        </span>
                    ))}
                </div>
            </div>
            {filteredIntegrationsList.length > 0 ? (
                <div className='integrations-list'>
                    {filteredIntegrationsList.map((integration) => (
                        <div key={integration.name} className='integration-container'>
                            <div className='integration-info-container'>
                                <div className='integration-logo-container'>
                                    <img
                                        src={integration.logo}
                                        alt={`${integration.name}-logo`}
                                        className='integration-logo'
                                    />
                                </div>
                                <div className='integration-details'>
                                    <p className='integration-name'>{integration.title}</p>
                                    <p className='integration-platform'>HRMS platform</p>
                                </div>
                            </div>
                            <EWButton
                                onClick={() => handleConnect(integration.name)}
                                plainTextButton
                                buttonText={buttonText()}
                                buttonStyleClass={clsx('connect-btn', {
                                    'btn-loading': selectedProvider === integration.name && isLoading,
                                })}
                                loading={selectedProvider === integration.name && isLoading}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p>No integrations found</p>
            )}
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleConnect: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    selectedProvider: PropTypes.string.isRequired,
};

Index.defaultProps = {
    isLoading: false,
};

export default Index;
