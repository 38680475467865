import { format } from 'date-fns';
import React, { useCallback } from 'react';
import VertMenu from 'components/ReusableComponents/VertMenu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ImageStack from 'components/ReusableComponents/ImageStack';
import AwardProgressBar from './Components/AwardProgressBar';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { dateRanges, HISTORY_FROM } from 'constants.js';
import { getLastNDays, isSubscriptionCancelled } from 'utils/HelperFunctions';
import { ReactComponent as SendVotingReminderIcon } from 'Assets/images/send_voting_reminder.svg';
import { ReactComponent as AnnounceAwardIcon } from 'Assets/images/announce_award.svg';
import { ReactComponent as ExtendVotingDeadlineIcon } from 'Assets/images/extend_voting_deadline.svg';
import { ReactComponent as PickAwardeeIcon } from 'Assets/images/pick_awardee.svg';
import { ReactComponent as EditAwardIcon } from 'Assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/deleteIcon.svg';
import { AWARD_STATUS, EMPLOYEE_AWARDS, AWARD_TYPE, AWARD_STAGES } from './constants';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import TieTooltip from './Components/TieTooltip';

const goToProfile = (userId, history) => () => {
    if (userId) {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `${EMPLOYEE_AWARDS.PROFILE}${userId}`,
            navMenuCode: HISTORY_FROM.EMPLOYEE_AWARDS,
        })();
    }
};

export const rowFormatterDirectCompleted = (history) => (award) => ({
    id: award._id,
    row: [
        <div key={`${award._id}-name`} className='award-name-cell'>
            <EllipsisText text={award.name} maxWidth='180px' />
        </div>,
        <button
            className='link-btn'
            onClick={goToProfile(award.awardee?._id, history)}
            key={`${award.awardee?._id}-awardee`}
        >
            {award.awardee?.userName}
        </button>,
        award.awardDate ? format(new Date(award.awardDate), 'MMM dd, yyyy') : '-',
        <button
            className='link-btn'
            onClick={goToProfile(award.awardedBy?._id, history)}
            key={`${award.awardedBy?._id}-awardedBy`}
        >
            {award.awardedBy?.userName}
        </button>,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
    ],
});

export const rowFormatterDirectScheduled =
    (optionMenu, selectedAward, history, subscription, setSubscriptionCancelled) => (award) => ({
        id: award._id,
        row: [
            <div key={`${award._id}-name`} className='award-name-cell'>
                <EllipsisText text={award.name} maxWidth='180px' />
            </div>,
            <button className='link-btn' onClick={goToProfile(award.awardee?._id, history)} key={award._id}>
                {award.awardee?.userName}
            </button>,
            format(new Date(award.launchDate), 'MMM dd, yyyy'),
            <button className='link-btn' onClick={goToProfile(award.awardedBy?._id, history)} key={award._id}>
                {award.awardedBy?.userName}
            </button>,
            award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
            getAnnouncementChannelString(award.announcementChannel, 2),
            <VertMenu
                key={award._id}
                placement={'bottom-end'}
                disabled={false}
                getSelected={(index) => {
                    if (optionMenu[index].value === 'editAward') {
                        if (isSubscriptionCancelled(subscription)) {
                            setSubscriptionCancelled(true);
                            return;
                        }
                        history.push(EMPLOYEE_AWARDS.LAUNCH, { award, edit: true });
                    } else {
                        selectedAward.current = award;
                        if (optionMenu[index].value === 'deleteAward') {
                            optionMenu[index].onClickHandler();
                        }
                    }
                }}
                optionMenu={optionMenu}
            >
                <MoreHorizIcon />
            </VertMenu>,
        ],
    });

export const rowFormatterPollScheduled =
    (optionMenu, selectedAward, history, subscription, setSubscriptionCancelled, awardType) => (award) => ({
        id: award._id,
        row: [
            <div key={`${award._id}-name`} className='award-name-cell'>
                <EllipsisText text={award.name} maxWidth='180px' />
            </div>,
            format(new Date(award.launchDate), 'MMM dd, yyyy'),
            ...(awardType !== AWARD_TYPE.POLL_NOMINATIONS
                ? [
                      <div className='total-audience' key={`${award._id}-nomineeSample`}>
                          <ImageStack members={award.nominee} showMore={award.nomineeCount > 4} showToolTip={true} />
                          {award.nomineeCount <= 4 && <p className='bold'>{award.nomineeCount || 0}</p>}
                      </div>,
                  ]
                : []),
            <span key={award._id} className='truncate-text'>
                {getAnnouncementChannelString(award.announcementChannel, 3)}
            </span>,
            <VertMenu
                key={award._id}
                placement={'bottom-end'}
                disabled={false}
                getSelected={(index) => {
                    if (optionMenu[index].value === 'editAward') {
                        if (isSubscriptionCancelled(subscription)) {
                            setSubscriptionCancelled(true);
                            return;
                        }
                        history.push(EMPLOYEE_AWARDS.LAUNCH, { award, edit: true });
                    } else {
                        selectedAward.current = award;
                        if (optionMenu[index].value === 'deleteAward') {
                            optionMenu[index].onClickHandler();
                        }
                    }
                }}
                optionMenu={optionMenu}
            >
                <MoreHorizIcon />
            </VertMenu>,
        ],
    });

const getAwardProgressLabel = (awardType, stage) => {
    if (awardType === AWARD_TYPE.POLL_NOMINATIONS) {
        return `(${stage === AWARD_STAGES.NOMINATION ? 'Nominee' : 'Award'} Poll)`;
    }
    return '';
};

export const rowFormatterPollOngoing = (optionMenu, selectedAward, awardType) => (award) => {
    let options;

    // this condition based on the current stage renders the vertical menu differently

    if (award?.stage === AWARD_STAGES.NOMINATION || award?.stage === AWARD_STAGES.READY_TO_LAUNCH) {
        // when the award is in nomination or ready to launch stage we dont want to show announce award and pick awardee as both of them are only available for the poll stage awards
        options = optionMenu.slice(1).filter((option) => option.value !== 'pickAwardee');
    } else {
        // to simplify the conditions for the vertical menu when the award is on poll stage and has multiple winners, single winner and all responses for that poll
        const hasMultipleWinners = award?.winner?.length > 1;
        const hasSingleWinner = award?.winner?.length === 1;
        const hasAllResponses = award.responseCount === award.requestedCount;

        options = optionMenu;

        if (hasMultipleWinners) {
            // when there is a multiple winners we need to and all people voted then we only need to show the 2 options Announce award and pick awardee
            options = hasAllResponses
                ? optionMenu
                      .slice(1)
                      .filter((option) => option.value === 'pickAwardee' || option.value === 'viewParticipants')
                : optionMenu.slice(1);
            // in the other case when we dont have all the responses we need to show all the options except for the announce award as the poll is still not ended or not all the people responded
        } else if (hasSingleWinner && hasAllResponses) {
            // when there is a single winner and all people responded then we need to show the option Announce award and view participants
            options = optionMenu.filter(
                (option) => option.value === 'announceAward' || option.value === 'viewParticipants'
            );
        }
        // if none of the above conditions are met then we need to show all the options
    }
    return {
        id: award._id,
        row: [
            <div key={`${award._id}-name`} className='award-name-cell'>
                <EllipsisText text={award.name} maxWidth='180px' />
            </div>,
            format(new Date(award.launchDate), 'MMM dd, yyyy'),
            <div key={`${award._id}-winner`}>
                <TieTooltip award={award} status={AWARD_STATUS.ONGOING} />
                <span>{format(new Date(award.endDate), 'HH:mm a | MMM dd, yyyy')}</span>
            </div>,
            <div className='total-audience' key={`award._id-nomineeSample`}>
                {awardType === AWARD_TYPE.POLL_NOMINATIONS && award?.stage === AWARD_STAGES.NOMINATION ? (
                    <span className='ca-nominations-progress'>Nominations in progress</span>
                ) : (
                    <>
                        <ImageStack members={award.nominee} showMore={award.nomineeCount > 4} showToolTip={true} />
                        {award.nomineeCount <= 4 && <p className='bold'>{award.nomineeCount || 0}</p>}
                    </>
                )}
            </div>,
            <AwardProgressBar
                key={award._id}
                responseCount={
                    awardType === AWARD_TYPE.POLL_NOMINATIONS && award?.stage === AWARD_STAGES.NOMINATION
                        ? award.responsesCountForNomination
                        : award.responseCount
                }
                requestedCount={
                    awardType === AWARD_TYPE.POLL_NOMINATIONS && award?.stage === AWARD_STAGES.NOMINATION
                        ? award.requestedCountForNomination
                        : award.requestedCount
                }
                label={getAwardProgressLabel(awardType, award?.stage)}
            />,
            <VertMenu
                key={award._id}
                placement={'bottom-end'}
                disabled={false}
                getSelected={() => (selectedAward.current = award)}
                optionMenu={options}
            >
                <MoreHorizIcon />
            </VertMenu>,
        ],
    };
};

export const rowFormatterPollCompleted = (history, optionMenu, selectedAward) => (award) => ({
    id: award._id,
    row: [
        <div key={`${award._id}-name`} className='award-name-cell'>
            <EllipsisText text={award.name} maxWidth='180px' />
        </div>,
        <div className='d-flex' key={`${award._id}-awardee`}>
            <button
                className='link-btn error-tooltip-span'
                onClick={goToProfile(award.awardee?._id, history)}
                key={`${award.awardee?._id}-awardee`}
            >
                {award.awardee?.userName}
            </button>
            <TieTooltip award={award} status={AWARD_STATUS.COMPLETED} />
        </div>,
        format(new Date(award.awardDate), 'MMM dd, yyyy'),
        <div className='total-audience' key={`award._id-nomineeSample`}>
            <ImageStack members={award.nominee} showMore={award.nomineeCount > 4} showToolTip={true} />
            {award.nomineeCount <= 4 && <p className='bold'>{award.nomineeCount || 0}</p>}
        </div>,
        <span key={award._id}>
            <strong>{award.responseCount}</strong>/{award.requestedCount}
        </span>,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
        <VertMenu
            key={award._id}
            placement={'bottom-end'}
            disabled={false}
            getSelected={() => (selectedAward.current = award)}
            optionMenu={optionMenu}
        >
            <MoreHorizIcon />
        </VertMenu>,
    ],
});

export const dateRangeHandler = ({
    index,
    setSelectedDateRangeDisplayLabel,
    setShowDatePicker,
    setSelectedDateRange,
    dateRangeObject = false,
}) => {
    setSelectedDateRangeDisplayLabel(dateRanges[index].label);
    let range = dateRanges[index].value;
    let startDate;
    switch (range) {
        case 'today':
            startDate = getLastNDays(0).startDate;
            break;
        case 'last7':
            startDate = getLastNDays(7).startDate;
            break;
        case 'last30':
            startDate = getLastNDays(30).startDate;
            break;
        case 'last60':
            startDate = getLastNDays(60).startDate;
            break;
        case 'last90':
            startDate = getLastNDays(90).startDate;
            break;
        case 'Custom Date':
            setShowDatePicker(true);
            return;
    }
    if (dateRangeObject) {
        range = startDate ? { startDate: format(startDate, 'dd-MM-yyyy') } : {};
    }
    setSelectedDateRange(range);
};

export const threeDotMenuOptions = {
    announceAward: (setOpenAnnounceAwardModal) => ({
        label: (
            <button className='vert-menu-option-button' onClick={() => setOpenAnnounceAwardModal(true)}>
                <AnnounceAwardIcon className='mr-2 pointer-cursor' /> Announce Award
            </button>
        ),
        value: 'announceAward',
    }),
    extendDeadline: (setOpenExtendDeadlineModal, subscription, setSubscriptionCancelled) => ({
        label: (
            <button
                className='vert-menu-option-button'
                onClick={() => {
                    if (isSubscriptionCancelled(subscription)) {
                        setSubscriptionCancelled(true);
                        return;
                    }
                    setOpenExtendDeadlineModal(true);
                }}
            >
                <ExtendVotingDeadlineIcon className='mr-2 pointer-cursor' /> Extend voting deadline
            </button>
        ),
        value: 'extendVotingDeadline',
    }),
    pickAwardee: (setOpenPickAwardeeModal, subscription, setSubscriptionCancelled) => ({
        label: (
            <button
                className='vert-menu-option-button'
                onClick={() => {
                    if (isSubscriptionCancelled(subscription)) {
                        setSubscriptionCancelled(true);
                        return;
                    }
                    setOpenPickAwardeeModal(true);
                }}
            >
                <PickAwardeeIcon className='mr-2 pointer-cursor' /> Pick winner
            </button>
        ),
        value: 'pickAwardee',
    }),
    sendReminder: (setOpenSendReminderModal, subscription, setSubscriptionCancelled) => ({
        label: (
            <button
                className='vert-menu-option-button'
                onClick={() => {
                    if (isSubscriptionCancelled(subscription)) {
                        setSubscriptionCancelled(true);
                        return;
                    }
                    setOpenSendReminderModal(true);
                }}
            >
                <SendVotingReminderIcon className='mr-2 pointer-cursor' /> Send voting reminder
            </button>
        ),
        value: 'sendVotingReminder',
    }),
    edit: () => ({
        label: (
            <span>
                <EditAwardIcon className='mr-2 pointer-cursor' /> Edit Award details
            </span>
        ),
        value: 'editAward',
    }),
    delete: (setOpenDeleteAwardModal) => ({
        label: (
            <span>
                <DeleteIcon className='mr-2 pointer-cursor' /> Delete
            </span>
        ),
        value: 'deleteAward',
        onClickHandler: () => setOpenDeleteAwardModal(true),
    }),
    viewParticipants: (navigateFn) => ({
        label: (
            <button className='vert-menu-option-button' onClick={navigateFn}>
                <PickAwardeeIcon className='mr-2 pointer-cursor' />
                View Award details
            </button>
        ),
        value: 'viewParticipants',
    }),
};

export const getAnnouncementChannelString = (channelList, n) => {
    let retVal = channelList
        ?.slice(0, n)
        ?.map((c) => `#${c.channelName}`)
        ?.join(', ');
    if (channelList?.length > n) {
        retVal += `+${channelList.length - n}`;
    }
    return retVal;
};

/**
 * Hook for handling award-related navigation
 * @param {object} history - React Router history object
 * @returns {object} Navigation functions
 */
export const useAwardNavigation = (history) => {
    const navigateToViewParticipants = useCallback(
        (selectedAward) => {
            // setTimeout is used here because the selectedAward ref is updated by VertMenu's
            // getSelected callback. By pushing our navigation to the next event loop tick,
            // we ensure the ref is updated before we try to access it, preventing undefined
            // award ID in the URL
            setTimeout(() => {
                if (selectedAward.current?._id) {
                    history.push(`/dashboard/employee-awards/${selectedAward.current._id}/view-participants`);
                }
            }, 0);
        },
        [history]
    );

    return { navigateToViewParticipants };
};

export const remainingNotVoted = (award) => {
    if (award.stage === AWARD_STAGES.NOMINATION) {
        return {
            requestedCount: award.requestedCountForNomination,
            responseCount: award.responsesCountForNomination,
        };
    } else {
        return {
            requestedCount: award.requestedCount,
            responseCount: award.responseCount,
        };
    }
};
