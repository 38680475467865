import { useMutation } from 'react-query';
import { launchAward, editLaunchedAward } from '../Services/APIFunctions';
import { reduce, map, find, filter } from 'lodash';
import { format } from 'date-fns';
import { SELECT_WINNER } from '../constants';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const defaultAudience = {
    nominees: [], // nominee list for awardd poll
    eligibleVoters: [],
    excludedVoters: [],
    requestedUserForNomination: [], // refers people who can nominate others
    excludeNominators: [],
};

export const handleAwardDetailsData = ({
    data,
    setAwardSelectionType,
    setSelectedAward,
    setIncludeAwardValue,
    setAudience,
    setDirectWinnerDetails,
    setAwardPrize,
    setAnnouncement,
    awardMessage,
    setExclude,
    setExcludeNominators,
    setMaxNomineesCount,
}) => {
    const { _id, name, icon, backgroundColor, type, amount, currency, launchDate, description, country } = data;
    if (type === SELECT_WINNER.VIA_POLLS || type === SELECT_WINNER.VIA_NOMINATIONS) {
        const audienceData = getFormattedAudienceData(data);
        setAudience(audienceData);

        // Handle other poll/nomination specific logic
        setExclude({
            channel: data.requestedChannel.length > 0,
            checked: data.excludedUser.length > 0,
        });

        if (type === SELECT_WINNER.VIA_NOMINATIONS) {
            setMaxNomineesCount(data.maxNominees);
            setExcludeNominators({
                channel: data.requestedChannelForNomination.length > 0,
                checked: data.excludedUserForNomination.length > 0,
            });
        }
    }

    setAwardPrize({ amount, currency, country });
    setAwardSelectionType(data.type);
    setIncludeAwardValue(data.showAwardValue);
    setSelectedAward({ _id, name, icon, backgroundColor, description });
    setAnnouncement({
        date: new Date(launchDate),
        time: format(new Date(launchDate), 'hh:mm a'),
        channelId: data.announcementChannel[0].channelId,
        channelName: data.announcementChannel[0].channelName,
    });
    if (type === SELECT_WINNER.DIRECTLY) {
        awardMessage.current = data?.message;
        setDirectWinnerDetails([
            { id: data.awardee._id, name: data.awardee.userName, picUrl: data.awardee.pictureURL },
        ]);
    }
};

export const getFormattedAudienceData = (data) => {
    // Helper function for user list formatting
    const getUsersList = (users) =>
        map(users, (user) => ({
            id: user._id,
            name: user.userName,
            memberID: user.memberID,
            picUrl: user.pictureURL,
            channel: false,
        }));

    // Helper function for channel formatting
    const getChannelsList = (channels) =>
        map(channels, (channel) => ({
            id: channel.channelId,
            name: channel.channelName,
            channelID: channel.channelId,
            channelName: channel.channelName,
            channel: true,
        }));

    // Format nominees
    const nominees = map(data.nominee || [], (nominee) => ({
        id: nominee.userId._id,
        name: nominee.userId.userName,
        memberID: nominee.userId.memberID,
        picUrl: nominee.userId.pictureURL,
        channel: false,
    }));

    // Format users and channels
    const requestedUser = getUsersList(data.requestedUser || []);
    const requestedChannel = getChannelsList(data.requestedChannel || []);
    const requestedUserForNomination = getUsersList(data.requestedUserForNomination || []);
    const requestedChannelForNomination = getChannelsList(data.requestedChannelForNomination || []);

    return {
        nominees,
        excludedVoters: getUsersList(data.excludedUser || []),
        eligibleVoters: [...requestedUser, ...requestedChannel],
        excludeNominators: data.excludedUserForNomination || [],
        requestedUserForNomination: [...requestedUserForNomination, ...requestedChannelForNomination],
    };
};

export const isValidAwardData = ({
    type,
    audience,
    announcement,
    directWinnerDetails,
    SetSnackbar,
    setShowAddNomineesModal,
    setStep,
}) => {
    let valid = true;
    if (type === SELECT_WINNER.VIA_POLLS) {
        if (audience.nominees.length === 0 || audience.eligibleVoters.length === 0) {
            valid = false;
        } else if (audience.nominees.length === 1) {
            setShowAddNomineesModal(true);
            setStep(0);
            return false;
        }
    } else if (
        type === SELECT_WINNER.VIA_NOMINATIONS &&
        (audience.requestedUserForNomination.length === 0 || audience.eligibleVoters.length === 0)
    ) {
        valid = false;
    } else if (type === SELECT_WINNER.DIRECTLY && (!directWinnerDetails.awardee || !directWinnerDetails.awardMessage)) {
        valid = false;
    }
    if (!announcement.date || !announcement.time || !announcement.channelId || !announcement.channelName) {
        showSnackBarMessage(SetSnackbar, 'error', 'Please enter all the announcement details');
        return false;
    }
    if (!valid) {
        setStep(0);
        showSnackBarMessage(SetSnackbar, 'error', 'Please enter all the award details');
    }
    return valid;
};

export const getRequestedListData = (users) =>
    reduce(
        users,
        ([channelData, userData], item) => {
            if (item.channel) {
                channelData.push({
                    channelId: item.channelID,
                    channelName: item.channelName,
                });
            } else {
                userData.push(item.id);
            }
            return [channelData, userData];
        },
        [[], []]
    );

export const getLaunchDate = (date, time, sendInstantly) => {
    if (sendInstantly) {
        return { launchDate: time };
    }
    const dateStr = new Date(date);
    const timeStr = time;

    // Parse the time string
    const [timer, meridian] = timeStr.split(' ');
    let [hour, minute] = timer.split(':');

    if (hour === '12') {
        hour = '00';
    }
    if (meridian === 'PM') {
        hour = parseInt(hour, 10) + 12;
    }

    // Set the time in the date object
    dateStr.setHours(hour);
    dateStr.setMinutes(minute);

    // Format the combined date and time into a string in ISO format
    const combinedDateString = dateStr.toISOString();
    return { launchDate: combinedDateString };
};

export const getLaunchAwardAPIData = ({
    awardId,
    isMSTeams,
    teamId,
    type,
    includeAwardValue,
    selectedAward,
    awardPrize,
    audience,
    announcement,
    directWinnerDetails,
    maxNomineesCount,
}) => {
    const { launchDate } = getLaunchDate(announcement.date, announcement.time, announcement.sendInstant);
    const nominee = audience.nominees.map((user) => user._id || user.id);
    const [requestedChannel, requestedUser] = getRequestedListData(audience.eligibleVoters);
    const excludedVoters = audience.excludedVoters.map((user) => user.id);
    const excludedNominators = audience.excludeNominators.map((user) => user.id || user._id);
    const apiData = {
        ...(awardId && { awardId }),
        name: selectedAward.name,
        description: selectedAward.description,
        backgroundColor: selectedAward.backgroundColor,
        icon: selectedAward.icon,
        ...(awardPrize.amount
            ? { amount: awardPrize.amount, currency: awardPrize.currency, country: awardPrize.country }
            : {}),
        launchDate,
        type,
        announcementChannel: [
            { channelId: announcement.channelId, channelName: announcement.channelName, ...(isMSTeams && { teamId }) },
        ],
        ...(type === SELECT_WINNER.VIA_POLLS && {
            nominee,
        }),
        ...((type === SELECT_WINNER.VIA_POLLS || type === SELECT_WINNER.VIA_NOMINATIONS) && {
            requestedUser,
            excludedUser: excludedVoters,
            requestedChannel,
        }),
        ...(type === SELECT_WINNER.VIA_NOMINATIONS && {
            requestedUserForNomination: filter(audience.requestedUserForNomination, (item) => !item.channel).map(
                (user) => user._id || user.id
            ),
            maxNominees: maxNomineesCount,
            requestedChannelForNomination: filter(audience.requestedUserForNomination, (item) => item.channel).map(
                (item) => ({ channelId: item.channelID, channelName: item.channelName, ...(isMSTeams && { teamId }) })
            ),
            excludedUserForNomination: excludedNominators,
        }),
        showAwardValue: includeAwardValue,
        ...(announcement?.sendInstant && { sendInstantly: true }),
        ...(type === SELECT_WINNER.DIRECTLY && {
            awardee: directWinnerDetails.awardee,
            message: directWinnerDetails.awardMessage,
        }),
    };
    return apiData;
};

export const useLaunchAward = ({ onError, onSuccess }) => {
    return useMutation(launchAward, {
        onError: async (err) => onError(err),
        onSuccess: async () => onSuccess(),
    });
};

export const useEditAward = ({ onError, onSuccess }) => {
    return useMutation(editLaunchedAward, {
        onError: async (err) => onError(err),
        onSuccess: async () => onSuccess(),
    });
};

export const DATA_ADD_MORE_NOMINEES = {
    heading: 'Add more nominees',
    subHeading: 'To launch a Poll Award, add at least 2 nominees.',
    confirm: 'Close',
};
export const getDefaultCountry = (workspaceCountry, countries) => {
    const filteredCountry = find(countries, (country) => country.countryCode === workspaceCountry);
    return {
        amount: '',
        currency: filteredCountry?.currency || 'AFN',
        country: filteredCountry?.country || 'Afghanistan',
    };
};
