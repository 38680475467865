import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as VerticalSeparator } from 'Assets/images/vertical-separator.svg';
import { format } from 'date-fns';
import './style.scss';
import { makePlural } from 'utils/HelperFunctions';

const PulseDetails = ({ taskItemData, showSender, isExpired }) => {
    const { sender, questions, questionsCount, anonymous, responseDeadline } =
        taskItemData?.metadata?.pulseIssue || taskItemData?.pulseIssueData || {};
    const formattedDate = responseDeadline && format(new Date(responseDeadline), "MMM d, yyyy 'at' h:mm aaa");

    return (
        <div className='pulse-answers-details'>
            {showSender && (
                <>
                    <div>
                        <span className='pulse-answers-light-text'>Sent By: &nbsp;</span>
                        <img
                            src={sender?.pictureURL || require('Assets/images/defaultUser.png')}
                            alt=''
                            height={16}
                            width={16}
                            className='pulse-admin-logo'
                        />
                        <span className='pulse-answers-bold-text'>{sender?.userName}</span>
                    </div>
                    <div className='vertical-separator'>
                        <VerticalSeparator />
                    </div>
                </>
            )}
            <div>
                <span className='pulse-answers-bold-text'>{questionsCount || questions?.length} &nbsp;</span>
                <span className='pulse-answers-light-text'>
                    {makePlural(questionsCount || questions?.length, 'Question', true)}
                </span>
            </div>
            <div className='vertical-separator'>
                <VerticalSeparator />
            </div>
            <div>
                <span className='pulse-answers-bold-text'>{anonymous ? 'Anonymous' : 'Non-Anonymous'}</span>
            </div>
            <div className='vertical-separator'>
                <VerticalSeparator />
            </div>
            <div>
                <span className='pulse-answers-light-text'>Expiry: </span>
                <span className='pulse-answers-bold-text'>
                    {formattedDate}
                    {isExpired && ` (expired)`}
                </span>
            </div>
        </div>
    );
};

PulseDetails.propTypes = {
    taskItemData: PropTypes.object.isRequired,
    showSender: PropTypes.bool,
    isExpired: PropTypes.bool,
};

export default PulseDetails;
