import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as PulseAnswered } from 'Assets/images/pulse-answered.svg';
import { ReactComponent as PulseExpired } from 'Assets/images/pulse-expired.svg';
import EWButton from 'components/ReusableComponents/EWButton';

const Index = ({ open, data, onConfirm, onClose, showCloseButtton = true, pulseExpired }) => {
    if (!open) {
        return null;
    }

    return (
        <Modal open={open}>
            <div className='pulse-created-modal absolute-center'>
                <div className='content d-flex flex-column justify-content-center align-items-center'>
                    {pulseExpired ? <PulseExpired /> : <PulseAnswered />}
                    <div className='pc-title my-3'>{data?.title}</div>
                    <p>{data?.content}</p>
                    {data?.buttonText && (
                        <EWButton
                            border
                            primary={false}
                            buttonStyleClass='mt-2'
                            buttonText={data?.buttonText}
                            onClick={onConfirm}
                        />
                    )}
                    {showCloseButtton && (
                        <EWButton
                            buttonStyleClass='mt-2'
                            primary={false}
                            plainTextButton
                            buttonText='← Back'
                            onClick={onClose}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        buttonText: PropTypes.string,
    }),
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    showCloseButtton: PropTypes.bool,
    pulseExpired: PropTypes.bool,
};

export default Index;
