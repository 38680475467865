import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MemberTag from '../../components/MemberTag';
import EWButton from 'components/ReusableComponents/EWButton';
import PulseDetails from 'components/Dashboard/Content/PulseSurvey/Components/PulseDetails';
import { ReactComponent as PulseTaskIcon } from 'Assets/images/pulse-task-icon.svg';
import { PULSE_RESPONSE_STATUS, PULSE_SUB_TYPE } from 'components/Dashboard/Content/PulseSurvey/constants';
import PulseAnsweredModal from 'components/Modals/PulseAnsweredModal';
import { ROUTES } from 'constants.js';

const PulseTaskComponent = ({ taskItemData, formattedDate, history, isAdmin }) => {
    const { name, sender, status, subtype } = taskItemData.metadata.pulseIssue;
    const [viewAnsweredModal, setViewAnsweredModal] = useState(false);
    const isENPS = subtype === PULSE_SUB_TYPE.ENPS;
    const pulseStatus = taskItemData?.metadata?.pulseIssue?.status;
    const responseStatus = taskItemData?.metadata?.response?.status;
    const isEnps = taskItemData?.metadata?.pulseIssue?.subtype === PULSE_SUB_TYPE.ENPS;
    const buttonStyle =
        pulseStatus === PULSE_RESPONSE_STATUS.ACTIVE && responseStatus === PULSE_RESPONSE_STATUS.NOT_ATTEMPTED;

    const pulseExpiredData = {
        title: `This ${isEnps ? 'eNPS survey' : 'Pulse'} has expired.`,
        content: 'You did not submit your responses in time.',
    };

    const getButtonText = () => {
        if (pulseStatus === PULSE_RESPONSE_STATUS.ACTIVE) {
            if (responseStatus === PULSE_RESPONSE_STATUS.NOT_ATTEMPTED) {
                return 'Start Pulse';
            } else if (responseStatus === PULSE_RESPONSE_STATUS.PARTIALLY_COMPLETE) {
                return 'Complete Pulse';
            }
            return 'Edit Responses';
        } else if (
            pulseStatus === PULSE_RESPONSE_STATUS.COMPLETED &&
            responseStatus !== PULSE_RESPONSE_STATUS.COMPLETE
        ) {
            return 'Pulse Expired';
        }
        return 'View Responses';
    };

    const handleButtonClick = () => {
        if (responseStatus === PULSE_RESPONSE_STATUS.COMPLETE && pulseStatus === PULSE_RESPONSE_STATUS.COMPLETED) {
            history.push(`/dashboard/pulse/answer?responseId=${taskItemData.metadata.response._id}`, {
                hidePulseModal: true,
            });
            return;
        }
        history.push(`/dashboard/pulse/answer?responseId=${taskItemData.metadata.response._id}`);
    };

    const onConfirm = () => {
        if (pulseExpiredData.buttonText) {
            history.push(`/dashboard/pulse/answer?responseId=${taskItemData.metadata.response._id}`);
        } else {
            history.push(ROUTES.TASKS);
        }
        setViewAnsweredModal(false);
    };

    const onClose = () => {
        history.push(ROUTES.TASKS);
        setViewAnsweredModal(false);
    };

    const getDisplayText = () => {
        if (status === PULSE_RESPONSE_STATUS.ACTIVE) {
            if (responseStatus === PULSE_RESPONSE_STATUS.NOT_ATTEMPTED) {
                return isENPS ? (
                    <>
                        is requesting you to complete an <b>{name}</b> survey.
                    </>
                ) : (
                    <>
                        is requesting feedback in the Pulse <b>{name}.</b>
                    </>
                );
            } else if (responseStatus === PULSE_RESPONSE_STATUS.PARTIALLY_COMPLETE) {
                return (
                    <>
                        You have partially filled {isENPS ? 'the' : 'this Pulse'} <b>{name}.</b>
                    </>
                );
            }
            return (
                <>
                    Thank you for your response to <b>{name}.</b>
                </>
            );
        } else if (
            pulseStatus === PULSE_RESPONSE_STATUS.COMPLETED &&
            responseStatus !== PULSE_RESPONSE_STATUS.COMPLETE
        ) {
            return (
                <>
                    You have not attempted {isENPS ? 'the' : 'this Pulse'} <b>{name}.</b>
                </>
            );
        }
        return (
            <>
                You submitted your responses to {isENPS ? 'the' : 'pulse'} <b>{name}.</b>
            </>
        );
    };

    return (
        <div className='recognitions-tasks-container'>
            <div className='recognition-container'>
                <div className='accept-reject-image'>
                    <PulseTaskIcon />
                </div>
                <div className='recognition-data'>
                    <p className='timestamp'>{formattedDate}</p>
                    <div className='recognition-text'>
                        {status === PULSE_RESPONSE_STATUS.ACTIVE &&
                            responseStatus === PULSE_RESPONSE_STATUS.NOT_ATTEMPTED && (
                                <>
                                    <MemberTag
                                        history={history}
                                        memberID={sender._id}
                                        memberName={sender.userName}
                                        memberPhoto={sender.pictureURL}
                                        isAdmin={isAdmin}
                                    />
                                    &nbsp;
                                </>
                            )}
                        <p>{getDisplayText()}</p>
                    </div>
                    <PulseDetails
                        taskItemData={taskItemData}
                        showSender={
                            !(
                                status === PULSE_RESPONSE_STATUS.ACTIVE &&
                                responseStatus === PULSE_RESPONSE_STATUS.NOT_ATTEMPTED
                            )
                        }
                        isExpired={status !== PULSE_RESPONSE_STATUS.ACTIVE}
                    />
                </div>
            </div>
            <div className='manage-button'>
                <EWButton
                    width={190}
                    border={!buttonStyle}
                    primary={buttonStyle}
                    buttonText={getButtonText()}
                    disabled={getButtonText() === 'Pulse Expired'}
                    onClick={handleButtonClick}
                />
            </div>
            {viewAnsweredModal && (
                <PulseAnsweredModal
                    open={viewAnsweredModal}
                    data={pulseExpiredData}
                    onConfirm={onConfirm}
                    onClose={onClose}
                    pulseExpired={true}
                />
            )}
        </div>
    );
};

PulseTaskComponent.propTypes = {
    taskItemData: PropTypes.object.isRequired,
    formattedDate: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
};

export default PulseTaskComponent;
