import './style.scss';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BONUS_STATUS } from '../../constants';
import EATable from '../../Components/EATable';
import CustomSearchBox from 'components/Styles/CustomSearchBox';

const Index = ({
    history,
    count,
    pageNumber,
    setPageNumber,
    activeKey,
    setActiveKey,
    tableData,
    loading,
    sort,
    setSort,
    total,
    handleSearchChange,
    platform,
    search,
}) => {
    const paginationProps = {
        currentPage: pageNumber,
        setCurrentPage: setPageNumber,
        totalCount: total,
        fixedBottom: true,
    };

    const onTabSelect = (value) => {
        history.push({
            search: `?tab=${value}`,
        });
        setActiveKey(value);
        setPageNumber(1);
    };

    return (
        <div className='container-bonus'>
            <div className='bg-white'>
                <div className='container-member-manager navigatortabs'>
                    <Tabs activeKey={activeKey} onSelect={onTabSelect}>
                        <Tab eventKey={BONUS_STATUS.SCHEDULED} title={`Scheduled (${count.scheduled || 0})`}>
                            <div className='mt-3'>
                                <EATable
                                    tab={BONUS_STATUS.SCHEDULED}
                                    paginationProps={paginationProps}
                                    rowData={tableData}
                                    loading={loading}
                                    sort={sort}
                                    setSort={setSort}
                                    total={total}
                                    platform={platform}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={BONUS_STATUS.COMPLETED} title={`Completed (${count.completed || 0})`}>
                            <div className='mt-3'>
                                <EATable
                                    tab={BONUS_STATUS.COMPLETED}
                                    paginationProps={paginationProps}
                                    rowData={tableData}
                                    loading={loading}
                                    sort={sort}
                                    setSort={setSort}
                                    total={total}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                    <div className='tabs-search-container'>
                        <CustomSearchBox
                            width={'320px'}
                            value={search}
                            handleSearch={handleSearchChange}
                            placeholder={'Search Bonus name or awardee'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    count: PropTypes.object,
    pageNumber: PropTypes.string,
    setPageNumber: PropTypes.func,
    activeKey: PropTypes.string,
    setActiveKey: PropTypes.func,
    tableData: PropTypes.array,
    loading: PropTypes.bool,
    sort: PropTypes.object,
    setSort: PropTypes.func,
    total: PropTypes.number,
    handleSearchChange: PropTypes.func,
    platform: PropTypes.string,
    search: PropTypes.string,
};

export default Index;
