import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import EWModal from 'components/ReusableComponents/EWModal';
import PropTypes from 'prop-types';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Certificate from './Certificate';
import './style.scss';
import clsx from 'clsx';

const Index = ({ open, onClose, awardDate, awardName, awardee, awardIcon }) => {
    const certificateRef = useRef();

    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    if (!open) {
        return null;
    }
    const downloadCertificate = () => {
        setDownloading(true);

        const rect = certificateRef.current.getBoundingClientRect();

        html2canvas(certificateRef.current, {
            useCORS: true, // Enable CORS for external images
            allowTaint: false, // Prevent tainting of the canvas
            width: rect.width,
            height: rect.height,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape');
            pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
            pdf.save(`${awardName || 'certificate'}.pdf`);
            setDownloading(false);
        });
    };
    const modalData = {
        onClose: onClose,
        loading: downloading,
        ...(!loading && { rightButtonText: 'Download Certificate' }),
        ...(!loading && { handleRightButtonClick: downloadCertificate }),
    };

    return (
        <EWModal open={open} onClose={onClose} width='810px' height='700px' modalData={modalData}>
            {loading && <CustomLoader />}
            <div className={clsx({ 'certificate-container': true, 'display-none': loading })}>
                <Certificate
                    certificateRef={certificateRef}
                    awardDate={awardDate}
                    awardName={awardName}
                    awardee={awardee}
                    awardIcon={awardIcon}
                    setLoading={setLoading}
                />
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    awardDate: PropTypes.string,
    awardName: PropTypes.string,
    awardee: PropTypes.string,
    awardIcon: PropTypes.string,
};

export default Index;
