import React from 'react';
import PropTypes from 'prop-types';
import FeedbackTable from '../FeedbackTable';
import '../style.scss';

const Index = ({
    history,
    data,
    setData,
    feedBackEnabled,
    platform,
    showReadFeedbackModal,
    setShowReadFeedbackModal,
    currentFeedback,
    setNewFeedbackModal,
}) => {
    return (
        <div className='my-feedback-container'>
            <FeedbackTable
                history={history}
                feedbackGiven={false}
                data={data}
                feedBackEnabled={feedBackEnabled}
                setData={setData}
                setNewFeedbackModal={setNewFeedbackModal}
                platform={platform}
                showReadFeedbackModal={showReadFeedbackModal}
                setShowReadFeedbackModal={setShowReadFeedbackModal}
                currentFeedback={currentFeedback}
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
    setData: PropTypes.func,
    setNewFeedbackModal: PropTypes.func,
    feedBackEnabled: PropTypes.bool,
    platform: PropTypes.string,
    showReadFeedbackModal: PropTypes.bool,
    setShowReadFeedbackModal: PropTypes.func,
    currentFeedback: PropTypes.object,
};

export default Index;
