import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import {
    updateCelebrationSettings,
    getGiftValues,
    getCelebrationConfigs,
    getIntegratedChannelsList,
} from 'Services/apiFunctions';
import { GET_GIFT_VALUES, GET_CELEBRATION_CONFIGS, GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import SelectChannelModal from '../SelectChannelModal';
import GiftValuesModal from '../GiftValuesModal';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import ToggleSetting from 'components/ReusableComponents/ToggleSetting';
import WishesPreview from '../WishesPreview';
import { ReactComponent as Pencil } from 'Assets/images/edit-blue-pencil.svg';
import { configureGiftValuesData, CONFIGURE_GIFT_SUBTITLE, DISABLE_CHANNEL_WISHES_MODAL_DATA } from '../constants';
import './style.scss';
import { getChannelOptionsByPlatform, getIntegratedChannelName } from 'utils/HelperFunctions';
import { PLATFORM } from 'constants.js';
import clsx from 'clsx';
import CustomCheckbox from 'components/Styles/CustomCheckbox';

const Index = ({ history }) => {
    const queryClient = useQueryClient();
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const [celebrationConfigs, setCelebrationConfigs] = useState({
        isBirthdayEnabled: false,
        isAnniversaryEnabled: false,
        isChannelEnabled: false,
        isGiftEnabled: false,
        addHereTagInMessage: false,
    });

    const [showChannelModal, setShowChannelModal] = useState(false);
    const [showGiftValuesModal, setShowGiftValuesModal] = useState(false);
    const [showDisableChannelWishesModal, setShowDisableChannelWishesModal] = useState(false);
    const [channelsData, setChannelsData] = useState([]);
    const [giftValues, setGiftValues] = useState([]);
    const [showConfigureGiftValuesModal, setShowConfigureGiftValuesModal] = useState(false);

    const { SetSnackbar } = useToaster();
    const isMsteams = platform === PLATFORM.TEAMS;
    const isWebPlatform = platform === PLATFORM.WEB;
    const channelName = getIntegratedChannelName(celebrationConfigs, platform);

    const openSnackbar = (variant, message) => {
        SetSnackbar({ open: true, variant, message: message || 'Some error occurred' });
    };

    useQuery([GET_CELEBRATION_CONFIGS], getCelebrationConfigs, {
        onSuccess: (data) => setCelebrationConfigs(data),
        onError: (err) => openSnackbar('error', err?.message),
    });

    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelsData(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError: (err) => openSnackbar('error', err?.message),
    });

    const { data: giftValuesData } = useQuery([GET_GIFT_VALUES], getGiftValues, {
        onSuccess: (data) => handleGiftData(data),
        onError: (err) => openSnackbar('error', err?.message),
    });

    const updateSettings = async (key, value) => {
        try {
            updateCelebrationStates(key, value);
            await updateCelebrationSettings({ apiData: { [key]: value } });
            openSnackbar('success', 'Updated successfully.');
        } catch (e) {
            updateCelebrationStates(key, !value);
            openSnackbar('error', e?.message || 'Some error occurred');
        }
    };

    const updateCelebrationStates = (key, value) => setCelebrationConfigs({ ...celebrationConfigs, [key]: value });

    const { mutate, isLoading: isUpdatingGiftValues } = useMutation(updateCelebrationSettings, {
        onSuccess: () => {
            queryClient.invalidateQueries(GET_GIFT_VALUES);
            setShowGiftValuesModal(false);
            openSnackbar('success', 'Gift values updated successfully.');
        },
        onError: (error) => openSnackbar('error', error?.message || 'Some error occurred'),
    });

    const formatGiftValues = (value) => {
        if (value) {
            if (typeof value === 'string') {
                return value.split(',').map((num) => parseInt(num.trim(), 10));
            } else {
                return value;
            }
        } else {
            return [0];
        }
    };

    const validateAnniversaryValues = (values) => {
        return values.every((value, index, arr) => index === 0 || value >= arr[index - 1]);
    };

    const handleSave = async (data) => {
        try {
            const giftValues = data?.map((item) => {
                const formatedAnniversaryValues = formatGiftValues(item?.anniversaryValue);
                if (Array.isArray(formatedAnniversaryValues) && !validateAnniversaryValues(formatedAnniversaryValues)) {
                    throw new Error('Anniversary values should be in increasing order');
                }
                return {
                    anniversaryValue: {
                        value: formatedAnniversaryValues,
                    },
                    birthDateValue: item.birthDateValue,
                    status: item.status,
                    country: item.country,
                };
            });
            const apiData = {
                giftValues,
            };
            mutate({ apiData });
        } catch (err) {
            openSnackbar('error', err?.message || 'Some error occurred');
        }
    };

    const handleGiftModalClose = () => {
        setShowGiftValuesModal(false);
        handleGiftData(giftValuesData);
    };

    const handleGiftData = (data) => {
        const tempData = data?.map((item) => ({ ...item, modified: false })) || [];
        setGiftValues(tempData);
    };

    const saveChannel = async (value) => {
        try {
            const apiData = {
                isChannelEnabled: true,
                isPrivate: value.isPrivate,
                ...(isMsteams
                    ? {
                          channelName: value.channelName,
                          channelId: value.channelId,
                          channelUrl: value.channelUrl,
                          groupId: value.groupId,
                          groupName: value.groupName,
                      }
                    : { channelName: value.channelName, channelId: value.channelId ?? value.channelID }),
            };
            setCelebrationConfigs({ ...celebrationConfigs, ...apiData, ...value });
            setShowChannelModal(false);
            await updateCelebrationSettings({ apiData });
            openSnackbar('success', 'Updated successfully.');
        } catch (e) {
            setCelebrationConfigs({ ...celebrationConfigs, channelName: '', channelId: '' });
            openSnackbar('error', e?.message || 'Some error occurred');
        }
    };

    const handleChannelWishesToggle = (value) => {
        setShowChannelModal(value);
        if (!value) {
            setShowDisableChannelWishesModal(true);
        } else {
            setCelebrationConfigs({
                ...celebrationConfigs,
                isChannelEnabled: value,
            });
        }
    };

    const handleDisableChannelWishes = () => {
        setShowDisableChannelWishesModal(false);
        updateSettings('isChannelEnabled', false);
    };

    return (
        <div className='container-wishes'>
            <div className='wishes mt-3'>
                <h6 className='heading'>Celebration Wishes</h6>
                <p className='grey-text'>Send wishes to your employees on their birthdays and work anniversaries.</p>
                <div className='celebrations-checkbox-container'>
                    <div className='celebration-checkbox'>
                        <CustomCheckbox
                            checked={celebrationConfigs?.isBirthdayEnabled}
                            onClick={() => updateSettings('isBirthdayEnabled', !celebrationConfigs?.isBirthdayEnabled)}
                            label='Birthdays'
                        />
                    </div>
                    <div className='celebration-checkbox'>
                        <CustomCheckbox
                            checked={celebrationConfigs?.isAnniversaryEnabled}
                            onClick={() =>
                                updateSettings('isAnniversaryEnabled', !celebrationConfigs?.isAnniversaryEnabled)
                            }
                            label='Anniversaries'
                        />
                    </div>
                </div>
                <p className='grey-text mb-0'>{`Every day at ${celebrationConfigs?.wishSendTime} ${celebrationConfigs?.timezone}, EngageWith checks for employee celebrations.`}</p>
                <p className='grey-text mb-0'>{`Update dates in advance to send timely wishes!`}</p>
            </div>

            <div className='gift-config'>
                <ToggleSetting
                    title='Configure gift values for celebrations'
                    subTitle={CONFIGURE_GIFT_SUBTITLE}
                    value={celebrationConfigs?.isGiftEnabled}
                    onChange={() => updateSettings('isGiftEnabled', !celebrationConfigs?.isGiftEnabled)}
                />
                <button
                    className={clsx('link-btn font-weight-normal pointer-cursor mt-3', {
                        'disabled-setup-cta': !celebrationConfigs?.isGiftEnabled,
                    })}
                    disabled={!celebrationConfigs?.isGiftEnabled}
                    onClick={() =>
                        celebrationConfigs?.isGiftEnabled && giftValues.length
                            ? setShowGiftValuesModal(true)
                            : setShowConfigureGiftValuesModal(true)
                    }
                >
                    {`${giftValues.length ? 'Manage' : 'Setup'} birthday and anniversary gift`}
                </button>
            </div>

            {!isWebPlatform && (
                <div className='channel-config'>
                    <h6 className='heading'>Configure channel and notification</h6>
                    <p className='grey-text'>
                        Send wishes on a preferred channel to let everyone know who is celebrating their special day
                    </p>
                    <ToggleSetting
                        title='Channel wishes'
                        value={celebrationConfigs.isChannelEnabled}
                        onChange={() => handleChannelWishesToggle(!celebrationConfigs.isChannelEnabled)}
                    />
                    {celebrationConfigs?.isChannelEnabled && (
                        <p className='grey-text'>
                            Wishes will be sent on <strong>{channelName}</strong>
                            <button
                                className='link-btn font-weight-normal'
                                onClick={() => setShowChannelModal(!showChannelModal)}
                            >
                                &nbsp;&nbsp;
                                <Pencil /> Edit
                            </button>
                        </p>
                    )}

                    {!isMsteams && celebrationConfigs?.isChannelEnabled && channelName && (
                        <ToggleSetting
                            title='@here notification'
                            subTitle={
                                <>
                                    This will notify all active members on <strong>{channelName}</strong> channel about
                                    their peer’s birthdays and work anniversaries
                                </>
                            }
                            value={celebrationConfigs?.addHereTagInMessage}
                            onChange={() =>
                                updateSettings('addHereTagInMessage', !celebrationConfigs?.addHereTagInMessage)
                            }
                        />
                    )}
                </div>
            )}

            <WishesPreview platform={platform} />
            {showChannelModal && (
                <SelectChannelModal
                    open={showChannelModal}
                    setOpen={setShowChannelModal}
                    channelsData={channelsData}
                    selectedChannel={{
                        channelId: celebrationConfigs.channelId,
                        channelName: celebrationConfigs.channelName,
                        ...(isMsteams && {
                            groupName: celebrationConfigs.groupName,
                            groupId: celebrationConfigs.groupId,
                        }),

                        isPrivate: celebrationConfigs?.isPrivate,
                    }}
                    handleSave={(value) => saveChannel(value)}
                    platform={platform}
                    onClose={() => {
                        !channelName && setCelebrationConfigs({ ...celebrationConfigs, isChannelEnabled: false });
                        setShowChannelModal(false);
                    }}
                />
            )}

            {showGiftValuesModal && (
                <GiftValuesModal
                    open={showGiftValuesModal}
                    onClose={handleGiftModalClose}
                    apiData={giftValuesData}
                    giftData={giftValues}
                    handleSave={handleSave}
                    loading={isUpdatingGiftValues}
                    isBirthdayEnabled={celebrationConfigs?.isBirthdayEnabled}
                    isWorkAnniversaryEnabled={celebrationConfigs?.isAnniversaryEnabled}
                    history={history}
                />
            )}

            {showConfigureGiftValuesModal && (
                <ConfirmModal
                    open={showConfigureGiftValuesModal}
                    data={configureGiftValuesData}
                    onClose={() => setShowConfigureGiftValuesModal(false)}
                    onConfirm={() => history.push(`/dashboard/users`)}
                    height={'245px'}
                    singleButton
                />
            )}

            {showDisableChannelWishesModal && (
                <ConfirmModal
                    open={showDisableChannelWishesModal}
                    data={DISABLE_CHANNEL_WISHES_MODAL_DATA}
                    onClose={() => setShowDisableChannelWishesModal(false)}
                    onConfirm={() => handleDisableChannelWishes()}
                    red
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    platform: state.Workspace.platform,
    wsConfig: state.Workspace.config,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
