import React from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { updateFeedbackConfiguration } from '../Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as SetupFeedbackImage } from 'Assets/images/setup-feedback.svg';
import { FEEDBACK_360, FEEDBACK } from '../constants';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const { todoConfigs } = useSelector(mapStateToProps, shallowEqual);
    const { mutateAsync: updateFeedbackConfigs, isLoading } = useMutation(updateFeedbackConfiguration);

    const updateConfigs = async () => {
        try {
            const apiData = {
                enabled: true,
            };
            await updateFeedbackConfigs({ apiData });
            if (!todoConfigs.isFeedbackFlowCompleted) {
                updateWorkspaceDetails(dispatch, { todo: { ...todoConfigs, isFeedbackFlowCompleted: true } });
            }
            showSnackBarMessage(SetSnackbar, 'success', 'Feedback enabled successfully');
            history.push(FEEDBACK.CONFIGURATION);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const emptyScreenContent = {
        image: <SetupFeedbackImage />,
        headingText: [FEEDBACK_360],
        subheadingText: 'Empower employees to share meaningful, constructive, and actionable insights with each other.',
        btnName: 'Enable',
        action: () => updateConfigs(),
        disabled: isLoading,
    };
    return (
        <div>
            <TitleBar title={[FEEDBACK_360]} />
            <div className='container-setup-feedback'>
                <EmptyContent {...emptyScreenContent} />
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    todoConfigs: Workspace.todo,
});

Index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};

export default Index;
