import React, { useState, useEffect } from 'react';
import SideOverlay from 'components/ReusableComponents/SideOverlay';
import RecognizedUsers from '../../RecognitionsHome/RecognitionFeed/RecognitionItem/RecognizedUsers';
import Recognizer from '../../RecognitionsHome/RecognitionFeed/RecognitionItem/Recognizer';
import PendingApproval from '../PendingApproval';
import { getFormattedDate, showSnackBarMessage, isSlack } from 'utils/HelperFunctions';
import UserButton from 'components/ReusableComponents/UserButton';
import EWButton from 'components/ReusableComponents/EWButton';
import PropTypes from 'prop-types';
import RejectModal from '../components/TaskCtaModal';
import ConfirmCancelModal from '../components/CancelModal';
import { setTaskStatus } from 'Services/apiFunctions';
import { useMutation, useQueryClient } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { GET_TASKS } from 'Services/apiKeys';
import { useSelector, shallowEqual } from 'react-redux';
import { RECOGNITION_STATUS } from '../constants';
import './style.scss';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { PLATFORM } from 'constants.js';

const Index = ({ open, setOpen, addOn, recognition, isSelfRecognition, users, timestamp }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const [confirmModal, setConfirmModal] = useState(false);
    const [reason, setReason] = useState('');
    const [openRejectModal, setOpenRejectModal] = useState(false);
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
    const [approveBtnLoading, setApproveBtnLoading] = useState(false);
    const [cancelBtnLoading, setCancelBtnLoading] = useState(false);
    const [recognitionRequestExpired, setRecognitionRequestExpired] = useState(false);
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const isSlackPlatform = isSlack(platform);
    const isWebOnlyUser = platform === PLATFORM.WEB;

    const formattedDate = getFormattedDate(new Date(timestamp));
    const handleChange = (event) => {
        setReason(event.target.value);
    };

    const { mutateAsync: updateTaskStatus } = useMutation(setTaskStatus);

    const handleApprove = async () => {
        setApproveBtnLoading(true);
        await handleRecognition({ status: RECOGNITION_STATUS.APPROVED });
        setApproveBtnLoading(false);
    };

    const handleCancel = async () => {
        setCancelBtnLoading(true);
        await handleRecognition({ status: RECOGNITION_STATUS.CANCELLED });
        setConfirmModal(false);
        setCancelBtnLoading(false);
    };

    const handleReject = async () => {
        setRejectBtnLoading(true);
        await handleRecognition({ reason, status: RECOGNITION_STATUS.REJECTED });
        setOpenRejectModal(false);
        setRejectBtnLoading(false);
    };

    const handleRecognition = async ({ reason, status }) => {
        const payload = {
            transactionIds: recognition.transactionIds,
            status: status,
            ...(reason && { reason }),
        };
        try {
            const { message } = await updateTaskStatus(payload);
            showSnackBarMessage(SetSnackbar, 'success', message);
            queryClient.invalidateQueries(GET_TASKS);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error.message);
            if (error.message === 'Recognition request expired' || error.message === 'Recognition request cancelled') {
                setRecognitionRequestExpired(true);
            }
        }
    };

    useEffect(() => {
        // code for hiding the zoho sales iq chat when the modal is open
        const hideElement = () => {
            const zsiqFloatElement = document.getElementById('zsiq_float');
            if (zsiqFloatElement) {
                zsiqFloatElement.style.display = 'none';
            }
        };
        // Call hideElement function when the modal mounts
        hideElement();
        // Function to show the element when the modal unmounts
        const showElement = () => {
            const zsiqFloatElement = document.getElementById('zsiq_float');
            if (zsiqFloatElement) {
                zsiqFloatElement.style.display = ''; // Revert to default display
            }
        };
        // Return a cleanup function to show the element when the modal unmounts
        return () => {
            showElement();
        };
    }, []);

    return (
        <SideOverlay
            open={open}
            onClose={() => setOpen(false)}
            height='100%'
            heading={!addOn ? 'Recognition' : 'Add on Recognition'}
            width='416px'
        >
            <div className='tasks-approval-container flex'>
                <div className='tasks-approval-header'>
                    <PendingApproval recognition={recognition} isSelfRecognition={isSelfRecognition} />
                    <div style={{ marginTop: '24px' }}>
                        <Recognizer
                            recognitionData={{
                                ...recognition,
                                ...(isSelfRecognition && { sender: { userName: 'You' } }),
                            }}
                        />
                    </div>
                </div>
                <RecognizedUsers isApproveOrRejectModal={true} users={users} value={recognition?.companyValues} />
                <div className='task-details-container'>
                    <div className='sent-on-details'>
                        <span className='sent-on'>Sent On</span>
                        <br />
                        <span className='sent-on-time'>{formattedDate}</span>
                    </div>
                    <div className='destination-details mt-3'>
                        <span className='destination'>Destination</span>
                        <br />
                        {recognition.isAddOn ? (
                            <span className='destination-channel'>
                                Will pe posted as add-on message on the original&nbsp;
                                <UserButton
                                    id={recognition?.taskId}
                                    customClassname='destination-main-channel'
                                    clickableUser={true}
                                    senderName={`#post.`}
                                    isAnonymous={false}
                                    onClick={() => window.open(recognition?.parentTransactionLink, '_blank')}
                                />
                            </span>
                        ) : (
                            <span className='destination-channel'>
                                <UserButton
                                    id={recognition?.taskId}
                                    showImage={false}
                                    customClassname='destination-main-channel'
                                    clickableUser={isSlackPlatform && !isWebOnlyUser}
                                    senderName={
                                        isSlackPlatform && !isWebOnlyUser ? (
                                            <EllipsisText text={`#${recognition.channel.name}`} maxWidth='238px' />
                                        ) : (
                                            <EllipsisText
                                                text={
                                                    recognition.channel.channelDisplayName
                                                        ? `${recognition.channel.channelDisplayName} `
                                                        : ''
                                                }
                                                maxWidth='238px'
                                            />
                                        )
                                    }
                                    onClick={() => {
                                        if (isSlackPlatform && !isWebOnlyUser) {
                                            window.open(recognition?.channel?.link, '_blank');
                                        } else {
                                            return;
                                        }
                                    }}
                                />
                                <span className='ml-1'> + Recognition Feed</span>
                            </span>
                        )}
                    </div>
                    <div className='message-details mt-3'>
                        <span className='message'>Message</span>
                        <br />
                        <span className='recognition-message'>{recognition?.message}</span>
                    </div>
                </div>
                <div className=' cta-btn-container'>
                    {!isSelfRecognition ? (
                        <div className='approve-reject-btns'>
                            <EWButton
                                buttonText='Reject'
                                redBackground={true}
                                primary={false}
                                buttonStyleClass='reject-btn'
                                width='106px'
                                loading={rejectBtnLoading}
                                disabled={recognitionRequestExpired}
                                onClick={() => setOpenRejectModal(true)}
                            />
                            <EWButton
                                buttonText='Approve'
                                greenBackground={true}
                                primary={false}
                                buttonStyleClass='approve-btn'
                                width='106px'
                                loading={approveBtnLoading}
                                disabled={recognitionRequestExpired}
                                onClick={handleApprove}
                            />
                        </div>
                    ) : (
                        <div className='cancel-btn-container'>
                            <EWButton
                                buttonText='Cancel Request'
                                redBackground={true}
                                primary={false}
                                buttonStyleClass='cancel-request-btn'
                                onClick={() => setConfirmModal(true)}
                                disabled={recognitionRequestExpired}
                            />
                        </div>
                    )}
                </div>

                {openRejectModal && !isSelfRecognition && (
                    <RejectModal
                        open={openRejectModal}
                        setOpen={setOpenRejectModal}
                        reason={reason}
                        handleChange={handleChange}
                        handleReject={handleReject}
                        isLoading={rejectBtnLoading}
                    />
                )}
                {confirmModal && (
                    <ConfirmCancelModal
                        setOpen={setConfirmModal}
                        handleConfirm={handleCancel}
                        isLoading={cancelBtnLoading}
                    />
                )}
            </div>
        </SideOverlay>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
});

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    addOn: PropTypes.bool,
    recognition: PropTypes.object,
    isSelfRecognition: PropTypes.bool,
    users: PropTypes.object,
    timestamp: PropTypes.string,
};

export default Index;
