import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';
import { filter, find, reduce } from 'lodash';
import { CustomCheckbox } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import AnonymousSection from './AnonymousSection';
import SearchUser from '../../Components/SearchUser';
import PulseCreationInfo from '../../Components/PulseCreationInfo';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import { getAudienceCount } from 'Services/apiFunctions';
import { EDIT_TYPE } from '../../constants';
import { showSnackBarMessage, addOrRemoveElement } from 'utils/HelperFunctions';
import { ANONIMITY, AUDIENCE_DROPDOWN_TABS } from '../constants';
import DropdownWithTabs from '../DropdownWithTabs';
import './style.scss';

const Index = ({
    setQuesAdded,
    eNPS,
    pulseData,
    audience,
    setAudience,
    questions,
    setStep,
    getScheduleInfo,
    isEditingOnetime,
    isEditingRolling,
    editType,
    scheduling,
    pulseAudienceData,
    setPulseAudienceData,
}) => {
    // make this false once the list is updated.
    const [error, setError] = useState(false);
    //make exclude as true even if one department/channel/team is selected
    const [exclude, setExclude] = useState(false);
    const { SetSnackbar } = useToaster();

    // to seggregate users, channels/usergroups, teams, departments into different arrays
    const getRequestedListData = (users) => {
        const filteredData = getFilteredData(users);
        return reduce(
            filteredData,
            ([currentChannelListData, userListData, userGroupsData, departmentListData, teamsListData], item) => {
                if (item.channel) {
                    currentChannelListData.push(item.channelID);
                } else if (item.groupID) {
                    userGroupsData.push(item.groupID);
                } else if (item.type === AUDIENCE_DROPDOWN_TABS.managersTeam) {
                    teamsListData.push(item._id);
                } else if (item.type === AUDIENCE_DROPDOWN_TABS.departments) {
                    departmentListData.push(item.id);
                } else {
                    userListData.push(item._id || item.id);
                }
                return [currentChannelListData, userListData, userGroupsData, departmentListData, teamsListData];
            },
            [[], [], [], [], []]
        );
    };

    // to get images and count of audience to show in PulseCreationInfo at right
    const getPulseAudienceCount = async (users, excludedUsers, anonymous) => {
        const [channelListData, userListData, userGroupsData, departmentListData, teamsListData] =
            getRequestedListData(users);
        const excludedListData = excludedUsers ? excludedUsers.map((item) => item.id) : [];
        const apiData = {
            channels: channelListData,
            departmentList: departmentListData,
            teamsList: teamsListData,
            requestedUsers: userListData,
            excludedUsers: excludedListData,
            userGroups: userGroupsData,
            anonymous: anonymous,
            withImages: true,
        };
        try {
            const data = await getAudienceCount({ ...apiData });
            setPulseAudienceData(data);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
        }
    };

    const isEditing = () => {
        return isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING;
    };

    const getUsersList = (users) =>
        users.map((user) => ({
            id: user._id,
            name: user.userName || user.name,
            memberID: user.memberID,
            picUrl: user.userImageURL || user.pictureURL || user.picUrl,
            channel: false,
            isEditingOnetime: isEditingOnetime, // if isEditingOnetime is true then hide the x mark next to selected audience
            isEditing: isEditing(),
            _id: user.id || user._id,
            type: AUDIENCE_DROPDOWN_TABS.user,
        }));

    // This function sets all the data of the pulse that is being edited
    const setData = (requestedUsers, channels, userGroups, departmentList, teamsList, excludedUsers, anonymous) => {
        const tempUsersArray = getUsersList(
            filter(requestedUsers, (user) => !user?.source || user.source.length === 0) || []
        );
        const tempChannelArray = filter(channels, (item) => item?.channelID).map((channel) => ({
            id: channel.channelID,
            name: channel.channelName,
            channelID: channel.channelID,
            channelName: channel.channelName,
            picUrl: null,
            channel: true,
            memberCount: 0,
            isEditing: isEditing(),
        }));
        const tempUserGroupArray = filter(userGroups, (item) => item?.userGroupID).map((userGroup) => ({
            id: userGroup.userGroupID,
            name: userGroup.userGroupName,
            groupID: userGroup.userGroupID,
            picUrl: null,
            memberCount: 0,
            isEditingOnetime: isEditing(),
        }));
        const tempDepartmentList = departmentList?.map((department) => ({
            ...department,
            type: AUDIENCE_DROPDOWN_TABS.departments,
            id: department._id,
            isEditing: isEditing(),
        }));
        const tempTeamsList = teamsList?.map((team) => ({
            ...team,
            type: AUDIENCE_DROPDOWN_TABS.managersTeam,
            isEditing: isEditing(),
        }));
        const combined = [...tempChannelArray, ...tempUserGroupArray];
        const tempUserExcludedList = getUsersList(excludedUsers || []);

        const audienceData = {
            userList: tempUsersArray,
            channelList: combined,
            departmentList: tempDepartmentList,
            teamsList: tempTeamsList,
            excludedUsers: tempUserExcludedList,
            anonymous: anonymous === ANONIMITY.ANONYMOUS,
        };

        setAudience({
            ...audience,
            ...audienceData,
        });

        setExclude(tempUserExcludedList && tempUserExcludedList.length > 0);
        getPulseAudienceCount(audienceData, tempUserExcludedList, anonymous === 'Anonymous');
    };

    useEffect(() => {
        setQuesAdded(true);
        if (pulseData) {
            // while editing the pulse
            setData(
                (pulseData?.requestedUsers?.length > 0 ? pulseData.requestedUsers : audience.userList) || [],
                (pulseData?.channels?.length > 0 ? pulseData.channels : audience.channelList) || [],
                pulseData.userGroups || [],
                (pulseData?.departmentList?.length > 0 ? pulseData.departmentList : audience.departmentList) || [],
                (pulseData?.teamsList?.length > 0 ? pulseData.teamsList : audience.teamsList) || [],
                (pulseData?.excludedUsers?.length > 0 ? pulseData.excludedUsers : audience.excludedUsers) || [],
                pulseData?.anonymous || ANONIMITY.ANONYMOUS
            );
        }
    }, [pulseData]);

    // callback on selecting audience
    const onChange = (value, type) => {
        setError(!value);
        value.type = type;
        const temp = { ...audience };
        if (type === AUDIENCE_DROPDOWN_TABS.user) {
            temp.userList = [...audience.userList];
            temp.userList = addOrRemoveElement(temp.userList, value);
            setExcludeUser(temp);
        } else if (type === AUDIENCE_DROPDOWN_TABS.departments) {
            temp.departmentList = [...audience.departmentList];
            temp.departmentList = addOrRemoveElement(temp.departmentList, value);
            setExcludeUser(temp);
        } else if (type === AUDIENCE_DROPDOWN_TABS.channels) {
            temp.channelList = [...(audience.channelList || [])];
            temp.channelList = addOrRemoveElement(temp.channelList, value);
            setExcludeUser(temp);
        } else {
            temp.teamsList = [...audience.teamsList];
            temp.teamsList = addOrRemoveElement(temp.teamsList, value);
            setExcludeUser(temp);
        }
        setAudience(temp);
    };

    const getFilteredData = (data) => [
        ...(data.channelList || []),
        ...(data.userList || []),
        ...(data.departmentList || []),
        ...(data.teamsList || []),
        ...(data.excludedUserList || []),
    ];

    // callback on selecting excluded users
    const onChangeExcludeUsers = (value) => {
        const temp = { ...audience };
        temp.excludedUsers = [...(audience.excludedUsers || [])];
        temp.excludedUsers = addOrRemoveElement(temp.excludedUsers, value);
        setAudience(temp);
        getPulseAudienceCount(temp, temp.excludedUsers, audience.anonymous);
    };

    // callback on removing selected audience
    const onRemoveUser = (data) => {
        const temp = {
            channelList: [],
            departmentList: [],
            teamsList: [],
            userList: [],
            excludedUsers: [],
            anonymous: true,
        };
        if (data.length > 0) {
            data.forEach((value) => {
                const { channel, groupID, type } = value;
                if (channel || groupID) {
                    temp.channelList.push(value);
                } else {
                    switch (type) {
                        case AUDIENCE_DROPDOWN_TABS.departments:
                            temp.departmentList.push(value);
                            break;
                        case AUDIENCE_DROPDOWN_TABS.managersTeam:
                            temp.teamsList.push(value);
                            break;
                        default:
                            temp.userList.push(value);
                    }
                }
            });
        }
        setAudience(temp);
        setExcludeUser(temp);
    };

    // callback on removing selected excluded users
    const onRemoveExcludedUser = (data) => {
        const temp = { ...audience };
        temp.excludedUsers = [...data];
        setAudience(temp);
        getPulseAudienceCount(temp, data, audience.anonymous);
    };

    // Show/hide exclude user UI if channels are added/removed
    const setExcludeUser = (tempAudience) => {
        const hasExcludedUsers = audience.excludedUsers.length > 0;
        getPulseAudienceCount(tempAudience, hasExcludedUsers ? audience.excludedUsers : [], audience.anonymous);
    };

    useBeforeunload((event) => {
        event.preventDefault();
    });

    return (
        <div className='pulse-audience-main'>
            <div>
                <div className='pa-header'>Choose your audience</div>
                <DropdownWithTabs onChange={onChange} audience={audience} isEditing={isEditing()} />
                <CustomUserTags users={getFilteredData(audience)} onRemove={onRemoveUser} />
                {error && <p className='error-validation-text'>Please add a user or channel name</p>}
                {(audience.channelList.length > 0 ||
                    audience.departmentList.length > 0 ||
                    audience.teamsList.length > 0) && (
                    <>
                        <div className='pa-exclude-users'>
                            <CustomCheckbox
                                checked={isEditing() ? pulseData?.excludedUsers : exclude}
                                onClick={() => {
                                    if (isEditing()) {
                                        const temp = { ...audience };
                                        temp.excludedUsers = [...(audience.excludedUsers || [])];
                                        setAudience(temp);
                                    }
                                    setExclude(isEditing() ? pulseData?.excludedUsers.length > 0 : !exclude);
                                }}
                            />
                            <p>Exclude users</p>
                        </div>
                        {isEditing() && (
                            <p className='audience-note'>
                                Note: Users who have already received this pulse cannot be excluded
                            </p>
                        )}
                        {(isEditing() ? pulseData.excludedUsers.length > 0 || exclude : exclude) && (
                            <>
                                <SearchUser
                                    autoCompleteId='excludeUser'
                                    width={341}
                                    onChange={onChangeExcludeUsers}
                                    filterChannels={true}
                                    placeholder='Search for users'
                                    selectedData={[
                                        ...(audience.channelList || []),
                                        ...(audience.teamsList || []),
                                        ...(audience.departmentList || []),
                                    ]}
                                    listBoxProps={{ style: { maxHeight: 300 } }}
                                    selectedOptions={audience.excludedUsers}
                                />
                                <CustomUserTags users={audience.excludedUsers} onRemove={onRemoveExcludedUser} />
                            </>
                        )}
                    </>
                )}
                {
                    <>
                        <div className='separator' />
                        <AnonymousSection
                            audience={audience}
                            setAudience={setAudience}
                            disabled={isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING}
                            onlyAnonymous={eNPS}
                        />
                    </>
                }
            </div>
            <PulseCreationInfo
                eNPS={eNPS}
                data={pulseAudienceData}
                scheduled={getScheduleInfo}
                questions={questions}
                setStep={setStep}
                scheduling={scheduling}
            />
        </div>
    );
};

Index.propTypes = {
    setQuesAdded: PropTypes.func,
    eNPS: PropTypes.bool,
    pulseData: PropTypes.object,
    audience: PropTypes.object,
    setAudience: PropTypes.func,
    questions: PropTypes.object,
    setStep: PropTypes.func,
    getScheduleInfo: PropTypes.func,
    isEditingOnetime: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    editType: PropTypes.bool,
    scheduling: PropTypes.object,
    pulseAudienceData: PropTypes.object,
    setPulseAudienceData: PropTypes.func,
};

export default Index;
