import React from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({ open, onClose, data, votesHandler, userButtonHandler, isSuggestionBoxAdmin = false }) => {
    const modalData = {
        heading: 'Suggestions',
    };

    return (
        <EWModal open={open} onClose={onClose} width='450px' modalData={modalData}>
            <div className='ew-modal-body'>
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>{isSuggestionBoxAdmin ? 'From' : 'Sent'}</strong>
                    {isSuggestionBoxAdmin ? (
                        userButtonHandler(data)
                    ) : (
                        <span>{data.anonymous ? 'Anonymously' : 'Non-Anonymously'}</span>
                    )}
                </div>
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>Sent on</strong>
                    {data.date}
                </div>
                {votesHandler(data)}
                <div>
                    <strong className='ew-modal-details-heading'>Suggestion message</strong>
                    <div className='suggestion-msg-container'>{data.message}</div>
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    votesHandler: PropTypes.func,
    userButtonHandler: PropTypes.func,
    isSuggestionBoxAdmin: PropTypes.bool,
};

export default Index;
