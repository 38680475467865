import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GET_FEEDBACK_CONFIGURATION } from 'Services/apiKeys';
import { getFeedbackConfiguration, updateFeedbackConfiguration } from '../Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import TitleBar from 'components/ReusableComponents/TitleBar';
import ConfigurationItem from './ConfigurationItem/index';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getFeedbackConfigs } from '../constants';
import PropTypes from 'prop-types';

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const { feedbackConfigs, todoConfigs } = useSelector(mapStateToProps, shallowEqual);
    const [allowFeedback, setAllowFeedback] = useState(feedbackConfigs?.feedbackLevel);
    const [feedbackConfiguration, setFeedbackConfiguration] = useState(getFeedbackConfigs());

    useQuery([GET_FEEDBACK_CONFIGURATION], getFeedbackConfiguration, {
        onSuccess: (data) => {
            const config = data?.data;
            // if feedback is not setup, there wont be default config values like enabled in api response
            if (config?.hasOwnProperty('enabled')) {
                updateWorkspaceDetails(dispatch, { feedback: { ...config } });
                setFeedbackConfiguration(getFeedbackConfigs(config.enabled, config.allowAnonymous, config.sendNudges));
                setAllowFeedback(config.feedbackLevel);
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const { mutateAsync: updateFeedbackConfigs } = useMutation(updateFeedbackConfiguration);

    const toggleConfiguration = async (index, key, value) => {
        const updatedConfiguration = [...feedbackConfiguration];
        updatedConfiguration[index] = {
            ...updatedConfiguration[index],
            isEnabled: !updatedConfiguration[index].isEnabled,
        };
        setFeedbackConfiguration(updatedConfiguration);
        updateConfigs({ key, value });
    };

    const handleAllowFeedback = (key, value) => {
        setAllowFeedback(value);
        updateConfigs({ key, value });
    };

    const updateConfigs = async ({ key, value }) => {
        try {
            const apiData = {
                [key]: value,
            };
            await updateFeedbackConfigs({ apiData });
            if (!todoConfigs.isFeedbackFlowCompleted) {
                updateWorkspaceDetails(dispatch, { todo: { ...todoConfigs, isFeedbackFlowCompleted: true } });
            }
            updateWorkspaceDetails(dispatch, { feedback: { ...feedbackConfigs, [key]: value } });
            showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            // Invalidate the data in case the api fails
            queryClient.invalidateQueries(GET_FEEDBACK_CONFIGURATION);
        }
    };

    return (
        <div>
            <TitleBar title='Configuration' />

            {feedbackConfiguration.slice(0)?.map((option, index) => (
                <ConfigurationItem
                    option={option}
                    index={index}
                    toggleConfiguration={toggleConfiguration}
                    key={option?.heading}
                    history={history}
                    isFeedbackEnabled={feedbackConfigs?.enabled}
                    handleAllowFeedback={handleAllowFeedback}
                    allowFeedback={allowFeedback}
                />
            ))}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    feedbackConfigs: Workspace.feedback,
    todoConfigs: Workspace.todo,
});
Index.propTypes = {
    history: PropTypes.object,
};
export default Index;
