import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { CustomSwitch, CustomCheckbox } from 'components';
import SelectionModal from '../SelectionModal/index';
import SuggestionBoxAdmin from '../SuggestionBoxAdmin/index';
import MultipleAdminSelectionModal from 'components/ReusableComponents/MultipleAdminSelectionModal';
import EWButton from 'components/ReusableComponents/EWButton';
import { getChannelOptionsByPlatform } from 'utils/HelperFunctions';
import { getIntegratedChannelsList } from 'Services/apiFunctions';
import { GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { changeAdminModalData, selectChannelModalData, CONFIGURATION_KEYS } from '../../../constants';
import './style.scss';

const Index = ({ index, option, toggleConfiguration, platform, loading }) => {
    const { SetSnackbar } = useToaster();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [openChangeAdmin, setOpenChangeAdmin] = useState(false);
    const [openChangeChannels, setOpenChangeChannels] = useState(false);
    const [channelsData, setChannelsData] = useState([]);

    const openSnackbar = (variant, message) => {
        SetSnackbar({ open: true, variant, message: message || 'Some error occurred' });
    };
    useEffect(() => {
        setSelectedUsers(option.admins);
    }, [option]);

    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelsData(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError: (err) => openSnackbar('error', err?.message),
    });

    return (
        <div className='suggestion-box-configuration-op-container'>
            <div className='configuration-wrapper'>
                <div className='configuration-heading-container'>
                    <div className='configuration-new-badge'>{option?.heading}</div>
                    <p>{option?.subtext}</p>
                </div>
                {/* toggle the configuration for suggestion box, anonymous, post postPublicly */}
                {!option.changeAdmin && (
                    <div className='configuration-switch-container'>
                        <CustomSwitch
                            data-testid='suggestionConfigSwitch'
                            checked={option.isEnabled}
                            onClick={() => {
                                toggleConfiguration(index, option.key, !option.isEnabled);
                                if (option.key === CONFIGURATION_KEYS.POST_PUBLICLY && !option.suggestionChannel) {
                                    setOpenChangeChannels(true);
                                }
                            }}
                            name={'suggestionBoxCheck' + index}
                        />
                    </div>
                )}
            </div>
            {/* change the suggestion admin */}
            {option.changeAdmin && (
                <>
                    <div className='configuration-admin-container'>
                        {option.admins?.map((admin) => (
                            <SuggestionBoxAdmin key={admin._id} data={admin} title='admin' />
                        ))}
                    </div>
                    <EWButton
                        buttonText={'Manage'}
                        primary={false}
                        plainTextButton
                        onClick={() => setOpenChangeAdmin(true)}
                    />
                </>
            )}
            {option.adminApproval && (
                <div className={clsx({ 'container-disabled': !option.isEnabled })}>
                    <div className='configuration-channel-container'>
                        {option.suggestionChannel && (
                            <SuggestionBoxAdmin data={option.suggestionChannel} title='channel' platform={platform} />
                        )}
                        <EWButton
                            buttonText={'Change Channel'}
                            primary={false}
                            plainTextButton
                            onClick={() => {
                                setOpenChangeChannels(true);
                            }}
                        />
                    </div>
                    <div className={'csf-config'}>
                        <div className='csf-checkbox'>
                            <CustomCheckbox
                                data-testid='configCheckbox'
                                checked={option.approvalRequired}
                                onClick={() => {
                                    toggleConfiguration(
                                        index,
                                        CONFIGURATION_KEYS.APPROVAL_REQUIRED,
                                        !option.approvalRequired
                                    );
                                }}
                                label='Approval from admin required'
                            />
                        </div>
                        <p>
                            All public suggestions will go to the suggestion box admin for approval before being posted
                            on the selected channel
                        </p>
                        <div className='csf-checkbox'>
                            <CustomCheckbox
                                data-testid='votingCheckbox'
                                checked={option.allowVote}
                                onClick={() => {
                                    toggleConfiguration(index, CONFIGURATION_KEYS.ALLOW_VOTE, !option.allowVote);
                                }}
                                label='Allow Voting'
                            />
                        </div>
                        <p>Employees can upvote or downvote anonymously on public suggestions</p>
                    </div>
                </div>
            )}
            {openChangeChannels && (
                <SelectionModal
                    open={openChangeChannels}
                    modalData={selectChannelModalData}
                    setOpen={setOpenChangeChannels}
                    dropdownData={channelsData}
                    selectedOption={option.suggestionChannel}
                    handleSave={(value) => {
                        toggleConfiguration(index, CONFIGURATION_KEYS.SUGGESTION_CHANNEL, value, () =>
                            setOpenChangeChannels(false)
                        );
                    }}
                    loading={loading}
                    platform={platform}
                    onClose={() => {
                        if (option.key === CONFIGURATION_KEYS.POST_PUBLICLY && !option.suggestionChannel) {
                            toggleConfiguration(index, CONFIGURATION_KEYS.POST_PUBLICLY, !option.isEnabled);
                        }
                        setOpenChangeChannels(false);
                    }}
                />
            )}
            {openChangeAdmin && option.admins && (
                <MultipleAdminSelectionModal
                    open={openChangeAdmin}
                    onClose={() => {
                        setOpenChangeAdmin(false);
                        setSelectedUsers(option.admins);
                    }}
                    modalData={changeAdminModalData}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    handleSave={(users) => {
                        toggleConfiguration(index, CONFIGURATION_KEYS.ADMINS, users, () => setOpenChangeAdmin(false));
                    }}
                    loading={loading}
                />
            )}
        </div>
    );
};

Index.defaultProps = {
    loading: false,
};

Index.propTypes = {
    index: PropTypes.number,
    option: PropTypes.object,
    toggleConfiguration: PropTypes.func,
    platform: PropTypes.string,
    loading: PropTypes.bool,
};

export default Index;
