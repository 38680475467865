import React, { useState } from 'react';
import { useQuery } from 'react-query';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import CategoriesItem from './CategoriesItem';
import Table from 'components/ReusableComponents/Table';
import { getFeedbackCategoriesList } from '../Services/apiFunctions';
import { GET_FEEDBACK_CATEGORIES_LIST } from 'Services/apiKeys';
import AddCategoryModal from './AddCategoryModal';
import { handleCategoryEdit, handleValueDelete } from '../componentsUtil';
import RemoveCategoryModal from './RemoveCategory';
import { useToaster } from 'Context/SnackbarContext';
import EWButton from 'components/ReusableComponents/EWButton';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = () => {
    const { SetSnackbar } = useToaster();
    const [addModal, setAddModal] = useState({ open: false, category: '', index: -1 });
    const [deleteModal, setDeleteModal] = useState({ open: false, index: -1 });
    const [categories, setCategories] = useState([]);

    const { isLoading, isFetching } = useQuery([GET_FEEDBACK_CATEGORIES_LIST], getFeedbackCategoriesList, {
        onSuccess: (data) => setCategories(data?.categories || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    if (isLoading) {
        return <CustomLoader />;
    }

    const tableData = categories.map((value, index) => ({
        id: index + 1,
        row: [
            <CategoriesItem
                key={value}
                index={index}
                value={value}
                handleEdit={(open, index, value) => handleCategoryEdit({ open, index, value, setAddModal })}
                handleDelete={(open, index) => handleValueDelete({ open, index, setDeleteModal })}
            />,
        ],
    }));

    return (
        <div className='mb-4'>
            <div className='category-config-container'>
                {addModal.open && (
                    <AddCategoryModal addModal={addModal} setAddModal={setAddModal} categories={categories} />
                )}
                {deleteModal.open && (
                    <RemoveCategoryModal
                        categories={categories}
                        deleteIndex={deleteModal.index}
                        setDeleteModal={setDeleteModal}
                    />
                )}
                <div className='category-list-container'>
                    <Table
                        data={tableData}
                        noShadow={true}
                        loading={isLoading || isFetching}
                        noSortColumns={[0]}
                        columnWidth={['100%']}
                        emptyStateText='No feedback categories found'
                    />
                </div>
                <EWButton
                    onClick={() => setAddModal({ open: true, category: '', index: -1 })}
                    border={true}
                    primary={false}
                >
                    <div className='d-flex align-items-center'>
                        <span className='add-value-button-plus-icon'>+</span>
                        <span className='ml-2'>Add Value</span>
                    </div>
                </EWButton>
            </div>
        </div>
    );
};

export default Index;
