import React from 'react';
import { format, parseISO } from 'date-fns';
import MemberTag from '../../components/MemberTag';

export const rowFormatter = (history) => (award) => ({
    id: award._id,
    row: [
        <strong key={award._id}>{award.name}</strong>,
        award?.awardDate ? format(parseISO(award.awardDate), 'MMM dd, yyyy') : '-',
        <MemberTag
            key={award._id}
            history={history}
            memberID={award.awardee?._id}
            memberPhoto={award.awardee?.pictureURL}
            memberName={award.awardee?.userName}
            isAdmin={true}
        />,
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
        <span key={award._id}>
            <img
                className='mr-2'
                src={require(`Assets/images/${
                    award.type === 'Direct' ? 'award_type_direct.svg' : 'award_type_poll.svg'
                }`)}
                alt=''
            />
            {award.type === 'Direct'
                ? `Direct Award By ${award.awardedBy?.userName}`
                : `Poll Award, ${award.responseCount}/${award.requestedCount} votes`}
        </span>,
    ],
});

export const getSortHandler = (sort, setSort, tableProperties) => (index) =>
    setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
