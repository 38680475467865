import React from 'react';
import clsx from 'clsx';
import { CustomCheckbox, CustomSwitch } from 'components';
import PropTypes from 'prop-types';
import ConfigurationRadioSection from './ConfigurationRadioSection';
import FeedbackCategories from 'components/Dashboard/Content/Feedback/FeebackCategories';
import './style.scss';

const OPTION_CONFIG = {
    enabled: {
        showSwitch: true,
        globalToggle: true,
    },
    feedbackLevel: {
        showRadioSelctor: true,
        hideHeadings: true,
        showSeparator: true,
        topRadius: true,
    },
    allowAnonymous: {
        showCheckbox: true,
        showSeparator: true,
    },
    sendNudges: {
        showCheckbox: true,
        showSeparator: true,
    },
    addCategories: {
        showCategories: true,
        bottomRadius: true,
    },
};

const Index = ({ index, option, toggleConfiguration, isFeedbackEnabled, handleAllowFeedback, allowFeedback }) => {
    const config = OPTION_CONFIG[option.key];

    return (
        <div className='feedback-config-container'>
            <div
                className={clsx('configuration-op-container', {
                    'container-disabled': !config.globalToggle && !isFeedbackEnabled,
                    'feedback-toggle-container': config.globalToggle,
                    'top-radius': config.topRadius,
                    'bottom-radius': config.bottomRadius,
                })}
            >
                <div className='configuration-wrapper'>
                    {config.showRadioSelctor && (
                        <ConfigurationRadioSection
                            allowFeedback={allowFeedback}
                            handleAllowFeedback={handleAllowFeedback}
                            option={option}
                        />
                    )}
                    <div className='config-checkbox'>
                        {config.showCheckbox && (
                            <CustomCheckbox
                                data-testid='feedbackConfigSwitch'
                                checked={option.isEnabled}
                                onClick={() => toggleConfiguration(index, option.key, !option.isEnabled)}
                                name={'feedbackCheck' + index}
                            />
                        )}
                        {!config.hideHeadings && (
                            <div className='configuration-heading-container'>
                                <div
                                    className={clsx({
                                        'configuration-new-badge': !config.globalToggle,
                                        'main-heading': config.globalToggle,
                                    })}
                                >
                                    {option?.heading}
                                </div>
                                <p>{option?.subtext}</p>
                            </div>
                        )}
                    </div>
                    {config.showSwitch && (
                        <CustomSwitch
                            data-testid='feedbackSwitch'
                            checked={option?.isEnabled}
                            onClick={() => toggleConfiguration(index, option?.key, !option?.isEnabled)}
                            name={'feedbackCheck' + index}
                        />
                    )}
                </div>
                {config.showCategories && <FeedbackCategories />}
                {config.showSeparator && <div className='config-separator' />}
            </div>
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    option: PropTypes.object,
    toggleConfiguration: PropTypes.func,
    isFeedbackEnabled: PropTypes.bool,
    handleAllowFeedback: PropTypes.func,
    allowFeedback: PropTypes.bool,
};

export default Index;
