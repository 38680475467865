import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const EmptyScreen = ({
    image,
    headingText,
    subheadingText,
    description,
    action,
    btnName,
    leftOnClick,
    leftBtn = false,
    disabled = false,
}) => {
    return (
        <div className='empty-container'>
            <div className='empty-content text-center'>
                <span className='d-block'>{image}</span>
                {headingText && <p className='heading '>{headingText}</p>}
                {subheadingText && <p className='subheading'>{subheadingText}</p>}
                {description && <p className='description'>{description}</p>}
                {leftBtn ? (
                    <div className='btn-wrapper'>
                        <EWButton
                            border
                            width='198px'
                            primary={false}
                            buttonText={leftBtn}
                            onClick={leftOnClick}
                            buttonStyleClass='button'
                        />
                        <EWButton primary={true} onClick={action} hidden={!btnName}>
                            {btnName}
                        </EWButton>
                    </div>
                ) : (
                    <EWButton primary={true} onClick={action} hidden={!btnName} disabled={disabled}>
                        {btnName}
                    </EWButton>
                )}
            </div>
        </div>
    );
};

EmptyScreen.propTypes = {
    image: PropTypes.element.isRequired,
    headingText: PropTypes.string,
    subheadingText: PropTypes.string,
    btnName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    action: PropTypes.func,
    description: PropTypes.string,
    leftBtn: PropTypes.string,
    leftOnClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default EmptyScreen;
