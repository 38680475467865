import React from 'react';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import PropTypes from 'prop-types';
import { TEAM, ORGANIZATION } from '../../constants';
import './style.scss';

const ConfigurationRadioSection = ({ option, allowFeedback, handleAllowFeedback }) => {
    return (
        <div className='config-anonymous-box'>
            <h5 className='pa-an-header'>{option.heading}</h5>
            <div>
                <div className='config-anonimity'>
                    <CustomRadioButton
                        checked={allowFeedback === ORGANIZATION}
                        onChange={() => handleAllowFeedback('feedbackLevel', ORGANIZATION)}
                        title='Company'
                        name='anyone-in-the-organization'
                        anyone-in-the-organisation
                        size={24}
                        bold={allowFeedback === ORGANIZATION}
                    />
                    <p className='subtitle'>{option.subtext1}</p>
                </div>

                <div className='config-anonimity'>
                    <CustomRadioButton
                        checked={allowFeedback === TEAM}
                        onChange={() => handleAllowFeedback('feedbackLevel', TEAM)}
                        title='Same team'
                        name='anyone-in-the-same-team'
                        size={24}
                        bold={allowFeedback === TEAM}
                    />
                    <p className='subtitle'>{option.subtext2}</p>
                </div>
            </div>
        </div>
    );
};
ConfigurationRadioSection.propTypes = {
    option: PropTypes.object.isRequired,
    allowFeedback: PropTypes.string.isRequired,
    handleAllowFeedback: PropTypes.func.isRequired,
};

export default ConfigurationRadioSection;
