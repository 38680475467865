import React from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';
import { PLATFORM } from 'constants.js';

const Index = ({ open, setOpen, handleManualUpdate, handleSendDM, platform }) => {
    const isWebPlatform = platform === PLATFORM.WEB;
    const onClose = (isCancel) => {
        if (isWebPlatform) {
            setOpen(false);
            return;
        }

        if (isCancel) handleManualUpdate();
        setOpen(false);
    };

    const handleConfirm = () => {
        if (isWebPlatform) {
            handleManualUpdate();
            setOpen(false);
            return;
        }

        handleSendDM();
    };

    const data = {
        confirm: !isWebPlatform ? 'Send DM to update' : 'Bulk Update',
        cancel: !isWebPlatform && 'Bulk Update',
        heading: 'Update missing information',
        subHeading: !isWebPlatform
            ? 'You can bulk upload birthday and anniversary dates via CSV, or you can send a DM to employees to update the information.'
            : 'You can bulk upload birthday and anniversary dates via CSV.',
    };

    return (
        <div className='update-missing-info-modal-container'>
            <ConfirmModal open={open} data={data} onClose={onClose} onConfirm={handleConfirm} height={'230px'} />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleManualUpdate: PropTypes.func,
    handleSendDM: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
