import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { Tooltip } from 'components';
import DropdownList from './DropdownList';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const DropdownIcon = ({ clock, open }) => {
    if (clock) {
        return <AccessTimeIcon data-testid='clock' />;
    } else if (open) {
        return <KeyboardArrowUpIcon data-testid='arrow-up' />;
    } else {
        return <KeyboardArrowDownIcon data-testid='arrow-down' />;
    }
};

const Index = ({
    handleClose,
    title,
    titleStyleClass,
    selectedName = 'Select',
    dropdownHeight,
    dropdownWidth,
    buttonStyleClass,
    dropdownStyleClass,
    search = false,
    filterOptions = [],
    showRadio,
    singleSelect,
    handleSelection,
    handleRadioSelection,
    disabled,
    dropDownID,
    clock,
    optionsSelected,
    customSearchText = 'Search',
    info,
    error = false,
    isRequired = false,
    optional = false,
    dropdownGap = 0, // gap between the filter button and the dropdown
    noDataFoundTitle,
    noDataFoundSubTitle,
}) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const onClose = () => {
        setOpen(false);
        if (handleClose) {
            handleClose();
        }
    };

    const handleSearch = (value) => setSearchValue(value);

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='container-custom-dropdown'>
                {title && (
                    <p className={clsx({ 'filter-title': !titleStyleClass, [titleStyleClass]: titleStyleClass })}>
                        {title}
                        {optional && <span className='filter-title-optional'>&nbsp;(Optional)</span>}
                        {isRequired && <span className='is-required'>*</span>}
                        {info && (
                            <Tooltip title={info}>
                                <InfoIcon className='info-icon' />
                            </Tooltip>
                        )}
                    </p>
                )}
                <button
                    className={clsx({
                        'dropdown-select-button': true,
                        'dropdown-button-open': open,
                        'dropdown-options-selected': optionsSelected,
                        'dropdown-button-error': error,
                        [buttonStyleClass]: buttonStyleClass,
                    })}
                    onClick={() => setOpen(!open)}
                    disabled={disabled}
                >
                    <span> {selectedName}</span>
                    <DropdownIcon clock={clock} open={open} />
                </button>
                <div
                    id={dropDownID}
                    className={clsx({
                        'dropdown-container': true,
                        'display-none': !open,
                        [dropdownStyleClass]: dropdownStyleClass,
                        [buttonStyleClass]: !dropdownStyleClass && buttonStyleClass,
                    })}
                    style={{
                        height: dropdownHeight || (filterOptions?.length <= 3 ? 'auto' : 140),
                        width: dropdownWidth,
                        top: title ? `${63 + dropdownGap}px` : `${48 + dropdownGap}px`,
                    }}
                >
                    {search && (
                        <CustomSearchBox
                            containerClassName='dropdown-search'
                            width='100%'
                            handleSearch={handleSearch}
                            placeholder={customSearchText}
                            data-testid='search'
                        />
                    )}
                    <div className='dropdown-list-container'>
                        <DropdownList
                            data={filterOptions}
                            searchValue={searchValue}
                            noDataFoundTitle={noDataFoundTitle}
                            noDataFoundSubTitle={noDataFoundSubTitle}
                            singleSelect={singleSelect}
                            showRadio={showRadio}
                            handleSelection={handleSelection}
                            handleRadioSelection={handleRadioSelection}
                            onClose={onClose}
                        />
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    handleClose: PropTypes.func,
    title: PropTypes.string,
    titleStyleClass: PropTypes.string,
    selectedName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    dropdownHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dropdownWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    buttonStyleClass: PropTypes.string,
    dropdownStyleClass: PropTypes.string,
    search: PropTypes.bool,
    filterOptions: PropTypes.array,
    showRadio: PropTypes.bool,
    singleSelect: PropTypes.bool,
    handleSelection: PropTypes.func,
    handleRadioSelection: PropTypes.func,
    disabled: PropTypes.bool,
    dropDownID: PropTypes.string,
    clock: PropTypes.bool,
    optionsSelected: PropTypes.bool,
    info: PropTypes.any,
    customSearchText: PropTypes.string,
    error: PropTypes.bool,
    isRequired: PropTypes.bool,
    optional: PropTypes.bool,
    dropdownGap: PropTypes.number,
    noDataFoundTitle: PropTypes.string,
    noDataFoundSubTitle: PropTypes.string,
};

DropdownIcon.propTypes = {
    clock: PropTypes.bool,
    open: PropTypes.bool,
};

export default Index;
