import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from 'components/ReusableComponents/LinearProgress';

const Index = ({ responseCount = 0, requestedCount = 0, label }) => {
    const percent = (100 * responseCount) / requestedCount;
    const responseBarColor = percent < 50 ? '#FF6C6C' : '#32DBA1';

    return (
        <div>
            <LinearProgress color={responseBarColor} value={percent} />
            <p className='mt-2 d-inline mb-0'>
                <strong>{responseCount} / </strong>
                {requestedCount} {label}
            </p>
        </div>
    );
};

Index.propTypes = {
    responseCount: PropTypes.number,
    requestedCount: PropTypes.number,
    label: PropTypes.string,
};

export default Index;
