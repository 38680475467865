import React, { useState } from 'react';
import EWModal from 'components/ReusableComponents/EWModal';
import EWButton from 'components/ReusableComponents/EWButton';
import UserButton from 'components/ReusableComponents/UserButton';
import { useQuery } from 'react-query';
import { ReactComponent as SubscriptionCancelledScreenImage } from 'Assets/images/subcription_cancelled_screen.svg';
import { shallowEqual, useSelector } from 'react-redux';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_ADMIN_LIST } from 'Services/apiKeys';
import { getAdminList } from 'Services/apiFunctions';
import {
    CONTACT_US,
    SUMMARY_PLAN_BOX_DATA,
    handlePlanAction,
    PLAN_TYPES,
} from '../../Dashboard/Content/PaymentsV2/constants';
import PropTypes from 'prop-types';

import './style.scss';

const evaluatePlanType = (subscription) => {
    // Right now its only one plan on the platform
    return PLAN_TYPES.STANDARD;
};

const Index = ({ open, onClose, history, currentRoute }) => {
    const { SetSnackbar } = useToaster();
    const { isAdmin, paymentsData } = useSelector(mapStateToProps, shallowEqual);
    const [redirectLoading, setRedirectLoading] = useState(false);

    const onError = (err) => {
        showSnackBarMessage(SetSnackbar, 'error', err?.message);
        setRedirectLoading(false);
    };
    const { data: adminData } = useQuery([GET_ADMIN_LIST], getAdminList, { enabled: !isAdmin });

    const planType = evaluatePlanType(paymentsData?.subscription);

    const handleOnClick = () => {
        setRedirectLoading(true);
        handlePlanAction(onError);
    };

    const userButtonHandler = (item) => {
        return (
            <UserButton
                onClick={() => openUserProfile(item)}
                imageURL={item.pictureURL}
                senderName={item.userName}
                clickableUser={true}
            />
        );
    };
    const openUserProfile = (item) => {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `/dashboard/profile/${item._id}`,
            navMenuCode: currentRoute,
        })();
        onClose();
    };

    return (
        <EWModal open={open} onClose={onClose} width='580px' height={isAdmin ? '388px' : 'auto'}>
            <div className='container-wrapper'>
                <SubscriptionCancelledScreenImage />
                <div className='header'>⚠️ Subscription Cancelled</div>
                <span className='subheader'>
                    {isAdmin
                        ? 'Please renew your subscription to continue using EngageWith'
                        : 'Please contact your admins to renew the subscription in order to continue using EngageWith'}
                </span>

                {isAdmin ? (
                    <>
                        <EWButton
                            buttonText={SUMMARY_PLAN_BOX_DATA[planType].button}
                            onClick={handleOnClick}
                            buttonStyleClass='crs-close-button'
                            loading={redirectLoading}
                            disabled={redirectLoading}
                        />
                        <EWButton
                            primary={false}
                            plainTextButton
                            buttonText='Contact Us'
                            onClick={() => window.open(CONTACT_US, '_blank')}
                            buttonStyleClass='crs-close-button'
                        />
                    </>
                ) : (
                    <div className='admin-list-container'>
                        {adminData?.data.map((item, index) => (
                            <span className='admin-button' key={item.id}>
                                {userButtonHandler(item)}
                                {index < adminData.data.length - 1 && ', '}
                            </span>
                        ))}
                    </div>
                )}
            </div>
        </EWModal>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin, paymentsData: state.Payments });

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    history: PropTypes.object,
    currentRoute: PropTypes.string,
};

export default Index;
