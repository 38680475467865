import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EWButton from 'components/ReusableComponents/EWButton';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { HISTORY_FROM, ROUTES, PLATFORM } from 'constants.js';
import { EMPLOYEE_AWARDS } from '../EmployeeAwards/constants';
import { SUGGESTION } from '../SuggestionBox/constants';
import { eNPS_ROUTE } from '../ENPS/constant';
import { PULSE_ROUTE } from '../PulseSurvey/constants';
import { RECOGNITIONS } from '../Recognitions/constants';
import { FEEDBACK } from '../Feedback/constants';
import { useToaster } from 'Context/SnackbarContext';
import { GET_PROFILE_DATA } from 'Services/apiKeys';
import { getProfile } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import UserDetails from './UserDetails';
import Activity from './Activity';
import CoreValues from './CoreValues';
import AwardsReceived from './AwardsReceived';
import EditProfile from './EditProfile';
import { ANALYTICS } from '../AnalyticsV2/constants';
import { toggleFullscreen } from 'redux/layout/actions';
import { ACTIVITIY_PATHS } from '../Activities/constants';
import { RECOGNITION_CONFIG } from '../Recognitions/Configuration/constants';
import clsx from 'clsx';
import './style.scss';
import { CELEBRATIONS } from '../Celebration/constants';

const RewardsBanner = ({ history, isRewardFlowCompleted }) => {
    const handleSetup = () => history.push(RECOGNITION_CONFIG.CONFIGURATION);

    return (
        <div className={clsx({ 'cp-warning-banner': true, 'cp-disabled': !isRewardFlowCompleted })}>
            <div className='cp-warning-top'>
                <div className='d-flex flex-column'>
                    <span>
                        <Warning />
                        Rewards and Recognition is not enabled for your organization
                    </span>
                    <p>
                        If you have any questions or would like to learn more about it, <br /> please reach out to
                        engagewith@springworks.in
                    </p>
                </div>
                <EWButton buttonText='Set up Rewards and Recognitions' onClick={handleSetup} />
            </div>
        </div>
    );
};

const handleOnBack = (history) => {
    const prevLocation = history?.location?.state?.from;
    switch (prevLocation) {
        case HISTORY_FROM.ANALYTICS:
            history.push(ANALYTICS.ANALYTICS_DASHBOARD);
            break;
        case HISTORY_FROM.LEADERBOARD:
            history.push(ANALYTICS.LEADERBOARD);
            break;
        case HISTORY_FROM.ACTIVITIES:
            history.push(ACTIVITIY_PATHS.ACTIVITIES);
            break;
        case HISTORY_FROM.EMPLOYEE_AWARDS:
            history.push(EMPLOYEE_AWARDS.HOME);
            break;
        case HISTORY_FROM.FEEDBACK:
            history.push(FEEDBACK.MY_FEEDBACK);
            break;
        case HISTORY_FROM.SUGGESTION_BOX:
            history.push(SUGGESTION.ALL_SUGGESTION);
            break;
        case HISTORY_FROM.TASKS:
            history.push(ROUTES.TASKS);
            break;
        case HISTORY_FROM.RECOGNITIONS:
            history.push(RECOGNITIONS.FEED);
            break;
        case HISTORY_FROM.eNPS:
            history.push(eNPS_ROUTE.HOME);
            break;
        case HISTORY_FROM.PULSE:
            history.push(PULSE_ROUTE.HOME);
            break;
        case HISTORY_FROM.CELEBRATIONS_EMPLOYEES_WITHOUT_DATES:
            history.push(CELEBRATIONS.EMPLOYEES_WITHOUT_ANY_DATES);
            break;
        case HISTORY_FROM.NOTIFICATIONS:
            history.push(ROUTES.NOTIFICATIONS);
            break;
        case HISTORY_FROM.AUDIT_LOGS:
            history.push(ROUTES.AUDIT_LOGS);
            break;
        case HISTORY_FROM.BONUS:
            history.push(ROUTES.BONUS);
            break;
        default:
            history.push(ROUTES.USERS);
            break;
    }
};

const Index = ({ history, match }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { isAdmin, platform, todo, loggedUserID } = useSelector(mapStateToProps, shallowEqual);
    const [showEditProfile, setShowEditProfile] = useState(false);

    const isWebOnly = platform === PLATFORM.WEB;

    useEffect(() => {
        toggleFullscreen(dispatch, false);
    }, []);

    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message);

    const { data, isLoading } = useQuery([GET_PROFILE_DATA, match.params.id], getProfile, {
        onError,
    });

    const handleEdit = () => setShowEditProfile(true);
    const handleCloseProfile = (save) => {
        if (save) {
            queryClient.invalidateQueries(GET_PROFILE_DATA);
        }
        setShowEditProfile(false);
    };

    if (isLoading) {
        return <CustomLoader />;
    }

    return (
        <div className='container-profile'>
            <TitleBar
                title='User profile'
                back
                showBalance
                fromRedeemPoints={true}
                currencySymbol={data?.currency}
                balance={data?.combinedRedeemBalance}
                onBack={() => handleOnBack(history)}
            />
            <UserDetails userData={data} isAdmin={isAdmin} handleEdit={handleEdit} loggedUserID={loggedUserID} />
            {(isAdmin || todo.isRewardFlowCompleted) && (
                <>
                    <div className='cp-banner-container'>
                        {!todo.isRewardFlowCompleted && (
                            <RewardsBanner history={history} isRewardFlowCompleted={todo.isRewardFlowCompleted} />
                        )}
                        <div className='cp-activity-values'>
                            <Activity data={data} />
                            <CoreValues valueData={data?.Chart?.data} />
                        </div>
                    </div>
                    {!isWebOnly && (isAdmin || data?.awards?.total > 0) && (
                        <AwardsReceived history={history} awards={data?.awards?.awards} />
                    )}
                </>
            )}
            {showEditProfile && (
                <EditProfile open={showEditProfile} data={data} onClose={handleCloseProfile} isAdmin={isAdmin} />
            )}
        </div>
    );
};

const mapStateToProps = ({ Workspace, User }) => ({
    todo: Workspace.todo,
    isAdmin: User.isAdmin,
    platform: Workspace.platform,
    loggedUserID: User._id,
});

Index.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

RewardsBanner.propTypes = {
    history: PropTypes.object,
    isRewardFlowCompleted: PropTypes.bool,
};

export default Index;
